import "jquery";
import {
  app,
  mainView,
  $$,
  updateUserProfile,
  addNewPost,
  addtohome,
  afterUserVerification,
  authenticateUser,
  selectcountry,
  cancelReservation,
  checkAvailability,
  openDatePicker,
  openTimePicker,
  openGuestPicker,
  openUsername,
  payviaupi,
  constructElement,
  placePicker,
  fetchUserLocationWeather,
  saveUserAndBrand,
  embedAndControlYouTubeVideo,
  parseUserAuth,
  firebaseAuth,
  logout,
  validateDiscount,
  calculateTax,
  createVCard,
} from "./app.js";

import moment from "moment";
import Vue from "vue";
import BotUI from "botui";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import { FIREBASE } from "./firebase";
import Parse from "./db";

import NO_COVER from '../img/no_cover.jpeg';
import NO_LOGO from '../img/no_logo.jpeg';
import HOME_PAGE_LOGO from '../img/sr_logo_small.png';

var homeBot = new BotUI("hello-world", {
  vue: Vue, // or this
});

// let mapScript = document.createElement("script");
// mapScript.setAttribute(
//   "src",
//   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCs0srJeIHCDL6DlP5yKZq0EQyTZ_b6BU8&libraries=places"
// );
// document.body.appendChild(mapScript);

    // var homeBot = new BotUI("hello-world");
    let json;
    let appButton;
    let senddata;
    var myArray = [];
    var alength = 0;
    var brandID;
    var apiaiid = "";
    var isMobile = "false";
    var photo = "";
    var brand_post_url = "";
    var storeUrl = "";
    // var mainView = app.views.add('.view-main', { url: '/' });
    // var senddata = [];
    var code = jQuery(".botui-app-container").data("code");
    var pdata = Parse.Object.extend("brandData");
    var query = new Parse.Query(pdata);
    // console.log(code);
    // var senddata = [];
    var deviceType = getMobileOperatingSystem();
    code && 
    query.get(code).then((data) => {
      // console.log(`Data is ${JSON.stringify(data)}`);
      // console.log(`BrandID: ${data.get("brand_id")}`);
      // console.log(`Payload JSON : ${JSON.stringify(data.get("payload_json"))}`);
      // console.log(`Brand name : ${data.get("payload_json").brand_name}`);
      json = JSON.parse(JSON.stringify(data));
      json = json.payload_json;
      brandID = json.brand_id;
      if(json.bot_bg){
        jQuery(".botui-app-container").attr("data-bg", json.bot_bg);
      } else {
        jQuery(".botui-app-container").attr("data-bg", "no");
      }
      localStorage.setItem(`brandid`, json.brand_id);
      localStorage.setItem(`brandName`, json.brand_name);
      localStorage.setItem(brandID + `-existcondition`, json.exist_condition);
      var brandCover = json.brand_cover;
      // apiaiurl = json.apiaiurl;
      appButton = json.appButton;
      // clearout();
      var bg = jQuery(".botui-app-container").data("bg");
      if (brandCover && bg == "yes") {
        jQuery(".botui-app-container").attr(
          "style",
          "background-image:url(" + brandCover + ");"
        );
        setTimeout(function () {
          jQuery(".botui-container").attr(
            "style",
            "background-color: transparent !important;"
          );
          jQuery(".botui-message-content").attr(
            "style",
            "background-color: white;"
          );
          jQuery(".botui-actions-buttons-button").attr(
            "style",
            "background: " + appButton + " !important;"
          );
        }, 1000);
      } else {
        jQuery(".botui-app-container").css("background-image", "none");
      }
    }).catch(error => {
      /** * 209 FOR INVALID SESSION TOKEN * 206 FOR SESSION IS MISSING */
      if (
        error.code === 209 ||
        error.code === 206 ||
        error.message === `Invalid session token`
      ) {
        logout();
        return;
      }
    });
  
    // jQuery('iframe').iframeAutoHeight();
  
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      // mobile!
      isMobile = true;
    } else {
      isMobile = false;
    }
  
    function sheetOpen() {
      app.sheet.open('.my-sheet-swipe-to-step');
    }
  
    // var msg = '{"status":true,"error":false,"data":{"brandID":"theteajunctionsaket","brandCode":"e0eoFRSKsv","title":"test title ","description":"desc test","type":"text","imageURL":"","videoURL":"","point":"10","enable":true,"social":"","code":"","createdAt":"2020-12-05T05:25:55.581Z","updatedAt":"2020-12-05T05:25:55.581Z","brand":{"_op":"AddRelation","objects":[{"_type":"Pointer","className":"brandData","objectId":"e0eoFRSKsv"}]},"objectId":"CM2il5aAjf"}}';
    // showNotification(msg, "");
  
    function offline(path, variable) {
      
      const currentUser = Parse.User.current();
      const ParseUserIDPos = myArray.findIndex(el => el.key === "ParseUserID");
      
      if(currentUser && (ParseUserIDPos === -1)){
        myArray = [...myArray, {key: "ParseUserID", value: currentUser.id}];
      }
      localStorage.removeItem("fbpage");
      // console.log(`Path and variable are ${path} and ${variable}`);
      // pageApply();
      // var url = jQuery(".botui-app-container").data("url");
      var code = jQuery(".botui-app-container").data("code");
      localStorage.setItem(`brandCode`, code);
      let brandCodePos = myArray.findIndex((el) => el.key === `brandCode`);
      // console.log(`isBrandCode: ${brandCodePos}`);
      if (brandCodePos > -1) {
        myArray[brandCodePos] = { key: `brandCode`, value: code };
      } else if (brandCodePos === -1) {
        myArray.push({ key: `brandCode`, value: code });
      }
      /*** FOR USER PROFILE */
      if(localStorage.getItem(`brandName`) === `userProfile`){
        let userFlag = myArray.findIndex((el)=> el.key === `remoteUser`);
        userFlag > -1 ? myArray[userFlag] = {key: `remoteUser`, value: localStorage.getItem(`remoteUser`)}: myArray.push({key: `remoteUser`, value: localStorage.getItem(`remoteUser`)});

        let userNameFlag = myArray.findIndex((el)=> el.key === `remoteUserName`);
        userNameFlag > -1 ? myArray[userNameFlag] = {key: `remoteUserName`, value: localStorage.getItem(`remoteUserName`)}: myArray.push({key: `remoteUserName`, value: localStorage.getItem(`remoteUserName`)});
      } else{
        localStorage.removeItem(`remoteUser`);
        localStorage.removeItem(`remoteUserName`); 
      }
      /** END */
      if(mainView.router.currentRoute.url == "/discover/") {
        mainView.router.back();
      }
      ga("send", {
        hitType: "event",
        eventCategory: brandID,
        eventAction: path,
        eventLabel: "Chatbot Engagement",
      });
  
      if (path == "feedbackalt" || "npsfeedbackalt" == path) {
        localStorage.setItem("chatfeedback", variable);
      } else if (path == "get_started" || path == "exist_start") {
        if (localStorage.getItem("brand_code") && localStorage.getItem("vmn")) {
          var dQuery = new Parse.Query("userChatbot");
          dQuery.equalTo("mobile", localStorage.getItem("vmn"));
          var bQuery = new Parse.Query("userChatbot");
          bQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
          var mainQuery = Parse.Query.and(dQuery, bQuery);
          mainQuery.find().then(function (result) {
            // console.log(result); console.log(result.length);
            if (result.length < 1) {
              var fb_bot = localStorage.getItem("fb_bot");
              var brandQuery = new Parse.Query("brandData");
              if(fb_bot && fb_bot == "true") {
                brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
              } else {
                brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
              }
              // var fieldName = (fb_bot && fb_bot == "true") ? "brand_code" : "objectId";
              // brandQuery.equalTo([fieldName], localStorage.getItem("brand_code"));
              brandQuery.first().then(function (brandResult) {
                if (brandResult.get(`type`) === `brand`) {                  
                  // jQuery.each(results, function (key, value) {
                  //   var json = JSON.parse(JSON.stringify(value));
                    var userQuery = new Parse.Object("userChatbot");
                    userQuery.set("brand_id", brandResult.get(`brand_id`));
                    userQuery.set("brand_icon", brandResult.get(`brand_icon`));
                    userQuery.set("brand_code", localStorage.getItem("brand_code"));
                    userQuery.set("brand_name", brandResult.get(`brand_name`));
                    userQuery.set("brand_category", brandResult.get(`brand_category`));
                    userQuery.set("type", brandResult.get(`type`));
                    userQuery.set("device_uuid", app.device.uuid);
                    userQuery.set("mobile", localStorage.getItem("vmn"));
                    userQuery.save();
                  // });
                }
              });
            } else if (result.length == 1) {
              // console.log("id :", result[0].id);
              var userChat = Parse.Object.extend("userChatbot");
              var userQuery = new Parse.Query(userChat);
              userQuery.equalTo("objectId", result[0].id);
              userQuery.first().then(function (bresult) {
                bresult.save();
              });
            }
          });
        }
      } else if (path === "discover") {                
        mainView.router.navigate({ name: "discover", path: "/discover/" });
      } else if (path === "checkPosts") {
        mainView.router.navigate({ name: `BrandPosts`, path: `/brand_posts/:brandid/:selection`, params: { brandid: brandID, selection:"true"} });
      } else if (path === "checkPoints") {
        mainView.router.navigate({ name: `PointsLog`, path: `/pointsLog/:brandid/:selection`, params: { brandid: brandID, selection:"true"} });
      } else if (path === "mychatapps") {
        mainView.router.navigate({ name: "mychatapps", path: "/mychatapps/" });
      } else if (path === "downloadVisitingCard") {
        mainView.router.navigate({ name: "profile", path: "/profile/" });
      } else if (path == "myorders") {
        var code = jQuery(".botui-app-container").data("code");
        query.get(code).then((data) => {
          var ordjson = JSON.parse(JSON.stringify(data));
          var bid = ordjson.brand_id;
            mainView.router.navigate({ name: "orders", path: "/orders/:brandid", params: { brandid: bid } });
        });
      }

      // var code = "jHsbMeEIiW";
      // console.log("code : ", code);
  
      code && query.get(code).then((data) => {
        json = JSON.parse(JSON.stringify(data));
        json = json.payload_json;
        brandID = json.brand_id;
        localStorage.setItem(`brandid`, json.brand_id);
        //HAVE TO FETCH SOME VALUE IN BACKGROUND
        if (json.prefetch) {
          for (const key in json.prefetch) {
            //FETCHING BRAND'S SERVICABLE AREA          
            if (
              !localStorage.getItem(`${brandID}_${key}`) ||
              localStorage.getItem(`${brandID}_${key}`) === `null`
            ) {
              jQuery.ajax({
                type: "GET",
                url: json.prefetch[key],
                headers: { "Content-Type": "application/json" },
                data: { brandid: brandID },
                dataType: "json",
                success: (result) => {                
                  localStorage.setItem(
                    brandID + "_" + key,
                    JSON.stringify(result[key])
                  );
                },
                error: (error) => {
                  console.error(
                    `RETURNED ERROR FROM PREFETCH VALUE API ${JSON.stringify(
                      error
                    )}`
                  );
                },
              });
            }
          }
        }
  
        if (json.is_persist_menu) {
          $("a.float").hasClass("sheet-open")
            ? ""
            : $("a.float").addClass("sheet-open");
          $("a.float").data("sheet") == ".my-sheet-swipe-to-step"
            ? ""
            : $("a.float").data("sheet", ".my-sheet-swipe-to-step");
          // let persistMenu = json.payloads.persist_menu.messages[0];
          let persistMenu = json.payloads.explore.messages[1];
          let prMenu = "";
          $("#prmenu").html("");
          persistMenu.action.button.action.forEach((value, key) => {
            let button = {
              action: {
                button: {
                  action: [value],
                },
              },
            };
            if(value.type) {
              prMenu +=
                value.type === "postback"
                  ? `<li><a href="#" onclick="offline('${value.value}')" class="panel-close sheet-close">${value.text}</a></li>`
                  : `<li><a href="#" onclick="openUrl('${value.value}')" class="panel-close sheet-close">${value.text}</a></li>`;
            } else {
              prMenu += `<li><a href="#" onclick="offline('${value.value}')" class="panel-close sheet-close">${value.text}</a></li>`;
            }
          });
          prMenu += `<li><a href="#" onclick="offline('manageNotification')" class="panel-close sheet-close">🔔 Manage Notification</a></li>`;
          $("#prmenu").html(prMenu);
        } else {
          $("a.float").removeClass("sheet-open");
          $("a.float").removeData("sheet");
          $("#prmenu").html("");
        }
  
        if (path === "persist_menu" && json.is_persist_menu) {
          homeBot.message.removeAll();
          var newmsg = [
            {
              add: {
                content:
                  "Tap the buttons below to explore our services:",
              },
            },
          ];
          addmessage(newmsg);
          // return addAction(json.payloads.persist_menu.messages[0]);
          return addAction(json.payloads.explore.messages[1]);
        } else if (path === "persist_menu" && !json.is_persist_menu) {
          var newmsg = [
            {
              add: {
                content:
                  "Your session has been refreshed. Explore the menu below:",
              },
            },
            {
              action: {
                button: {
                  action: [
                    {
                      text: "📙 Menu",
                      value: "explore",
                    },
                  ],
                },
              },
              delay: 1000,
            },
          ];
          return addmessage(newmsg);
        }
        // jQuery.getJSON(url, function(json) {
        var botstat = "";
        brandID = json.brand_id;
        photo = json.brand_icon;
        if (json.payloads[path]) {
          botstat = json.payloads[path].messages;
          // console.log(path);
          if (json.payloads[path].login) {
            // console.log("in login");
            var login = json.payloads[path].login;
            if (login == "true") {
              var vmn = localStorage.getItem("vmn");
              // console.log("in true");
              if (!vmn) {
                path = "get_started";
                offline(path, "");
                return false;
              }
            }
          }
        } else if (path == "addtocart") {
          path = "addtocart";
          var value = {
            action: {
              button: {
                action: [
                  {
                    icon: "check",
                    text: "Checkout",
                    value: "checkout",
                  },
                  {
                    icon: "check",
                    text: "Add more",
                    value: "products",
                  },
                ],
              },
            },
            save: "addtocart",
          };
          addAction(value);
        } else if (path == "attribute") {
        } else if (path == "neworder") {
          var finaladdress = localStorage.getItem(
            "userdata-" + brandID + "-finaladdress"
          );
          if (finaladdress) {
            path = "neworder";
          } else {
            path = "products";
          }
        } else {          
          // botstat = json.payloads["get_started"].messages;
          // path = "get_started";
          path = "explore";
        }
        brandID = json.brand_id;
        brand_post_url = json.brand_post_url;
        // brand_post_url = "https://parseapi.server.scvpl.in/add/insert";
        if (json.payloads[path].send == "true") {
          // console.log(json.payloads[path]);
          let mobile = localStorage.getItem(`vmn`);
          if(mobile) {
            let userIDPos = myArray.findIndex((el) => el.key === `mobile`);        
            if (userIDPos > -1) {
                myArray[userIDPos] = { key: `mobile`, value: mobile };
            } else if (userIDPos === -1) {
                myArray.push({ key: `mobile`, value: mobile });
            }
          }
          let brandIDPos = myArray.findIndex((el) => el.key === `brandid`);        
          if (brandIDPos > -1) {
              myArray[brandIDPos] = { key: `brandid`, value: brandID };
          } else if (brandIDPos === -1) {
              myArray.push({ key: `brandid`, value: brandID });
          }
          // console.log(myArray);
          if(myArray) {
            localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
          }
          var senddatajson = window.localStorage.getItem("json-" + brandID);
          var feedbackJson = false;
          if (
            ".5 star" == path ||
            path == "1 star" || path == "1star" ||
            "1.5 star" == path ||
            path == "2 star" || path == "2star" ||
            "2.5 star" == path ||
            path == "3 star" || path == "3star" ||
            "3.5 star" == path ||
            path == "4 star" || path == "4star" ||
            "4.5 star" == path ||
            path == "5 star" || path == "5star"
          ) {
            var userid = localStorage.getItem("vmn");
            var brandid = brandID || localStorage.getItem("brandid");
            var username = localStorage.getItem("name");
            var orderid = localStorage.getItem("feed_orderid");
            var chatfeedback = localStorage.getItem("chatfeedback") || localStorage.getItem(`userdata-${brandid}-feedback`);
            senddata = {
              name: username,
              userid: userid,
              brandid: brandid,
              orderid: orderid,
              rate: path,
              feedback: chatfeedback,
            };
            feedbackJson = true;
            localStorage.removeItem("feed_orderid");
          } else if (path === `sendaddress`) {
            senddata = JSON.parse(senddatajson).filter((obj) => {
              return obj.value !== `null`;
            });
            let mobileCheck = senddata.some((data) => data.key === `mobile`);
            if (!mobileCheck) {
              senddata.push({
                key: `mobile`,
                value: localStorage.getItem(`vmn`),
              });
            }
            let nameCheck = senddata.some((data) => data.key === `name`);
            if (!nameCheck) {
              senddata.push({
                key: `name`,
                value: localStorage.getItem(`name`),
              });
            }
            //SUPPLY ESSENTIAL BRAND VALUE FOR QUERY
            senddata.push({ key: "brandid", value: brandID });
          } else {
            let name = localStorage.getItem(`name`);
            if (
              localStorage.getItem(`name`) &&
              name &&
              localStorage.getItem("name") !== `null` &&
              name !== `null` &&
              localStorage.getItem("name") !== null &&
              name !== null &&
              typeof localStorage.getItem("name") !== `undefined` &&
              name !== `undefined` &&
              localStorage.getItem("name") !== undefined &&
              name !== undefined
            ) {
              senddata = JSON.parse(senddatajson);
            } else {
              //FILTER OUT NULL VALUE FROM POST DATA
              senddata = JSON.parse(senddatajson).filter((obj) => {
                return obj.value !== `null`;
              });
              //SUPPLY ESSENTIAL BRAND VALUE FOR QUERY
              senddata.push({ key: "brandid", value: brandID });
            }
          }
  
          if(feedbackJson == true) {
            senddata = senddata;
          } else {
            let namePos = senddata.findIndex(
              (el) =>
                el.key === `name` &&
                el.value ===
                  (localStorage.getItem(
                    `${localStorage.getItem("brandID")}-name`
                  ) || localStorage.getItem("name"))
            );
            namePos === -1 && senddata.push({key: "name", value: localStorage.getItem("name")});
            //SUPPLY ESSENTIAL BRAND VALUE FOR QUERY
            let brandIDPos = senddata.findIndex(
              (el) => el.key === `brandid` && el.value === brandID
            );
            brandIDPos === -1 && senddata.push({ key: "brandid", value: brandID });

            senddata = JSON.parse(senddatajson).filter((obj) => {
              return obj.value !== `null`;
            });
          }
          if (json.payloads[path].brand_post_url) {
            brand_post_url = json.payloads[path].brand_post_url;
          } else {
            brand_post_url = json.brand_post_url;
          }
          senddata = JSON.stringify(senddata);
          // console.log(brand_post_url);
          jQuery.ajax({
            type: "POST",
            url: brand_post_url,
            headers: { "Content-Type": "application/json" },
            data: senddata,
            success: (result) => {
              // console.log(result);
              if( brand_post_url == 'https://chatappapi.server.scvpl.in/check/table/booking/user' ) {
                var contentMsg =
                  result[0] &&
                  result[0].messages[1] &&
                  result[0].messages[1].add &&
                  result[0].messages[1].add.content;
                localStorage.setItem('reservation_msg', contentMsg);
              }
              localStorage.removeItem("json-" + brandID);
              var serverdata = jQuery.parseJSON(JSON.stringify(result));
              var arr = []; // Array to hold the keys
              myArray = [];
              myArray.push(
                {
                  key: "mobile",
                  value:
                    localStorage.getItem("vmn") ||
                    localStorage.getItem(`${brandID}-mobile`),
                },
                { key: "brandid", value: brandID }
              );
              // Iterate over localStorage and insert the keys that meet the condition into arr
              for (var i = 0; i < localStorage.length; i++) {
                var str1 = localStorage.key(i);
                var str2 = "userdata-" + brandID;
                if (str1.indexOf(str2) != -1) {
                  arr.push(localStorage.key(i));
                }
              }
              // Iterate over arr and remove the items by key
              if (arr.length) {
                for (var i = 0; i < arr.length; i++) {
                  localStorage.removeItem(arr[i]);
                }
              }
  
              var fda = serverdata[0] && serverdata[0].messages
                ? serverdata[0].messages
                : serverdata;
              jQuery.each(fda, (index, val) => {
                if (val.add) {
                  setTimeout(function () {
                    newMessage(val, variable);
                  }, index * 1000);
                } else if (val.action) {
                  setTimeout(function () {
                    addAction(val);
                  }, index * 2000);
                }
              });
            },
            error: (error) => {
              console.error(JSON.stringify(error));
            },
          });
        } else if (json.payloads[path].location == "true") {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              var positionfinal =
                position.coords.latitude + "," + position.coords.longitude;
              localStorage.setItem(
                "userdata-" + brandID + "-" + json.payloads[path].save,
                positionfinal
              );
              saveValue(json.payloads[path].save, positionfinal);
              if (json.payloads[path].nextpayload) {
                offline(json.payloads[path].nextpayload, positionfinal);
              }
            },
            function (error) {
              console.error(error);
              if (json.payloads[path].nextpayload) {
                var positionfinal =
                  "Could not get your location, please allow location and try again by clicking on the lock button.";
                offline(json.payloads[path].nextpayload, positionfinal);
              }
            }
          );
          // if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition(
          //     function(position) {
          //       var positionfinal =
          //         position.coords.latitude + "," + position.coords.longitude;
  
          //       localStorage.setItem(
          //         "userdata-" + brandID + "-" + json.payloads[path].save,
          //         positionfinal
          //       );
          //       if (json.payloads[path].nextpayload) {
          //         offline(json.payloads[path].nextpayload, positionfinal);
          //       }
          //     },
          //     function(positionDenied) {
          //       if (json.payloads[path].nextpayload) {
          //         var positionfinal =
          //           "Could not get your location, please allow location and try again by clicking on the lock button.";
          //         offline(json.payloads[path].nextpayload, positionfinal);
          //       }
          //     }
          //   );
          // }
        } else if (json.payloads[path].subscribe == "true") {
          let nextPayload = json.payloads[path].nextpayload || "";
          subscribenotifyapp(nextPayload);
          // if (navigator.userAgent.match(/iPad|webOS|iPhone|iPod|Blackberry/i)) {
          //   // do tablet stuff
          //   offline(nextPayload, "");
          // } else {
          //   // do desktop stuff
          //   subscribenotifyapp(nextPayload);
          // }
          // localStorage.setItem('userdata-'+brandID+'-'+ json.payloads[path].save, positionfinal);
        } else if (json.payloads[path].push == "true") {
          var tokenm = window.localStorage.getItem("msgtoken");
          var vmn = window.localStorage.getItem("vmn");
          var mbody = {
            notification: {
              title: json.payloads[path].title,
              body: json.payloads[path].body,
              image: json.payloads[path].image,
            },
            android: {
              priority: "high",
              notification: {
                title: json.payloads[path].title,
                body: json.payloads[path].body,
                icon: json.payloads[path].icon,
                image: json.payloads[path].image,
              },
            },
            data: {
              postid: "Unique ID",
              post_title: json.payloads[path].title,
              image: json.payloads[path].image,
              brandid: brandID,
              click_action: "https://chatapp.store/" + brandID,
            },
            token: tokenm,
          };
          // var mbody =  {
          //     "token" : "cJyz4_GGBVk:APA91bF3NrK1YdLzosfvnB-GeNc0w6WkUBhQYnBsdXjrVjn9tfHLWhOgEKTduYSFPlICnU_p1rkuIjDQhiQej_ejEo2SP54mWvk4wTzBNmlmLZmTeX7SSqQ9Ht6e1PGlwh9de88v0BQx",
          //     "notification": {
          //       "title": "FCM Message",
          //       "body": "This is a message from FCM",
          //       "icon": "https://miro.medium.com/max/800/1*4QemAP2IzD_8ct2f3kySvg.jpeg"
          //     },
          //     "webpush": {
          //       "headers": {
          //         "Urgency": "high"
          //       },
          //       "notification": {
          //         "body": "This is a message from FCM to web",
          //         "requireInteraction": "true",
          //         "badge": "https://miro.medium.com/max/800/1*4QemAP2IzD_8ct2f3kySvg.jpeg"
          //       }
          //     }
          //   };
          var deviceType = getMobileOperatingSystem();
          var sdata = [
            { key: "mobile", value: vmn },
            { key: "token", value: tokenm },
            { key: "action", value: "push" },
          ];
  
          jQuery.ajax({
            type: "POST",
            url: "https://newsum-fcm.server.scvpl.in/chatbot/notification",
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(mbody),
            success: (success) => {
              jQuery.ajax({
                type: "POST",
                url: "https://www.socialrecharge.com/compi.php",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(sdata),
                success: (success) => {},
                error: (error) => {
                  console.error(JSON.stringify(error));
                },
              });
  
              var cmessage = json.payloads[path].nextpayload;
              var avalue = {
                action: {
                  button: {
                    action: [
                      {
                        icon: "check",
                        text: "Continue",
                        value: cmessage,
                      },
                    ],
                  },
                },
                save: "subscribed",
              };
              addAction(avalue);
            },
            error: (error) => {
              console.error(JSON.stringify(error));
            },
          });
          // localStorage.setItem('userdata-'+brandID+'-'+ json.payloads[path].save, positionfinal);
        } else if (json.payloads[path].carousel == "true") {
          var pathv = json.payloads[path];
          var nurl =
            "https://www.socialrecharge.com/chat/carousel.html?q=" +
            encodeURI(JSON.stringify(pathv));
          newmsg = {
            add: {
              type: "embed",
              content: nurl,
            },
          };
  
          newMessage(newmsg);
        } else if (json.payloads[path].list == "true") {
          var pathv = json.payloads[path];
          // console.log("listapp: "+brandID);
          if(app.device.platform == "ios") {
            mainView.router.navigate({ name: "listapp", path: "/listapp/:brandid", params: { brandid: brandID } });
          } else {
            mainView.router.navigate({ name: "listapp", params: { brandid: brandID } });
          }
        } else if (json.payloads[path].contest == "true") {
          var pathv = json.payloads[path];
          var userid = localStorage.getItem("vmn");
          // console.log(userid + ", pathv : ", pathv);
          // mainView.router.navigate({ name: "spinandwin", params: { brandid: brandID } });
          addcontest(path);
          // var nurl = "https://www.socialrecharge.com/spinandwin/?bid=" +brandID + ".json&userid=" + userid;
          // newmsg = {
          //   add: {
          //     type: "embed",
          //     content: nurl,
          //   },
          // };
          // newMessage(newmsg);
        } else if(json.payloads[path].inappurl == "true"){
          var target = "_blank";
          var options = "location=yes";
          var resVal = json.payloads[path].link;
          if(is_url(resVal)) {
            // console.log(json.payloads[path].nextpayload);
            window.open(resVal, target);
            offline(json.payloads[path].nextpayload, "");
          }
        } else if (json.payloads[path].process) {
          /*var fname = json.payloads[path].process;
          // console.log("fname: " + fname);
          if (variable) {
            variables = variable.split("_");
            localStorage.setItem(
              "userdata-" + brandID + "-" + fname,
              variables[1]
            );
          }
          window[fname](json.payloads[path].messages);*/

          var fname = {};

          fname = json.payloads[path].process;
          import("./app.js")
            .then((func) => {
              // console.log(`Loaded app.js:-`,func);
              func[fname](json.payloads[path].messages);
            })
            .catch((err) =>
              console.error(
                `Error in loading ./app.js file. Error is ${JSON.stringify(err)}`
              )
            );
          // import * as fname from "./app.js";
          // console.log("fname: " + fname);
          if (variable) {
            let variables = variable.split("_");
            localStorage.setItem(
              "userdata-" + brandID + "-" + fname,
              variables[1]
            );
          }
        } else if (json.payloads[path].weatherForecast) {
          if (json.payloads[path].exist_start) {
            let message = {
              add: {
                content: "Hey {name}, so good to see you again..",
              },
            };
            newMessage(message);
          }
          const geocoder = new google.maps.Geocoder();
          let input = localStorage.getItem(
            `userdata-${brandID}-address_location`
          );
          // console.log("Lat Long: ", input);
          const latlngStr = input.split(",", 2);
          // console.log(`LatLng: ${latlngStr}`);
          const latlng = {
            lat: parseFloat(latlngStr[0]),
            lng: parseFloat(latlngStr[1]),
          };
          geocoder.geocode(
            {
              location: latlng,
            },
            (results, status) => {
              if (status === "OK") {
                if (results[0]) {
                  // console.log(`Address is : `, results[0].formatted_address);
                  let message = {
                    add: {
                      content: `Your location is : ${results[0].formatted_address}`,
                    },
                  };
                  newMessage(message);
                } else {
                  // console.log("No results found");
                }
              } else {
                console.error("Geocoder failed due to: " + status);
              }
            }
          );
          fetchUserLocationWeather(
            localStorage.getItem(`userdata-${brandID}-address_location`)
          );
        } else if (json.payloads[path].changeLocation) {
          // console.log(`inside change location with boolean true value`);
          /*let locationPopup = app.popup.open(".popup-weather-location");
          locationPopup.on("popup:open", function (popup) {
            console.log("Popup open");
            var input = document.getElementById("addressinput");
            var autoComplete = new google.maps.places.Autocomplete(input);
            console.log(`input: `, input);
            console.log(`autoComplete: `, autoComplete);
            jQuery("#addressinput")
              .geocomplete()
              .bind("geocode:result", function (event, result) {
                // $("#latitude").val(result.geometry.location.lat());
                // $("#longitude").val(result.geometry.location.lng());
                console.log(result);
                console.log(`LAT: `, result.geometry.location.lat());
                console.log(`LONG: `, result.geometry.location.lng());
              });
          });*/
  
          app.popup
            .create({
              content:
                '<div class="popup">' +
                '<div class="block">' +
                // '<p><a href="#" class="link popup-close">Close me</a></p>' +
                "<p style='text-align:center;'>Enter city, for which, you want the forecast.</p>" +
                '<div class="list no-hairlines-md">' +
                "<ul>" +
                '<li class="item-content item-input">' +
                '<div class="item-inner">' +
                '<div class="item-title item-floating-label">City</div>' +
                '<div class="item-input-wrap">' +
                '<input type="text" placeholder="Enter city name" id="addressinput" autocomplete="on">' +
                // '<span class="input-clear-button"></span>' +
                "</div>" +
                "</div>" +
                "</li>" +
                "</ul>" +
                "<ul>" +
                "<li class='item-content'>" +
                '<div class="item-inner">' +
                "<button class='button button-raised button-rounded button-fill popup-close'>Get Forecast</button>" +
                "</div>" +
                "</li>" +
                "<li class='item-content'>" +
                '<div class="item-inner">' +
                "<button class='button button-raised button-rounded button-fill color-red popup-close'>Close</button>" +
                "</div>" +
                "</li>" +
                "</ul>" +
                "</div>" +
                "</div>" +
                "</div>",
              // Events
              on: {
                open: function (popup) {
                  // console.log("Popup open");
                  var input = document.getElementById("addressinput");
                  var autoComplete = new google.maps.places.Autocomplete(input);
                  // console.log(`input: `, input);
                  // console.log(`autoComplete: `, autoComplete);
                  /*jQuery("#addressinput")
                    .geocomplete()
                    .bind("geocode:result", function (event, result) {
                      // $("#latitude").val(result.geometry.location.lat());
                      // $("#longitude").val(result.geometry.location.lng());
                      console.log(result);
                      console.log(`LAT: `, result.geometry.location.lat());
                      console.log(`LONG: `, result.geometry.location.lng());
                    });*/
                },
                opened: function (popup) {
                  // console.log("Popup opened");
                },
                close: function (popup) {
                  if (!document.getElementById("addressinput").value) return;
                  var geocoder = new google.maps.Geocoder();
                  geocoder.geocode(
                    { address: document.getElementById("addressinput").value },
                    function (results, status) {
                      // console.log(`geocode result: `, results);
                      // console.log(`geocode status: `, status);
                      if (status == google.maps.GeocoderStatus.OK) {
                        var latitude = results[0].geometry.location.lat();
                        var longitude = results[0].geometry.location.lng();
                        // console.log(latitude, longitude);
                        let latLong = `${latitude},${longitude}`;
                        // console.log(`Lat Long : `, latLong);
                        fetchUserLocationWeather(latLong);
                      } else {
                        let message = {
                          add: {
                            content: `Sorry, data is not available for this city.`,
                          },
                        };
                        newMessage(message);
                        // console.log(`geocode result, inside else: `, results);
                        // console.log(`geocode status, inside else: `, status);
                      }
                    }
                  );
                },
              },
            })
            .open();
          /*homeBot.action
            .text({
              action: {
                type: "html",
                content:
                  "<input type='text' id='addressinput' placeholder='enter your address'>",
              },
            })
            .then(function (res) {
              console.log(`res: `, res);
              jQuery("#addressinput")
                .geocomplete()
                .bind("geocode:result", function (event, result) {
                  // $("#latitude").val(result.geometry.location.lat());
                  // $("#longitude").val(result.geometry.location.lng());
                  console.log(result);
                  console.log(`LAT: `, result.geometry.location.lat());
                  console.log(`LONG: `, result.geometry.location.lng());
                });
            });
          setTimeout(() => {
            var input = document.getElementsByClassName("input-focused");
            var autoComplete = new google.maps.places.Autocomplete(input);
            console.log(`input: `, input);
            console.log(`autoComplete: `, autoComplete);
          }, 5000);
          jQuery("#addressinput").on("change", function () {
            console.log(`On change`);
            $("#addressinput")
              .geocomplete()
              .bind("geocode:result", function (event, result) {
                // $("#latitude").val(result.geometry.location.lat());
                // $("#longitude").val(result.geometry.location.lng());
                console.log(result);
                console.log(`LAT: `, result.geometry.location.lat());
                console.log(`LONG: `, result.geometry.location.lng());
              });
          });*/
          // fetchUserLocationWeather(
          //   localStorage.getItem(`userdata-${brandID}-address_location`)
          // );
        } else if (json.payloads[path].todayHoroscope) {
          fetchTodayHoroscope(
            localStorage.getItem(`userdata-${brandID}-todayHoroscope`)
          );
        } else if (json.payloads[path].datePicker) {
          let params = json.payloads[path].params;
          // console.log(`Params: `, params);
          openDatePicker(params);
        } else if (json.payloads[path].timePicker) {
          let params = json.payloads[path].params;
          openTimePicker(params);
        } else if (json.payloads[path].noOfGuests) {
          let params = json.payloads[path].params;
          openGuestPicker(params);
        } else if (json.payloads[path].userName) {
          let params = json.payloads[path].params;
          openUsername(params);
        } else if (json.payloads[path].payviaupi) {
          let params = json.payloads[path].params;
          payviaupi(params);
        } else if (json.payloads[path].range) {
          let params = json.payloads[path].params;
          // console.log(`Params: `, params);
          constructElement(params);
        } else if (json.payloads[path].placePicker) {
          let params = json.payloads[path].params;
          // console.log(`Params: `, params);
          placePicker(params);
        } else if (json.payloads[path].calculate) {
          var f = parseInt(
              localStorage.getItem("userdata-" + brandID + "-total")
            ),
            b = json.payloads[path].results;
          if (!f) f = 0;
          jQuery.each(b, (e, t) => {
            var o = parseInt(t.score_min),
              n = parseInt(t.score_max);
            // console.log("minscore ", o);
            // console.log("maxscore ", n);
            // console.log("total ", f);
            if (f >= o && f <= n) {
              var i = t.messages;
              jQuery.each(i, (e, t) => {
                var o = 1000;
                t.delay && (o = t.delay),
                  t.add
                    ? setTimeout(function () {
                        newMessage(t, variable);
                      }, e * o)
                    : t.action
                    ? setTimeout(function () {
                        addAction(t);
                      }, e * o)
                    : t.carousel
                    ? addcarousel(t)
                    : t.list && addlist(t);
              });
            }
            // console.log(t.messages);
          });
        } else if (json.payloads[path].subscribeToTopic) {
          let nextPayload = json.payloads[path].nextpayload || "";
          if (navigator.userAgent.match(/iPad|webOS|iPhone|iPod|Blackberry/i)) {
            // do tablet stuff
            offline(nextPayload, "");
          } else {
            subscribenotifyapp(nextPayload);
            // offline(json.payloads[path].nextpayload, "");
            // do desktop stuff
          }
          // window.FirebasePlugin.subscribe(
          //   json.payloads[path].topic || brandID,
          //   function () {
          //     console.log("Subscribed to topic");
          //   },
          //   function (error) {
          //     console.error("Error subscribing to topic: " + error);
          //   }
          // );
        } else if (json.payloads[path].saveUserAndBrand){
          // window[json.payloads[path].invokeFunction](json.payloads[path]);
          // offline(json.payloads[path].nextpayload, "");
          import(`./app.js`)
            .then((func) => {
              func[json.payloads[path].invokeFunction](json.payloads[path]);
            })
            .catch((err) => {
              console.error(`Error is ${JSON.stringify(err)}`);
            });
          offline(json.payloads[path].nextpayload, "");
        } else if (json.payloads[path].checkAuth){    
          // const triggerBack = json.payloads[path] && json.payloads[path].triggerBack ? json.payloads[path].triggerBack : ``;
          // window[json.payloads[path].authFunction](json.payloads[path].messages, triggerBack);
          const triggerBack =
          json.payloads[path] && json.payloads[path].triggerBack
            ? json.payloads[path].triggerBack
            : ``;
          import(`./app.js`)
            .then((func) => {
              func[json.payloads[path].authFunction](
                json.payloads[path].messages,
                triggerBack
              );
            })
            .catch((err) => {
              console.error(`Error is ${JSON.stringify(err)}`);
            });
        } else if (json.payloads[path].validate){
          // window["validateUserAction"](json.payloads[path].messages, json.payloads[path].validationCriteria);
          import(`./app.js`)
          .then((func) => {
            func[json.payloads[path].validateUserAction](
              json.payloads[path].messages,
              json.payloads[path].validationCriteria
            );
          })
          .catch((err) => {
            console.error(`Error is ${JSON.stringify(err)}`);
          });
        } else if (json.payloads[path].dynamicSocialHandle){
          // window["dynamicSocialMediaHandle"](json.payloads[path].socialMedia);
          // console.log(`Dynamic Social Media handle.`);
          import(`./app.js`)
            .then((func) => {
              func["dynamicSocialMediaHandle"](json.payloads[path].socialMedia, json.payloads[path].messages)
            })
            .catch((err) => {
              console.error(`Error is ${JSON.stringify(err)}`);
            });
        } else if (json.payloads[path].calculateTax) {
          calculateTax(json.payloads[path], path);
        } else if(json.payloads[path].vcard){
          createVCard(json.payloads[path]);
        } else if(json.payloads[path].video && json.payloads[path].type === "YouTubeVideo"){
          // console.log("Embedding YouTube Video");
          embedAndControlYouTubeVideo(json.payloads[path]);
        } else {          
          jQuery.each(botstat, (index, val) => {
            if (val.add) {
              setTimeout(function () {
                newMessage(val, variable);
              }, index * 1000);
            } else if (val.action) {
              setTimeout(function () {
                addAction(val);
              }, index * 1000);
            } else if (val.carousel) {
              addcarousel(val);
            } else if (val.list) {
              addlist(val);
            } else if (val.contest) {
              addcontest(val);
            } else if (val.scratch) {
              winscratch(val);
            }
          });
        }
      }).catch(error => {
        if (
          error.code === 209 ||
          error.code === 206 ||
          error.message === `Invalid session token`
        ) {
          logout();
          return;
        }
      });
    }
  
    var url = jQuery(".botui-app-container").data("url");
    var payload = jQuery(".botui-app-container").data("payload");
    //  var url = "profile-bot-4eb0a-amirkhan-export.json";
    // jQuery.getJSON(url, function (json) {
    code &&
    query.get(code).then((data) => {
      json = JSON.parse(JSON.stringify(data));
      json = json.payload_json;
      // console.log(json);
      brandID = json.brand_id;
      // console.log(brandID);
      // console.log(json.prefetch);
      //HAVE TO FETCH SOME VALUE IN BACKGROUND
      if (json.prefetch) {
        for (const key in json.prefetch) {
          //FETCHING BRAND'S SERVICABLE AREA
          if (
            !localStorage.getItem(key) ||
            localStorage.getItem(key) === `null`
          ) {
            jQuery.ajax({
              type: "GET",
              url: json.prefetch[key],
              headers: { "Content-Type": "application/json" },
              data: { brandid: brandID },
              dataType: "json",
              success: (result) => {
                localStorage.setItem(
                  brandID + "_" + key,
                  JSON.stringify(result[key])
                );
              },
              error: (error) => {
                console.error(
                  `RETURNED ERROR FROM PREFETCH VALUE API ${JSON.stringify(
                    error
                  )}`
                );
              },
            });
          }
        }
      }
  
      var localbrandid = localStorage.getItem("json-" + brandID);
      if (localbrandid !== null) {
        localStorage.removeItem("json-" + brandID);
        myArray = [];
      }
      apiaiid = json.apiaikey;
      photo = json.brand_icon;
      window.ga =
        window.ga ||
        function () {
          (ga.q = ga.q || []).push(arguments);
        };
      ga.l = +new Date();
      ga("create", "UA-126023259-1", "auto");
      ga("send", "pageview");
  
      // Feature detects Navigation Timing API support.
      if (window.performance) {
        // Gets the number of milliseconds since page load
        // (and rounds the result since the value must be an integer).
        var timeSincePageLoad = Math.round(performance.now());
  
        // Sends the timing hit to Google Analytics.
        ga("send", "timing", "Chat Response Time", "load", timeSincePageLoad);
      }
      // ga('send', {
      //   hitType: 'social',
      //   socialNetwork: 'Facebook',
      //   socialAction: 'Like',
      //   socialTarget: 'https://newsum.in'
      // });
  
      if (json.apiaiid) {
        apiaiid = json.apiaiid;
      }
  
      var checkuser = localStorage.getItem("vmn");
      var name = localStorage.getItem("name");
      var startpath = getQueryStringValue("payload");
      const userDeliveryAddress = localStorage.getItem(`${brandID}-building`);
      if (payload) {
        offline(payload, "");
      } /*else if (checkuser) {
        var nyArray = [];
        // var nod = JSON.stringify(localStorage.getItem('json-'+brandID));
        // nyArray.push(nod);
        var mnd = { key: "mobile", value: checkuser };
        // var mnb = {key:"brandid", value:brandID};
        nyArray.push(mnd);
        // nyArray.push(mnb);
        if (name) {
          var nam = { key: "name", value: name };
          nyArray.push(nam);
        }
        // var tokenm =  window.localStorage.getItem("msgtoken");
        // if(tokenm){
        //   var tk = {key:"token", value:tokenm};
        //   nyArray.push(tk);
        // }
        nyArray.push({ key: `brandid`, value: brandID });
        localStorage.setItem("json-" + brandID, JSON.stringify(nyArray));
  
        myArray = nyArray;
        nyArray = [];
        if (startpath) {
          offline(startpath, "");
        } else if (
          name &&
          name !== `null` &&
          name !== null &&
          typeof name !== `undefined` &&
          name !== undefined &&
          name !== `undefined` &&
          name !== ""
        ) {
          if (!userDeliveryAddress && json.brand_category === `E-Commerce`) {
            //NOT FOUND USER DELIVERY ADDRESS FOR OUTLET
            offline(`verifyyourself`, ``);
          } else {
            // EDITED VAERSION, CHECK IF USER HAS NAME
            offline("exist_start", "");
          }
        } else {
          //IF USER'S NAME ISN'T SET IN LOCAL STORAGE BUT MOBILE NUMBER IS.
          offline(`verifyname`, ``);
        }
      } else if (startpath) {
        offline(startpath, "");
      } else {
        offline("get_started", "");
      }*/
      var $htmlOrBody = jQuery("html, body"), // scrollTop works on <body> for some browsers, <html> for others
        scrollTopPadding = 8;
  
      jQuery("textarea").focus(function () {
        // get textarea's offset top position
        var textareaTop = jQuery(this).offset().top;
        // scroll to the textarea
        $htmlOrBody.scrollTop(textareaTop - scrollTopPadding);
      });
    }).catch(error => {
      if (
        error.code === 209 ||
        error.code === 206 ||
        error.message === `Invalid session token`
      ) {
        logout();
        return;
      }
    });
  
    function handleExternalURLs() {
      // Handle click events for all external URLs
      // console.log("handleExternalURLs : ", app.device.platform);
      if (app.device.platform.toUpperCase() === "ANDROID") {
        jQuery(document).on("click", 'a[href^="http"]', function (e) {
          e.preventDefault();
          var url = jQuery(this).attr("href");
          // console.log("url : ", url);
          // navigator.app.loadUrl(url, { openExternal: true });
          var ref = window.open(url);
          ref.addEventListener("loadstart", function () {
            // console.log("loadstart");
            mainView.router.back();
          });
        });
      } else if (app.device.platform.toUpperCase() === "IOS") {
        jQuery(document).on("click", 'a[href^="http"]', function (e) {
          var url = $(this).attr("href");
          window.open(url, "_system");
          e.preventDefault();
        });
      } else {
        // Leave standard behaviour
      }
    }
  
    function subscribenotifyapp(nextPayload) {
      try{
        const messaging = FIREBASE.messaging();
        messaging.getToken({vapidKey: 'BKrjiH7SY-WQR78OWs0Cv1RKfZo81sFoRm0v-t1PB2midMYQNkWvGSMoDDoOOOvzyCOObeotFTaVWoNeGEm-gBg'})
        .then((currentToken)=>{
          // console.log(`Current token : ${currentToken}`);
          if(currentToken){
            saveFCMToken(currentToken, nextPayload);
          } else{
            messaging.requestPermission()
            .then(function(permission){
              // console.log(`Permission: ${permission}`);
              if(permission === `granted`){
                messaging.getToken({vapidKey: 'BKrjiH7SY-WQR78OWs0Cv1RKfZo81sFoRm0v-t1PB2midMYQNkWvGSMoDDoOOOvzyCOObeotFTaVWoNeGEm-gBg'})
                .then((fcmToken)=>{
                  // console.log(`FCM token : ${fcmToken}`);
                  if(fcmToken){
                    saveFCMToken(fcmToken, nextPayload);                    
                  }
                })
              } else{
                console.log(`Permission denied.`)
              }
            }).catch(function(error){
              console.error(`Error in FCM in built requestPermission. Error is ${JSON.stringify(error)}`);
            });
          }
        }).catch((err)=>{
          console.error(`Error in fetching FCM token. Error is ${JSON.stringify(err)}`);
        });      
      }catch(error){
        console.error(`Error in subscribenotifyapp module. Error is ${JSON.stringify(error)}`);
      }
    }
  
    function saveFCMToken(fcmToken, nextPayload){
      var bid = brandID;
      try{
        const FCMTopicSubscriptionURL = `https://newsum-fcm.server.scvpl.in/subscribe/topics`;
        app.request.promise.postJSON(FCMTopicSubscriptionURL, { tag:bid, user_token:fcmToken })
        .then(function (res) {
          console.log(`FCM success token Subscription response:`,res.data);
        }).catch(function(err){
          console.error(`FCM topic subscription error xhr:`,err.xhr)
          console.error(`FCM topic subscription error status:`,err.status)
          console.error(`FCM topic subscription error message:`,err.message)
        });
        window.localStorage.setItem("msgtoken", fcmToken);
        // console.log("Bid " + bid);
        var ntoken = { key: "token", value: fcmToken };
        window.localStorage.setItem(
          "userdata-" + bid + "-token",
          fcmToken
        );
        // var myArray = JSON.parse(
        //   window.localStorage.getItem("json-" + bid)
        // );      
        myArray.push(ntoken);
        // Aman ji's code (coded date 17-05-2021)
        console.log(`App DEVICE: `,app.device);
        const DEVICE = app.device;
        let pushType;
        const deviceType = DEVICE.ios ? 'ios' : DEVICE.android ? 'android' : DEVICE.desktop ? 'desktop' : DEVICE.androidChrome ? 'AndroidChrome' : undefined;
        
        if (deviceType === 'ios') {
          pushType = 'apn';
        } else if(deviceType === 'android'){
          pushType = 'gcm';
        } else if(DEVICE.windows && DEVICE.os === `windows`){
          pushType = 'gcm';
        } else if (DEVICE.macos || DEVICE.os === `macos`) {
          pushType = 'apn';
        }

        if(deviceType){
          saveValue(`deviceType`, deviceType.trim());
          pushType ? saveValue(`pushType`, pushType) : undefined;
        }
        
        localStorage.setItem(
          "json-" + brandID,
          JSON.stringify(myArray)
        );
        if (localStorage.getItem("vmn")) {
          offline("saveFcmToken", "");
          /*var chatApp = Parse.Object.extend("ChatAppUsers");
          var bQuery = new Parse.Query(chatApp);
          bQuery.equalTo("mobile", localStorage.getItem("vmn"));
          bQuery.first().then(function (result) {
            console.log('Result:',result);
            // result.set("device_uuid", app.device.uuid);
            // result.set("fcmToken", fcmToken);
            // result.save();
          });*/
          nextPayload !== ""
            ? offline(nextPayload, "")
            : offline("subscribe", "");
        } else {
          offline("get_started", "");
        }
      }catch(err){
        console.error(`Error in saveFCMToken module. Error is ${JSON.stringify(err)}`);
      }
    }
  
    function newMessage(response, variable) {
      // console.log(response);
      var content = "";
      var newcontent = "";
      var newtype = "";
      // let brandid = localStorage.getItem(`brandid`);
  
      content = response.add.content;
  
      // var str = content.substring(
      //     content.lastIndexOf("{") + 1,
      //     content.lastIndexOf("}")
      // );
      if (content.includes("#LEAD_USER_FIRST_NAME#")) {
        // console.log(`Special character detected`);
        content =
          localStorage.getItem(`${brandID}-name`) ||
          localStorage.getItem(`userdata-${brandID}-name`)
            ? content.replace(
                "#LEAD_USER_FIRST_NAME#",
                localStorage.getItem(`${brandID}-name`)
              )
            : content.replace("#LEAD_USER_FIRST_NAME#", `friend`);
      }
  
      // content = replaceDynamicValues(content);
      // console.log(`content: `, content);
      var str = getWordsBetweenCurlies(content);
      // console.log("Str:",str);
      function getWordsBetweenCurlies(strg) {
        var results = [],
          re = /{([^}]+)}/g,
          text;
        while ((text = re.exec(strg))) {
          results.push(text[1]);
        }
        return results;
      }
  
      var nval = "";
      brandID = localStorage.getItem(`brandid`) || brandID;
      if (str) {
        jQuery.each(str, function (index, val) {
          nval = localStorage.getItem("userdata-" + brandID + "-" + val);
          if (str == "name") {
            nval =
              localStorage.getItem(`${brandID}-name`) ||
              localStorage.getItem("name") ||
              localStorage.getItem(`userdata-${brandID}-name`);
            if (nval == null || nval == "null") {
              nval = "friend";
            }
          } else if(val == "remoteUser"){
            nval = localStorage.getItem(`remoteUser`);              
          }
          nval = nval ? nval : 'friend';
  
          if (nval) {
            var action = val.split("_");
            if (action[1] == "location") {
              if (
                variable ==
                "Could not get your location, please allow location and try again by clicking on the lock button."
              ) {
                newcontent = variable;
              } else {
                newcontent =
                  "https://maps.google.com/maps?q=" +
                  variable +
                  "&z=15&output=embed";
                newtype = "embed";
              }
            } else {
              val = "{" + val + "}";
              content = content.replace(val, nval);
            }
          }
        });
      } else {
        content = response.add.content;
      }
  
      if (newcontent) {
        homeBot.message
          .add({
            photo: brandID === `chatappstore` ? HOME_PAGE_LOGO : photo,
            loading: true,
            content: newcontent,
            type: newtype,
            autoHide: false,
            delay: 1000,
          })
          .then(function () {
            // var lnt = newcontent.length;
            // var tt = lnt/25;
            // var td = Math.floor(tt);
  
            // var fdelay = td*1000;
  
            str = "{" + str + "}";
            if (nval) {
              content = content.replace(str, nval);
            }
            // setTimeout(() => {
  
            homeBot.message
              .add({
                content: content,
                photo: brandID === `chatappstore` ? HOME_PAGE_LOGO : photo,
                type: response.add.type,
                autoHide: false,
                loading: true,
                delay: 1000,
              })
              .then(function () {
                if (response.nextpayload) {
                  offline(response.nextpayload, content);
                }
              });
            // }, fdelay);
          });
      } else {
        // var lnt = content.length;
        // var tt = lnt/25;
        // var td = Math.floor(tt);
        // var fdelay = td*1000;
        // setTimeout(() => {
        homeBot.message
          .add({
            content: content,
            photo: brandID === `chatappstore` ? HOME_PAGE_LOGO : photo,
            type: response.add.type,
            autoHide: false,
            loading: true,
            delay: 1000,
          })
          .then(function (index) {
            if (response.nextpayload) {
              offline(response.nextpayload, content);
            }
            /* *** Coded 05-07-2021 given by sir *** */
            if(response.counter){
              var timer = parseInt(response.counter)*1000;
              var gpayload = "explore";
                // console.log("counter: "+timer);
                t   =    setTimeout(function () {
                  // console.log("index "+index);
                  
                  // homeBot.message.remove(index+2);
                  homeBot.message.remove(index+1);
                  homeBot.message.remove(index);
                  homeBot.message.remove(index-1);
                  homeBot.message.remove(index-2);
                  // homeBot.message.remove(index-3);
                  if(response.finish){
                    gpayload = response.finish;
                  }
                  // var nval = json.payloads[gpayload].results;
                  // console.log("time up: "+nval);
                  var fact =    {
                    "action": {
                      "button": {
                        "action": [
                          {
                            "text": "🔄 Click Here",
                            "value": gpayload
                          }
                        ]
                      }
                    },
                    "save": "again"
                  };
                  addAction(fact);
                }, timer); 
              }
              if(response.clearCounter){
                // console.log("clearing timeout");
                homeBot.message.remove(index-2);
                homeBot.message.remove(index-3);
                homeBot.message.remove(index-4);
                homeBot.message.remove(index-5);
                homeBot.message.remove(index-6);
                clearTimeout(t);
              }
          });
        // }, fdelay);
      }
      jQuery("#ending")[0].scrollIntoView(true);
    }
  
    function showNotification(payload, pageid) {
      var myPhotoBrowserPopup = "";
      var msgPayload = JSON.parse(payload);
      // console.log(msgPayload);
      if (msgPayload) {
        if (msgPayload.template_type == "quick_reply") {
          var textmessage = {
            add: {
              content: msgPayload.text,
            },
          };
          newMessage(textmessage);
          var quickButton = msgPayload.quick_replies;
          if (quickButton) {
            var vmn = window.localStorage.getItem("vmn");
            var butt = [];
            jQuery.each(quickButton, function (q) {
              if (quickButton[q].content_type == "user_phone_number") {
                var vmn = window.localStorage.getItem("vmn");
                if (vmn) {
                  butt.push({ icon: "phone", text: "Call", value: vmn });
                }
              } else if (quickButton[q].content_type == "user_email") {
                var email = window.localStorage.getItem(brandID + "-email");
                if (email) {
                  butt.push({
                    icon: "envelope",
                    text: "Email",
                    value: email,
                  });
                }
              } else {
                butt.push({
                  icon: "check",
                  text: quickButton[q].title,
                  value: quickButton[q].payload,
                });
              }
            });
            // console.log("Butt : ", butt);
            var value = {
              action: {
                button: {
                  action: butt,
                },
              },
              medium: "fb",
              pageid: pageid,
            };
            addAction(value);
          }
        } else if (msgPayload.template_type == "receipt") {
          localStorage.setItem("receipt_payload", payload);
          var myMsg =
            '<div class="list media-list" style="margin:0px;"><div class="card" onclick="openReceipt()" style="border-radius:20px;width:98%;float:left;">' +
            '<div class="card-header">Order Confirmation</div>' +
            '<div class="card-content"><ul>';
          var eleReceipt = msgPayload.elements;
          if (eleReceipt) {
            jQuery.each(eleReceipt, function (r) {
              // console.log(eleReceipt[r].title);
              myMsg +=
                "<li>" +
                '<div class="item-content">' +
                '<div class="item-media">' +
                '<img src="' +
                eleReceipt[r].image_url +
                '" style="min-height:80px;width:80px;max-width:inherit;border-radius:initial;">' +
                "</div>" +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">' +
                eleReceipt[r].title +
                "</div>" +
                "</div>";
              if (eleReceipt[r].subtitle) {
                myMsg +=
                  '<div class="item-text">' + eleReceipt[r].subtitle + "</div>";
              }
              myMsg += "</div>" + "</div>" + "</li>";
            });
          }
          myMsg +=
            '</ul><div class="card-content-inner">' +
            '<p style="margin:5px 15px;line-height:20px;"><span style="font-size:13px;">Paid with</span><br><b>' +
            msgPayload.payment_method +
            "</b></p>";
          if (msgPayload.address) {
            myMsg +=
              '<p style="margin:5px 15px;line-height:20px;"><span style="font-size:13px;">Ship to</span><br><b>' +
              msgPayload.address.street_1 +
              ", " +
              msgPayload.address.city +
              ", " +
              msgPayload.address.state +
              ", " +
              msgPayload.address.country +
              " " +
              msgPayload.address.postal_code +
              "</b></p>";
          }
          myMsg +=
            '</div></div><div class="card-footer">Total <b style="top:12px;right:15px;position:absolute;">' +
            msgPayload.summary.total_cost +
            "</b></div></div></div>";
          var result = {
            add: {
              type: "html",
              content: myMsg,
            },
          };
          newMessage(result);
        } else if (msgPayload.template_type == "button") {
          var buttmsg = {
            add: {
              content: msgPayload.text,
            },
          };
          newMessage(buttmsg);
          var buttons = msgPayload.buttons;
          var butt = [];
          jQuery.each(buttons, function (q) {
            if (buttons[q].type == "web_url") {
              //myMsg += '<a href="#" class="link" onClick="openUrl(\''+element[i].buttons[j].url+'\');" style="text-transform:uppercase;">' + element[i].buttons[j].title + '</a>';
              var bUrl = buttons[q].url;
              if (bUrl.indexOf(" 91") >= 0) {
                var buttUrl = bUrl.replace(" 91", "+91");
              } else {
                var buttUrl = bUrl;
              }
              butt.push({
                icon: "check",
                text: buttons[q].title,
                value: buttUrl,
              });
            } else if (buttons[q].type == "phone_number") {
              myMsg +=
                '<a href="tel:' +
                buttons[q].payload +
                '" class="link external" style="text-transform:uppercase;display:inline-table;">' +
                element[i].buttons[j].title +
                "</a>";
            } else if (buttons[q].type == "postback") {
              //myMsg += '<a href="#" class="link" onClick="offline(\'' + element[i].buttons[j].payload + '\');" style="text-transform:uppercase;">' + element[i].buttons[j].title + '</a>';
              butt.push({
                icon: "check",
                text: buttons[q].title,
                value: buttons[q].payload,
              });
            }
          });
          // console.log("Butt : ", butt);
          var value = {
            action: {
              button: {
                action: butt,
              },
            },
            medium: "fb",
            pageid: pageid,
          };
          addAction(value);
        } else if (msgPayload.template_type == "media") {
          var eleMedia = msgPayload.elements;
          var myMsg = "";
          if (eleMedia) {
            jQuery.each(eleMedia, function (m) {
              // console.log(eleMedia[m].media_type);
              if (eleMedia[m].media_type == "image") {
                var imgUrl = eleMedia[m].url;
                myPhotoBrowserPopup = app.photoBrowser.create({
                  photos: [imgUrl],
                  zoom: 400,
                  theme: "dark",
                  backLinkText: "Back",
                  type: "popup",
                });
                myMsg +=
                  '<div class="card demo-card-header-pic" style="border-radius:25px;min-width:275px;box-shadow:none;">' +
                  '<div style="background-image:url(' +
                  eleMedia[m].url +
                  ');border-radius:25px 25px 0px 0px;height:60vw;" valign="bottom" class="card-header color-white no-border pb-popup"></div>';
              } else if (eleMedia[m].media_type == "video") {
                myMsg +=
                  '<div class="card demo-card-header-pic" style="border-radius:25px;min-width:275px;box-shadow:none;">' +
                  '<div class="card-content">' +
                  '<iframe width="100%" style="border:#000 1px solid;border-radius:25px 25px 0px 0px;height:40vw;" src="' +
                  eleMedia[m].url +
                  '" frameborder="0" allowfullscreen></iframe>' +
                  "</div>";
              }
              myMsg += '<div class="card-footer">';
              var eleButton = eleMedia[m].buttons;
              if (eleButton) {
                jQuery.each(eleButton, function (j) {
                  // console.log(eleMedia[m].buttons[j].title);
                  if (eleMedia[m].buttons[j].type == "web_url") {
                    var bUrl = eleMedia[m].buttons[j].url;
                    if (bUrl.indexOf(" 91") >= 0) {
                      var buttUrl = bUrl.replace(" 91", "+91");
                    } else {
                      var buttUrl = bUrl;
                    }
                    myMsg +=
                      '<a href="#" class="link external" onClick="openUrl(\'' +
                      buttUrl +
                      '\');" style="text-transform:uppercase;">' +
                      eleMedia[m].buttons[j].title +
                      "</a>";
                  } else if (eleMedia[m].buttons[j].type == "phone_number") {
                    myMsg +=
                      '<a href="tel:' +
                      eleMedia[m].buttons[j].payload +
                      '" class="link external" style="text-transform:uppercase;display:inline-table;">' +
                      eleMedia[m].buttons[j].title +
                      "</a>";
                  }
                });
              }
              myMsg += "</div></div>";
            });
          }
          var result = {
            add: {
              type: "html",
              content: myMsg,
            },
          };
          newMessage(result);
        } else if (msgPayload.template_type == "airline_boardingpass") {
          var bPass = msgPayload.boarding_pass;
          // console.log(bPass);
          localStorage.setItem("boarding_payload", payload);
          var myMsg =
            '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#0085ff;color:#fff;border-radius:20px;min-width:275px;">' +
            '<div class="card-header" style="border-bottom:#fff 0.5px solid;margin:0px 10px;font-size:13px;padding:10px 0px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="' +
            bPass[0].header_image_url +
            '" width="36" height="36"></div><div style="width:30%;float:left;">Terminal<br>' +
            bPass[0].flight_info.departure_airport.terminal +
            '</div><div align="center" style="width:20%;float:right;">Gate<br>' +
            bPass[0].flight_info.departure_airport.gate +
            "</div></div></div>" +
            '<div class="card-content"><table width="100%" style="padding:5px 10px;">' +
            '<tr><th align="left"><span style="font-size:13px;">Passengers</span></th><th align="right"><span style="font-size:13px;">Seat</span></th></tr>';
          // var sfields = bPass[0].secondary_fields;
          jQuery.each(bPass, function (p) {
            myMsg +=
              '<tr><td><b style="font-size:13px;">' +
              bPass[p].passenger_name +
              '</b></td><td align="right"><b>' +
              bPass[p].seat +
              "</b></td></tr>";
          });
          myMsg += "</table>";
          if (bPass[0].flight_info) {
            myMsg +=
              '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:22%;"><span style="font-size:13px;">Flight</span><br><b>' +
              bPass[0].flight_info.flight_number +
              "</b></p>";
          }
          myMsg +=
            '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;">Departs</span><br><b>' +
            bPass[0].flight_info.flight_schedule.departure_time +
            "</b></p>";
          myMsg +=
            '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;">Arrive</span><br><b>' +
            bPass[0].flight_info.flight_schedule.arrival_time +
            "</b></p>";
          myMsg +=
            '<table width="100%" style="padding:0px 10px;">' +
            '<tr><th align="left"><span style="font-size:13px;">' +
            bPass[0].flight_info.departure_airport.city +
            '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;">' +
            bPass[0].flight_info.arrival_airport.city +
            "</span></th></tr>" +
            '<tr><td><span style="font-size:40px;">' +
            bPass[0].flight_info.departure_airport.airport_code +
            '</span></td><td align="center"><img src="' +
            bPass[0].logo_image_url +
            '" width="64" height="64"></td><td align="right"><span style="font-size:40px;">' +
            bPass[0].flight_info.arrival_airport.airport_code +
            "</span></td></tr>" +
            "</table>";
          myMsg +=
            '</div><div class="card-footer" style="background:#fff;width:100%;"><a href="#" class="link" onclick="openBoarding()">View Boarding Pass</a></div>' +
            "</div></div>";
          var result = {
            add: {
              type: "html",
              content: myMsg,
            },
          };
          newMessage(result);
        } else if (msgPayload.template_type == "airline_checkin") {
          var bPass = msgPayload;
          // console.log(bPass);
          var myMsg =
            '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
            '<div class="card-header" style="background:#0085ff;color:#fff;font-size:13px;padding:10px;border-radius:20px 20px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36"></div><div align="center" style="width:42%;float:right;">Booking Number<br><b style="line-height:22px;float:right;">' +
            bPass.pnr_number +
            "</b></div></div></div>" +
            '<div class="card-content">';
          if (bPass.flight_info) {
            myMsg +=
              '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:22%;"><span style="font-size:13px;color:#999;">Flight</span><br><b>' +
              bPass.flight_info[0].flight_number +
              "</b></p>";
            myMsg +=
              '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Boards</span><br><b>' +
              bPass.flight_info[0].flight_schedule.boarding_time +
              "</b></p>";
            myMsg +=
              '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Arrives</span><br><b>' +
              bPass.flight_info[0].flight_schedule.arrival_time +
              "</b></p>";
            myMsg +=
              '<table width="100%" style="padding:0px 10px;">' +
              '<tr><th align="left"><span style="font-size:13px;color:#999;">' +
              bPass.flight_info[0].departure_airport.city +
              '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;color:#999;">' +
              bPass.flight_info[0].arrival_airport.city +
              "</span></th></tr>" +
              '<tr><td><span style="font-size:40px;color:#0085ff;">' +
              bPass.flight_info[0].departure_airport.airport_code +
              '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;color:#0085ff;">' +
              bPass.flight_info[0].arrival_airport.airport_code +
              "</span></td></tr></table>";
          }
          myMsg +=
            '</div><div class="card-footer" style="background:#fff;width:100%;font-weight:bold;padding-left:40%;"><a href="#" class="link">Check In</a></div>' +
            "</div></div>";
          var result = {
            add: {
              type: "html",
              content: myMsg,
            },
          };
          newMessage(result);
        } else if (msgPayload.template_type == "airline_update") {
          var bPass = msgPayload;
          // console.log(bPass);
          var myMsg =
            '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
            '<div class="card-header" style="font-size:13px;padding:10px;border-radius:20px 20px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36" style="border-radius:50%;"></div><div align="right" style="width:42%;float:right;color:#999;">Flight Status<br><b style="line-height:22px;float:right;color:#e8452b;font-size:16px;text-transform:capitalize;">' +
            bPass.update_type +
            "</b></div></div></div>" +
            '<div class="card-content">';
          if (bPass.update_flight_info) {
            myMsg +=
              '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:22%;"><span style="font-size:13px;color:#999;">Flight</span><br><b>' +
              bPass.update_flight_info.flight_number +
              "</b></p>";
            myMsg +=
              '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Departs</span><br><b style="color:#e8452b;">' +
              bPass.update_flight_info.flight_schedule.departure_time +
              "</b></p>";
            myMsg +=
              '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Arrives</span><br><b style="color:#e8452b;">' +
              bPass.update_flight_info.flight_schedule.arrival_time +
              "</b></p>";
            myMsg +=
              '<table width="100%" style="padding:0px 10px;">' +
              '<tr><th align="left"><span style="font-size:13px;color:#999;">' +
              bPass.update_flight_info.departure_airport.city +
              '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;color:#999;">' +
              bPass.update_flight_info.arrival_airport.city +
              "</span></th></tr>" +
              '<tr><td><span style="font-size:40px;color:#0085ff;">' +
              bPass.update_flight_info.departure_airport.airport_code +
              '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;color:#0085ff;">' +
              bPass.update_flight_info.arrival_airport.airport_code +
              "</span></td></tr></table>";
          }
          myMsg += "</div></div></div>";
          var result = {
            add: {
              type: "html",
              content: myMsg,
            },
          };
          newMessage(result);
        } else if (msgPayload.template_type == "airline_itinerary") {
          var bPass = msgPayload;
          // console.log(bPass);
          localStorage.setItem("itinerary_payload", payload);
          var myMsg =
            '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
            '<div class="card-header" style="background:#0085ff;color:#fff;border-radius:20px 20px 0px 0px;font-size:13px;padding:10px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36"></div><div align="right" style="width:42%;float:right;">Booking Number<br><b style="line-height:22px;">' +
            bPass.pnr_number +
            "</b></div></div></div>" +
            '<div class="card-content"><table width="100%" style="padding:5px 10px;">' +
            '<tr><th align="left"><span style="font-size:13px;color:#999;">Passengers</span></th></tr>';
          // var sfields = bPass[0].secondary_fields;
          jQuery.each(bPass.passenger_info, function (p) {
            myMsg +=
              '<tr><td><b style="font-size:13px;">' +
              bPass.passenger_info[p].name +
              "</b></td></tr>";
          });
          myMsg += "</table>";
          jQuery.each(bPass.flight_info, function (f) {
            myMsg +=
              '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:90%;"><span style="font-size:13px;color:#999;">' +
              bPass.flight_info.length +
              " Stops</span><br><b>" +
              bPass.flight_info[f].flight_schedule.departure_time +
              "</b></p>";
            myMsg +=
              '<table width="100%" style="padding:0px 10px;">' +
              '<tr><th align="left"><span style="font-size:13px;">' +
              bPass.flight_info[f].departure_airport.city +
              '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;">' +
              bPass.flight_info[f].arrival_airport.city +
              "</span></th></tr>" +
              '<tr><td><span style="font-size:40px;">' +
              bPass.flight_info[f].departure_airport.airport_code +
              '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;">' +
              bPass.flight_info[f].arrival_airport.airport_code +
              "</span></td></tr>" +
              "</table>";
          });
          myMsg +=
            '<hr style="margin:0px 10px;"><p style="margin:10px;width:92%;"><span style="font-size:13px;color:#999;">Total</span><b style="float:right;">' +
            bPass.currency +
            " " +
            bPass.total_price +
            "</b></p>";
          myMsg +=
            '</div><div class="card-footer" style="background:#fff;width:100%;"><a href="#" onclick="viewItinerary()" class="link">View Details</a></div>' +
            "</div></div>";
          var result = {
            add: {
              type: "html",
              content: myMsg,
            },
          };
          newMessage(result);
        } else {
          // console.log(msgPayload);
          if(msgPayload.data) {
            var brand_id = msgPayload.data.brandID;
            if(msgPayload.data.objectId) {
              localStorage.setItem("objectId", msgPayload.data.objectId);
            }
            mainView.router.navigate({
              name: `BrandPosts`,
              path: `/brand_posts/:brandid`,
              params: { brandid: brand_id},
            });
          } else {
            var textmessage = {
              add: {
                content: msgPayload.text,
              },
            };
            newMessage(textmessage);
          }
        }
      }
    }
  
    function replaceDynamicValues(content) {
      let probebalContents = {
        "#LEAD_USER_FIRST_NAME#": localStorage.getItem(`${brandID}-name`),
        "{{user_first_name}}": localStorage.getItem(`${brandID}-name`),
      };
      return new Promise((resolve, reject) => {
        Object.keys(probebalContents).map((key, value) => {
          resolve(
            (content = content.includes(key)
              ? content.replace(key, probebalContents[key])
              : content)
          );
          console.log(content);
        });
      });
    }
  
    function saveValue(save, value) {
      localStorage.setItem("userdata-" + brandID + "-" + save, value);
      alength = myArray.length;
  
      var njs = { key: save, value: value };
      ga_record(save, value);
      myArray.push(njs);
      localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
    }
  
    function savePersistData(save, value) {
      localStorage.setItem(`${brandID}-${save}`, value);
    }
  
    function addAction(response, fdata) {
      if (response.action.data) {
        var obj = response.action.data;
        Object.keys(obj).forEach(function (key) {
          localStorage.setItem(key, obj[key]);
        });
      }
  
      if (response.action.text) {
        var placeholder = "";
        if (response.action.text.action.placeholder) {
          placeholder = response.action.text.action.placeholder;
        } else {
          placeholder = "enter response...";
        }
        var responseValue = response.action.text.action.value ? response.action.text.action.value : '';
  
        homeBot.action
          .text({
            action: {
              placeholder: placeholder,
              sub_type: response.action.text.action.sub_type,
              value: responseValue
            },
          })
          .then(async function (res) {
            if (response.action.text.action.regex) {
              const str = response.action.text.action.regex;

              const pattern = new RegExp(str);
              let rest = pattern.test(res.value);

              if (!rest) {
                const finalmsg = {
                    add: {
                    content: response.action.text.action.failure,
                    },
                };
                newMessage(finalmsg);
                addAction(response);
                return;    
              }
            }
            if (response.action.text.action.prepend) {
              let rv = res.value;
              res.value = response.action.text.action.prepend + rv;
            } else if (response.action.text.action.append) {
              let rv = res.value;
              res.value = rv + response.action.text.action.append;
            }

            if (await (response.save === "email")) {
              if (await !isValidEmail(res.value)) {
                return addAction(response, fdata);
              }
            }

            // Sangeet Sir's Code at 02-09-2021 
            // if(await (response.action.text.action.regex)) {
            //   var regexp = new RegExp(response.action.text.action.regex);
            //   var regresult = regexp.test(response.save);
            //   console.log("regex result "+regresult);
            //   if(regresult==true){
                
            //   } else {
            //     response.action.text.action.nextpayload = response.action.text.action.regfail;
            //   }
            // }

            var objId = getUrlParameter("t");
            if((response.save === "name") && objId) {
              localStorage.setItem("name", res.value);
              var bid = localStorage.getItem("brandid");
              var mobile = localStorage.getItem("vmn");
              var fname = res.value;
              jQuery.ajax({
                url: "https://chatappapi.server.scvpl.in/save/bot/user",
                type: "POST",
                data: { fname: fname, mobile: mobile, building:"", finaladdress:"", brandid: bid },
                success: function(data) {
                  // console.log(data);
                }
              });
              saveUserAndBrand();
            }
  
            if (response.save) {
              localStorage.setItem(
                "userdata-" + brandID + "-" + response.save,
                res.value
              );
              alength = myArray.length;
  
              var njs = { key: response.save, value: res.value };
              ga_record(response.save, res.value);
              myArray.push(njs);
              localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              if (
                response.save == "name" ||
                response.save == "vmn" ||
                response.save == "building" ||
                response.save == "finaladdress"
              ) {
                localStorage.setItem(brandID + "-" + response.save, res.value);
              }
            } else if (response.apiai == "true") {
              var dataString = {
                lang: "en",
                query: res.value,
                sessionId: "12345",
                timezone: "America/New_York",
              };
              jQuery.ajax({
                type: "POST",
                url: "https://api.dialogflow.com/v1/query?v=20150910",
                dataType: "json",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + apiaiid,
                },
                data: JSON.stringify(dataString),
                success: (success) => {
                  if (success.result.action == "menu") {
                    offline(success.result.action, "");
                  } else {
                    var messages = success.result.fulfillment.messages;
                    if (success.result.fulfillment.messages[0].speech) {
                      var finalmsg = {
                        add: {
                          content: success.result.fulfillment.messages[0].speech,
                        },
                      };
                      newMessage(finalmsg);
                    } else if (
                      success.result.fulfillment.messages[0].textToSpeech
                    ) {
                      var finalmsg = {
                        add: {
                          content:
                            success.result.fulfillment.messages[0].textToSpeech,
                        },
                      };
                      newMessage(finalmsg);
                    }
                  }
                },
                error: (error) => {},
              });
            }
  
            if (response.persist) {
              localStorage.setItem(brandID + "-" + response.save, res.value);
            }

            if (response.next) {
              if(response.save == 'question_URL') {
                if(is_url(res.value)) {
                  setFlowData(response.current, res.value);
                  nextQuestion(response.nextvalue);
                } else {
                  homeBot.message
                  .add({
                    delay: 1000,
                    loading: true,
                    content: `Wrong url! Please enter a valid URL.`,
                  })
                  .then(function (index) {
                    question_URL(response.current);
                  });
                }
              } else {
                setFlowData(response.current, res.value);
                nextQuestion(response.nextvalue);
              }
            }
            if (response.formBuilder) {
              var bcode = localStorage.getItem('brand_code');
              var ansFormBuilder = localStorage.getItem(bcode+'_form_builder_answer');
              // console.log(ansFormBuilder);
              var obj = response.save+'='+res.value+'&';
              // console.log(obj);
              var new_object = ansFormBuilder+""+obj;
              // console.log(new_object);
              localStorage.setItem(bcode+'_form_builder_answer', new_object);
              nextFormBuilder(response.nextvalue);
            }


            if (!response.action.text.action.validateEntry &&
              parseInt(res.value) >= parseInt(response.action.text.action.min) &&
              parseInt(res.value) <= parseInt(response.action.text.action.max)
            ) {
              if (response.action.text.action.nextpayload == "attribute") {
                var finalmsg = {
                  add: {
                    content: "Please select from the following: ",
                  },
                };
                newMessage(finalmsg);
                var attribute = fdata.attribute.size;
                var price = fdata.attribute.price;
                price = price.toString().split(",");
 
                attribute = attribute.toString().split(",");
                var options = [];
                var i = 0;
 
                attribute.forEach((element) => {
                  options[i] = { text: element, value: element + "+" + price[i] };
                  i++;
                });
                var value = {
                  action: {
                    select: {
                      action: {
                        button: {
                          icon: "check",
                          label: "ok",
                        },
                        nextpayload: "addtocart",
                        label: "text",
                        multipleselect: false,
                        options: options,
                        placeholder: "select your option",
                        searchselect: false,
                        value: "Click arrow to select",
                      },
                    },
                  },
                  save: response.action.text.action.sku + "_attribute",
                };
                addAction(value);
              } else if (response.action.text.action.nextpayload) {
                var value = {
                  action: {
                    button: {
                      action: [
                        {
                          icon: "check",
                          text: "Checkout",
                          value: "checkout",
                        },
                        {
                          icon: "check",
                          text: "Add More",
                          value: "products",
                        },
                      ],
                    },
                  },
                  save: "addtocart",
                };
                addAction(value);
              } else if ((response.action.select.action.nextpayload, res.value)) {
                offline(response.action.select.action.nextpayload, res.value);
              } else {
                // send(res.value);
                offline(res.value);
              }
            } else if (!response.action.text.action.validateEntry &&
              response.action.text.action.min &&
              response.action.text.action.max
            ) {
              var finalmsg = {
                add: {
                  content:
                    "Please select a min value of " +
                    response.action.text.action.min +
                    " and a max of " +
                    response.action.text.action.max,
                },
              };
              newMessage(finalmsg);
 
              // var value = {
              //   "action" : {
              //     "text" : {
              //       "action" : {
              //         "nextpayload" : response.action.text.action.nextpayload,
              //         "placeholder" : "Select the quantity",
              //         "sub_type": "number",
              //         "min": response.action.text.action.min,
              //         "max": response.action.text.action.max
              //       }
              //      }
              //   },
              //    "save": response.action.text.action.save
              // };
 
              var minimum = parseInt(response.action.text.action.min);
              var maximum = parseInt(response.action.text.action.max);
              var noptions = [];
              var j = 0;
              var value;
              for (i = minimum; i <= maximum; i++) {
                if (response.action.text.action.attribute) {
                  noptions[j] = {
                    text: i,
                    value:
                      "attribute_" +
                      i +
                      "_" +
                      JSON.stringify(response.action.text.action.attribute),
                  };
                } else {
                  noptions[j] = { text: i, value: "attribute_" + i };
                }
 
                //shamli
                j++;
                if (i == maximum) {
                  value = {
                    action: {
                      select: {
                        action: {
                          button: {
                            icon: "plus",
                            label: "ok",
                          },
                          label: "number",
                          multipleselect: false,
                          options: noptions,
                          placeholder: "select quantity",
                          searchselect: false,
                          value: "",
                        },
                      },
                    },
                    save: response.action.text.action.save,
                  };
                  addAction(value);
                }
              }
              // addAction(value);
            } else if (response.action.text.action.validateEntry) {
              let validation = true;
              const {validationCriteria, nextpayload, sub_type, min, max, selfPayload, errorMessage} = response.action.text.action;
              if(!isNaN(parseFloat(res.value))){
                if (min && parseFloat(res.value) < parseFloat(min)) {
                  validation = false;
                  console.log(`Validation failed 1st`);                  
                }
                if(max && parseFloat(res.value) > parseFloat(max)) {
                  validation = false;
                  console.log(`Validation failed 2nd`);                  
                }
              } else{
                validation = false;
              }
              if(validation) {
                offline(nextpayload);
              } else {
                const errMessage = [{
                  add: {
                    content: errorMessage
                  }
                }];
                addmessage(errMessage);
                offline(selfPayload);
                /*homeBot.message
                .add({
                  delay: 1000,
                  loading: true,
                  content: errorMessage,
                })
                .then(() => {
                  offline(selfPayload);
                });*/
                // offline(selfPayload);
              }
              /*console.log(validationCriteria);
              if (validationCriteria.multiple) {
                const { criteria } = validationCriteria;
                console.assert((parseInt(res.value) [criteria[0].split(" ")[0]] +(criteria[0].split(" ")[1])) ? '1' : '0');
                if (parseInt(res.value) [criteria[0].split(" ")[0]] +criteria[0].split(" ")[1]) {
                  console.log('inside first arguments if');
                } else{
                  console.log('inside first arguments else');
                }
                console.log(typeof +criteria[1].split(" ")[1]);
                console.log(criteria[1].split(" ")[1]);
                console.log((parseInt(res.value) [criteria[1].split(" ")[0]] +(criteria[1].split(" ")[1])) ? '1' : '0');
                if (parseInt(res.value) [criteria[1].split(" ")[0]] +criteria[1].split(" ")[1]) {
                  console.log('inside second arguments if');
                } else{
                  console.log('inside second arguments else');
                }
               
                console.log(`1: '${res.value} ${criteria[0]}' ${(res.value + criteria[0]) ? '1' : '0'}`);
                console.log(`2: '${res.value} ${criteria[1]}' ${(res.value + criteria[1]) ? '1' : '0'}`);
                let temp = criteria.map(crt => `${res.value} ${crt}` ? 1 : 0);
                console.log(temp);
                console.log(typeof temp);
                console.log(temp.includes(0));
              }
              if(response.action.text.action.validationModule) {                            
              } else {
                validateDiscount(response, res.value);
              }*/
            } else if (response.action.text.action.nextpayload) {
              offline(response.action.text.action.nextpayload, res.value);
            }


            
  
            // if (
            //   parseInt(res.value) >= parseInt(response.action.text.action.min) &&
            //   parseInt(res.value) <= parseInt(response.action.text.action.max)
            // ) {
            //   if (response.action.text.action.nextpayload == "attribute") {
            //     var finalmsg = {
            //       add: {
            //         content: "Please select from the following: ",
            //       },
            //     };
            //     newMessage(finalmsg);
            //     var attribute = fdata.attribute.size;
            //     var price = fdata.attribute.price;
            //     price = price.toString().split(",");
  
            //     attribute = attribute.toString().split(",");
            //     var options = [];
            //     var i = 0;
  
            //     attribute.forEach((element) => {
            //       options[i] = { text: element, value: element + "+" + price[i] };
            //       i++;
            //     });
            //     var value = {
            //       action: {
            //         select: {
            //           action: {
            //             button: {
            //               icon: "check",
            //               label: "ok",
            //             },
            //             nextpayload: "addtocart",
            //             label: "text",
            //             multipleselect: false,
            //             options: options,
            //             placeholder: "select your option",
            //             searchselect: false,
            //             value: "Click arrow to select",
            //           },
            //         },
            //       },
            //       save: response.action.text.action.sku + "_attribute",
            //     };
            //     addAction(value);
            //   } else if (response.action.text.action.nextpayload) {
            //     var value = {
            //       action: {
            //         button: {
            //           action: [
            //             {
            //               icon: "check",
            //               text: "Checkout",
            //               value: "checkout",
            //             },
            //             {
            //               icon: "check",
            //               text: "Add More",
            //               value: "products",
            //             },
            //           ],
            //         },
            //       },
            //       save: "addtocart",
            //     };
            //     addAction(value);
            //   } else if ((response.action.select.action.nextpayload, res.value)) {
            //     offline(response.action.select.action.nextpayload, res.value);
            //   } else {
            //     // send(res.value);
            //     offline(res.value);
            //   }
            // } else if (
            //   response.action.text.action.min &&
            //   response.action.text.action.max
            // ) {
            //   var finalmsg = {
            //     add: {
            //       content:
            //         "Please select a min value of " +
            //         response.action.text.action.min +
            //         " and a max of " +
            //         response.action.text.action.max,
            //     },
            //   };
            //   newMessage(finalmsg);
  
            //   // var value = {
            //   //   "action" : {
            //   //     "text" : {
            //   //       "action" : {
            //   //         "nextpayload" : response.action.text.action.nextpayload,
            //   //         "placeholder" : "Select the quantity",
            //   //         "sub_type": "number",
            //   //         "min": response.action.text.action.min,
            //   //         "max": response.action.text.action.max
            //   //       }
            //   //      }
            //   //   },
            //   //    "save": response.action.text.action.save
            //   // };
  
            //   var minimum = parseInt(response.action.text.action.min);
            //   var maximum = parseInt(response.action.text.action.max);
            //   var noptions = [];
            //   var j = 0;
            //   var value;
            //   for (i = minimum; i <= maximum; i++) {
            //     if (response.action.text.action.attribute) {
            //       noptions[j] = {
            //         text: i,
            //         value:
            //           "attribute_" +
            //           i +
            //           "_" +
            //           JSON.stringify(response.action.text.action.attribute),
            //       };
            //     } else {
            //       noptions[j] = { text: i, value: "attribute_" + i };
            //     }
  
            //     //shamli
            //     j++;
            //     if (i == maximum) {
            //       value = {
            //         action: {
            //           select: {
            //             action: {
            //               button: {
            //                 icon: "plus",
            //                 label: "ok",
            //               },
            //               label: "number",
            //               multipleselect: false,
            //               options: noptions,
            //               placeholder: "select quantity",
            //               searchselect: false,
            //               value: "",
            //             },
            //           },
            //         },
            //         save: response.action.text.action.save,
            //       };
            //       addAction(value);
            //     }
            //   }
            //   // addAction(value);
            // } else if (response.action.text.action.nextpayload) {
            //   offline(response.action.text.action.nextpayload, res.value);
            // }
          });
      } else if (response.action.button) {
        homeBot.action
          .button({
            action: response.action.button.action,
          })
          .then(function (res) {
            // will be called when a button is clicked.
            // socket.emit('fromClient', { client : res.value });
            // console.log(res);
            var nv = res.value;
            var nv1 = nv.split("_");
  
            if (response.save) {
              alength = myArray.length;
              if (nv1[1]) {
                var njs = { key: response.save, value: nv1[1] };
                localStorage.setItem(
                  "userdata-" + brandID + "-" + response.save,
                  nv1[1]
                );
                ga_record(response.save, nv1[1]);
                myArray.push(njs);
                localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              } else {
                var njs = { key: response.save, value: res.value };
                localStorage.setItem(
                  "userdata-" + brandID + "-" + response.save,
                  res.value
                );
                ga_record(response.save, res.value);
                myArray.push(njs);
                localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              }
              res.points && addpoints(res.points);
            }
  
            if (response.persist) {
              localStorage.setItem(brandID + "-" + response.save, res.value);
            }
  
            if (response.action.data) {
              // localStorage.setItem('userdata-'+brandID+'-'+ response.save, res.value);
            }
  
            if (response.action.button.action) {
              // console.log(res.value);
              if((res.value).indexOf("messenger_bot_connectivity/webview") >= 0) {
                var canonId = /[^/]*$/.exec(res.value)[0];
                var canonical_id = canonId.split("?");
                var canon_id = canonical_id[0];
                // console.log(canon_id);
                var fb_bot = localStorage.getItem("fb_bot");
                const brandQuery = new Parse.Query("brandData");
                if(fb_bot && fb_bot == "true") {
                  brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
                } else {
                  brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
                }
                brandQuery
                .find()
                .then(function (results) {
                  if(results.length > 0){  
                    jQuery.each(results, async function (key, value) {
                      var jsonRes = JSON.parse(JSON.stringify(value));
                      var apikey = jsonRes.api_key;
                      var bCode = jsonRes.brand_code;
                      var mobile = localStorage.getItem('vmn');
                      var phone = mobile.split('+');
                      jQuery.ajax({
                        type: "GET",
                        url: "https://messengerf.com/apiv2/get_single_form_builder_info/?api_key="+apikey+"&page_id="+bCode+"&canonical_id="+canon_id,
                        success: (result) => {
                          // console.log(JSON.parse(result[0].form_data));
                          var bcode = localStorage.getItem('brand_code');
                          localStorage.setItem(bcode+'_form_builder', result[0].form_data);
                          var next = "0";
                          nextFormBuilder(next);
                          var obj = 'subscriber_id='+phone[1]+'&webview_form_id='+canon_id+'&';
                          // console.log(obj);
                          localStorage.setItem(bcode+'_form_builder_answer', obj);
                        }
                      });
                    });
                  }
                });
              } else if (res.value == "submit_form_builder") {
                var bcode = localStorage.getItem('brand_code');
                var ansFormBuilder = localStorage.getItem(bcode+'_form_builder_answer');
                // console.log(ansFormBuilder);
                var mobile = localStorage.getItem('vmn');
                var phone = mobile.split('+');
                jQuery.ajax({
                  type: "GET",
                  url: "https://messengerf.com/apiv2/set_form_bulder_subscriber_data/?"+ansFormBuilder,
                  success: (result) => {
                    // console.log(result);
                    var ogmessage = {
                      add: {
                        content: "Data submitted successfully.",
                      },
                    };
                    newMessage(ogmessage);
                  }
                });
              } else if (res.value == "skipquestion") {
                nextQuestion(res.next);
              } else if (res.value == "myorders") {
                var code = jQuery(".botui-app-container").data("code");
                // console.log("code : ", code);
                query.get(code).then((data) => {
                  var ordjson = JSON.parse(JSON.stringify(data));
                  var bid = ordjson.brand_id;
                  // console.log("Platform: ", app.device.platform);
                  // if(app.device.platform == "ios") {
                    mainView.router.navigate({ name: "orders", path: "/orders/:brandid", params: { brandid: bid } });
                  // } else {
                  //   console.log("not in iOS");
                  //   mainView.router.navigate({ name: "orders", params: { brandid: bid } });
                  // }
                  // mainView.router.navigate({ name: "orders", params: { brandid: bid } }, {
                  //   reloadCurrent: true,
                  //   ignoreCache: true,
                  // });
                });
              } else if (res.value == "checkout") {
                var jurl = jQuery(".botui-app-container").data("url");
                var nurl = "cartapp/?q=" + brandID + "&url=" + jurl;
                newmsg = {
                  add: {
                    type: "embed",
                    content: nurl,
                  },
                };
                newMessage(newmsg);
              } else if (res.value == "payonline") {
                payonline();
              } else if (res.value == "sendOrderWhatsapp") {
                sendOrderWhatsapp();
              } else if (res.value == "sendReservationWhatsapp") {
                sendReservationWhatsapp();
              } else if (res.value == `refresh_page`) {
                localStorage.clear();
                window.location.reload();
                logout();
              } else if (res.value == "share") {
                // console.log(response.action.button.action[0]);
                // console.log(res.share_title);
                // offline( res.nextpayload, res.value );
                if (navigator.share) {
                  // navigator.share({
                  //   title: response.action.button.action[0]["share_title"],
                  //   text: response.action.button.action[0]["share_text"],
                  //   url: response.action.button.action[0]["share_link"]
                  // });
                  navigator
                    .share({
                      title: res.share_title,
                      text: res.share_text,
                      url: res.share_link,
                    })
                    .then(() => {
                      var ogmessage = [{
                        add: {
                          content: "Thank you for sharing 👍",
                        },
                      },
                      {
                        action: {
                          button: {
                            action: [
                              {
                                text: "☰ Explore",
                                value: "explore",
                              },
                            ],
                          },
                        },
                        delay: 1000,
                      }];
                      newMessage(ogmessage);
                    })
                    .catch((err) => {
                      console.error("Share failed:", err.message);
                      var ogmessage = [{
                        action: {
                          button: {
                            action: [
                              {
                                text: "☰ Explore",
                                value: "explore",
                              },
                            ],
                          },
                        },
                        delay: 1000,
                      }];
                      newMessage(ogmessage);
                    });
                } else {
                  // share message further..
                  var ogmessage = {
                      add: {
                        type: "html",
                        content: `Please click on the following links to Share: <a class="link external" target="blank" href="https://facebook.com/sharer/sharer.php?u=` +
                        encodeURI( res.share_link ) +
                        `"><i class="fa fa-facebook-square" style="font-size:34px;margin-left:5px;"></i></a>&nbsp;<a class="link external" target="blank" href="https://twitter.com/intent/tweet/?text=` +
                        encodeURI( res.share_title ) + ";url=" + encodeURI( res.share_link ) +
                        `"><i class="fa fa-twitter-square" style="font-size:34px;margin-left:10px;"></i></a>&nbsp;<a class="link external" target="blank" href="https://www.linkedin.com/sharing/share-offsite/?url=` +
                        encodeURI( res.share_link ) +
                        `"><i class="fa fa-linkedin-square" style="font-size:34px;margin-left:10px;"></i></a>&nbsp;<a class="link external" target="blank" href="https://wa.me/?text=` +
                        encodeURI( res.share_title + " " + res.share_link ) +
                        `"><i class="fa fa-whatsapp" style="font-size:34px;margin-left:10px;"></i></a>`,
                      },
                    };
                  // var ogmessage = {
                  //   add: {
                  //     content:
                  //       "Please click on the following links to Share: !(facebook-square)[Facebook](https://facebook.com/sharer/sharer.php?u=" +
                  //       encodeURI(
                  //         res.share_link
                  //       ) +
                  //       ")^   !(twitter-square)[Twitter](https://twitter.com/intent/tweet/?text=" +
                  //       encodeURI(
                  //         res.share_title
                  //       ) +
                  //       ";url=" +
                  //       encodeURI(
                  //         res.share_link
                  //       ) +
                  //       ")^   !(linkedin-square)[Linkedin](https://www.linkedin.com/sharing/share-offsite/?url=" +
                  //       encodeURI(
                  //         res.share_link
                  //       ) +
                  //       ")^    !(whatsapp)[Whatsapp](https://wa.me/?text=" +
                  //       encodeURI(
                  //         res.share_title +
                  //           " " +
                  //           res.share_link
                  //       ) +
                  //       ";)^",
                  //   },
                  // };
                  newMessage(ogmessage);
                  setTimeout(function () {
                    offline(
                      res.nextpayload,
                      res.nextpayload
                    );
                  }, 500);
                }
              } else if (res.value == "sharephoto") {
                // console.log("Share Photo");
                var result = {
                  add: {
                    type: "html",
                    content:
                      '<input type="file" id="image_file" accept="image/*" capture="camera" onchange="onSuccess(this)">',
                  },
                };
                newMessage(result);
                // navigator.camera.getPicture(onSuccess, onFail, {
                //   quality: 50,
                //   sourceType: Camera.PictureSourceType.CAMERA,
                //   allowEdit: true,
                //   destinationType: Camera.DestinationType.FILE_URI,
                // });
              } else if (res.value == "takephoto") {
                // console.log("Take Photo");
                navigator.camera.getPicture(onSuccess, onFail, {
                  quality: 20,
                  destinationType: Camera.DestinationType.FILE_URI,
                });
              } else if (response.medium === "fb" && response.pageid) {
                if (is_url(res.value)) {
                  // console.log(`URL IS: `, res.value);
                  if (res.value.includes("https://wa.me/?text=")) {
                    navigator
                      .share({
                        title: res.value
                          .split("https://wa.me/?text=")[1]
                          .includes("https://")
                          ? res.value
                              .split("https://wa.me/?text=")[1]
                              .split("https://")[0]
                          : res.value.split("https://wa.me/?text=")[1],
                        text: res.value
                          .split("https://wa.me/?text=")[1]
                          .includes("https://")
                          ? res.value
                              .split("https://wa.me/?text=")[1]
                              .split("https://")[0]
                          : res.value.split("https://wa.me/?text=")[1],
                        url: res.value
                          .split("https://wa.me/?text=")[1]
                          .includes("https://")
                          ? `https://${
                              res.value
                                .split("https://wa.me/?text=")[1]
                                .split("https://")[1]
                            }`
                          : res.value.split("https://wa.me/?text=")[1],
                      })
                      .then(() => {
                        var ogmessage = {
                          add: {
                            content: "Data shared successfully.",
                          },
                        };
                        newMessage(ogmessage);
                      })
                      .catch((err) => {
                        console.error("Share failed:", err.message);
                      });
                    return;
                  }
                  let target = "blank";
                  let options = "location=yes";
                  var vmn = localStorage.getItem("vmn");
                  if (vmn) {
                    var pageId = jQuery(".botui-app-container").data("pageid");
                    // console.log(pageId)
                    var page_id = (pageId != undefined && pageId != 'undefined')
                      ? pageId
                      : localStorage.getItem("brand_code");
                    var fullname = localStorage.getItem(brandID + "-name")
                      ? localStorage.getItem(brandID + "-name")
                      : '""';
                    var email_id = localStorage.getItem(brandID + "-email")
                      ? localStorage.getItem(brandID + "-email")
                      : '""';
                    var name = fullname.split(" ");
                    var fname = name[0] ? name[0] : '""';
                    var lname = name[1] ? name[1] : '""';
                    // console.log(page_id + " - " + fullname + " - " + fname);
                    if (localStorage.getItem("set_subsriber") == "success") {
                      var resVal = res.value.replace(
                        "#SUBSCRIBER_ID_REPLACE#",
                        vmn
                      );
                      storeUrl = window.open(resVal);
                      addAction(response);
                    } else {
                      var mUrl =
                        "https://messengerf.com/Api/set_subsriber?page_id=" +
                        page_id +
                        "&subscribe_id=" +
                        vmn +
                        "&first_name=" +
                        fname +
                        "&last_name=" +
                        lname +
                        "&email=" +
                        email_id;
                      // console.log(mUrl);
                      jQuery.get(mUrl, function (data, status) {
                        // console.log("Data: " + data + "\nStatus: " + status);
                        if (status == "success") {
                          localStorage.setItem("set_subsriber", "success");
                          var resVal = res.value.replace(
                            "#SUBSCRIBER_ID_REPLACE#",
                            vmn
                          );
                          storeUrl = window.open(resVal);
                          addAction(response);
                        }
                      });
                    }
                  } else {
                    offline("get_started", "");
                  }
                  return;
                }
                processfb(res.value, response.pageid, true);
              } else if (is_url(res.value)) {
                let target = "blank";
                let options = "location=yes";
                var vmn = localStorage.getItem("vmn");
                var resVal = res.value.replace("#SUBSCRIBER_ID_REPLACE#", vmn);
                window.open(resVal);
                addAction(response);
              } else if (res.value === "discover") {                
                mainView.router.navigate({
                  name: "discover",
                  path: "/discover/",
                });
                addAction(response);
              } else if (res.value === "brandPosts") {
                mainView.router.navigate({
                  name: `BrandPosts`,
                  path: `/brand_posts/:brandid`,
                  params: { brandid: brandID},
                });
                addAction(response);
              } else if (res.value === "mychatapps") {
                mainView.router.navigate({
                  name: res.value,
                  path: "/"+res.value+"/",
                });
                addAction(response);
              } else if (res.value === "downloadVisitingCard") {
                mainView.router.navigate({
                  name: "profile",
                  path: "/profile/",
                });
                addAction(response);
              } else if (res.value === "get_started") {
                logout();
                offline("get_started");
              } else if (res.value === `openFBPage`) {  //coded date 07/05/2021
                //FOR OPENING FB PAGE, AFTER USER SUCCESSFULLY AUTHETICATED WITH OUR SYSTEM
                const pageType = jQuery(".botui-app-container").data("pagetype");
                const fbPageID = jQuery(".botui-app-container").data("pageid");
                brandID = jQuery(".botui-app-container").data("brandid");
                if (pageType === "fb" && fbPageID) {
                  openPop(fbPageID, "fb", undefined, brandID);
                }
              } else if(res.route){
                mainView.router.navigate({
                  name: res.pageName,
                  path: res.path,
                  params: { brandid: res.params[0], selection: res.params[1]},
                });
                addAction(response);
              } else {
                if (nv1[1]) {
                  offline(nv1[0], res.value);
                } else {
                  offline(res.value, res.value);
                }
              }
            } else {
              send(res.value);
            }
          });
      }
      /*else if (response.action.select) {
        //FOR CHANGE ADDRESS
        let locs;
        if (
          response.action.select.action.optionsvariable &&
          response.action.select.action.optionsbuilding
        ) {
          locs = JSON.parse(
            localStorage.getItem(
              brandID + "_" + response.action.select.action.optionsvariable
            )
          ).map((location) => {
            return {
              text: location.text,
              value: location.value.replace(
                `building`,
                response.action.select.action.optionsbuilding
              ),
            };
          });
        }
  
        homeBot.action
          .select({
            action: {
              placeholder: response.action.select.action.placeholder,
              value: response.action.select.action.value, // Selected value or selected object. Example: {value: "TR", text : "Türkçe" }
              searchselect: response.action.select.action.searchselect, // Default: true, false for standart dropdown
              label: response.action.select.action.label, // dropdown label variable
              multipleselect: response.action.select.action.multipleselect,
              options: response.action.select.action.options
                ? response.action.select.action.options
                : response.action.select.action.optionsbuilding
                ? locs
                : JSON.parse(
                    localStorage.getItem(
                      brandID +
                        "_" +
                        response.action.select.action.optionsvariable
                    )
                  ),
              button: {
                icon: response.action.select.action.button.icon,
                label: response.action.select.action.button.label,
              },
            },
          })
          .then(function (res) {
            // will be called when a button is clicked.
            // socket.emit('fromClient', { client : res.value });
  
            if (response.save) {
              var valsel = res.value;
              // console.log(valsel);
              valsel = valsel.split("_");
              var valselmultiple = res.value;
              valselmultiple = valselmultiple.split(",");
              if (response.save == "building") {
                localStorage.setItem(brandID + "-" + response.save, valsel[1]);
              }
              if (valsel[1]) {
                //saving split value
                if (valselmultiple[1]) {
                  valsel[1] = valselmultiple;
                }
  
                ga_record(response.save, valsel[1]);
                alength = myArray.length;
                var njs = "";
                if (response.save == "building") {
                  njs = { key: response.save, value: valsel[1] };
                } else {
                  njs = { key: response.save, value: [valsel[1]] };
                }
                myArray.push(njs);
  
                localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              } else {
                //saving original value
                localStorage.setItem(
                  "userdata-" + brandID + "-" + response.save,
                  res.value
                );
                ga_record(response.save, res.value);
                alength = myArray.length;
                var njs = "";
                if (response.save == "building") {
                  njs = { key: response.save, value: res.value };
                } else if (response.action.select.action.multipleselect) {
                  njs = { key: response.save, value: [res.value] };
                } else {
                  njs = { key: response.save, value: res.value };
                }
                myArray.push(njs);
  
                localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              }
              // handle select next payload
              if (valsel[1]) {
                var cdata = valsel[1];
                // offline(valsel[0]);
  
                if (valsel[0] == "attribute") {
                  var finalmsg = {
                    add: {
                      content: "Please select from the following: ",
                    },
                  };
                  newMessage(finalmsg);
  
                  var attri = valsel[2];
                  var attvalue = JSON.parse(attri);
                  var size = attvalue.size;
                  var price = attvalue.price;
                  price = price.toString().split(",");
  
                  var attribute = size.toString().split(",");
                  var options = [];
                  var i = 0;
  
                  attribute.forEach((element) => {
                    options[i] = {
                      text: element,
                      value: element + "+" + price[i],
                    };
                    i++;
                  });
  
                  var value = {
                    action: {
                      select: {
                        action: {
                          button: {
                            icon: "check",
                            label: "ok",
                          },
                          nextpayload: "addtocart",
                          label: "text",
                          multipleselect: false,
                          options: options,
                          placeholder: "select your option",
                          searchselect: false,
                          value: "Click arrow to select",
                        },
                      },
                    },
                    save: cdata.sku + "_attribute",
                  };
                  addAction(value);
                }
              } else {
                var valsel2 = res.value;
                valsel2 = valsel2.split("+");
                if (valsel2[1]) {
                  var value = {
                    action: {
                      button: {
                        action: [
                          {
                            icon: "check",
                            text: "Checkout",
                            value: "checkout",
                          },
                          {
                            icon: "check",
                            text: "Add More",
                            value: "products",
                          },
                        ],
                      },
                    },
                    save: "addtocart",
                  };
                  addAction(value);
                } else {
                  offline(res.value);
                }
              }
            }
            if (response.action.select.action.nextpayload) {
              if (response.action.select.action.nextpayload == "addtocart") {
                var value = {
                  action: {
                    button: {
                      action: [
                        {
                          icon: "check",
                          text: "Checkout",
                          value: "checkout",
                        },
                        {
                          icon: "check",
                          text: "Add more",
                          value: "products",
                        },
                      ],
                    },
                  },
                  save: "addtocart",
                };
                addAction(value);
              } else {
                offline(response.action.select.action.nextpayload, res.value);
              }
              // else if(response.action.text.action.nextpayload){
              //   if(response.action.text.action.nextpayload=="addtocart"){
              //     var value = {
              //      "action" : {
              //        "button" : {
              //          "action" : [ {
              //                "icon": "check",
              //                "text" : "Checkout",
              //              "value" : "checkout"
              //            }, {
              //              "icon": "check",
              //              "text" : "Continue Shopping",
              //            "value" : "about"
              //          }] }
              //      },
              //      "save":"addtocart"
              //    };
              //    addAction(value);
              //   }else{
              //     offline(response.action.text.action.nextpayload,res.value);
              //   }
  
              // }
            } else {
              var nvalselect = res.value;
              nvalselect = nvalselect.split("_");
              if (nvalselect[1]) {
                offline(nvalselect[0]);
              } else {
                offline(res.value);
              }
            }
            if (response.persist) {
              localStorage.setItem(brandID + "-" + response.save, res.value);
            }
          });
      }*/
      else if (response.action.select) {
        //FOR CHANGE ADDRESS
        let locs;
        if (
          response.action.select.action.optionsvariable &&
          response.action.select.action.optionsbuilding
        ) {
          locs = JSON.parse(
            localStorage.getItem(
              brandID + "_" + response.action.select.action.optionsvariable
            )
          ).map((location) => {
            return {
              text: location.text,
              value: location.value.replace(
                `building`,
                response.action.select.action.optionsbuilding
              ),
            };
          });
        }
  
        homeBot.action
          .select({
            action: {
              placeholder: response.action.select.action.placeholder,
              value: response.action.select.action.value, // Selected value or selected object. Example: {value: "TR", text : "Türkçe" }
              searchselect: response.action.select.action.searchselect, // Default: true, false for standart dropdown
              label: response.action.select.action.label, // dropdown label variable
              multipleselect: response.action.select.action.multipleselect,
              options: response.action.select.action.options
                ? response.action.select.action.options
                : response.action.select.action.optionsbuilding
                ? locs
                : JSON.parse(
                    localStorage.getItem(
                      brandID +
                        "_" +
                        response.action.select.action.optionsvariable
                    )
                  ),
              button: {
                icon: response.action.select.action.button.icon,
                label: response.action.select.action.button.label,
              },
            },
          })
          .then(function (res) {
            // will be called when a button is clicked.
            // socket.emit('fromClient', { client : res.value });
  
            if (response.save) {
              var valsel = res.value;
              // console.log(valsel);
              valsel = valsel.split("_");
              var valselmultiple = res.value;
              valselmultiple = valselmultiple.split(",");
              if (response.save == "building") {
                localStorage.setItem(brandID + "-" + response.save, valsel[1]);
              }
              if (valsel[1]) {
                //saving split value
                if (valselmultiple[1]) {
                  valsel[1] = valselmultiple;
                }
  
                ga_record(response.save, valsel[1]);
                alength = myArray.length;
                var njs = "";
                if (response.save == "building") {
                  njs = { key: response.save, value: valsel[1] };
                } else {
                  njs = { key: response.save, value: [valsel[1]] };
                }
                myArray.push(njs);
  
                localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              } else {
                //saving original value
                localStorage.setItem(
                  "userdata-" + brandID + "-" + response.save,
                  res.value
                );
                ga_record(response.save, res.value);
                alength = myArray.length;
                var njs = "";
                if (response.save == "building") {
                  njs = { key: response.save, value: res.value };
                } else if (response.action.select.action.multipleselect) {
                  njs = { key: response.save, value: [res.value] };
                } else {
                  njs = { key: response.save, value: res.value };
                }
                myArray.push(njs);
  
                localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
              }
              // handle select next payload
              if (valsel[1]) {
                var cdata = valsel[1];
                // offline(valsel[0]);
  
                if (valsel[0] == "attribute") {
                  var finalmsg = {
                    add: {
                      content: "Please select from the following: ",
                    },
                  };
                  newMessage(finalmsg);
  
                  var attri = valsel[2];
                  var attvalue = JSON.parse(attri);
                  var size = attvalue.size;
                  var price = attvalue.price;
                  price = price.toString().split(",");
  
                  var attribute = size.toString().split(",");
                  var options = [];
                  var i = 0;
  
                  attribute.forEach((element) => {
                    options[i] = {
                      text: element,
                      value: element + "+" + price[i],
                    };
                    i++;
                  });
  
                  var value = {
                    action: {
                      select: {
                        action: {
                          button: {
                            icon: "check",
                            label: "ok",
                          },
                          nextpayload: "addtocart",
                          label: "text",
                          multipleselect: false,
                          options: options,
                          placeholder: "select your option",
                          searchselect: false,
                          value: "Click arrow to select",
                        },
                      },
                    },
                    save: cdata.sku + "_attribute",
                  };
                  addAction(value);
                }
              } else {
                var valsel2 = res.value;
                valsel2 = valsel2.split("+");
                if (valsel2[1]) {
                  var value = {
                    action: {
                      button: {
                        action: [
                          {
                            icon: "check",
                            text: "Checkout",
                            value: "checkout",
                          },
                          {
                            icon: "check",
                            text: "Add More",
                            value: "products",
                          },
                        ],
                      },
                    },
                    save: "addtocart",
                  };
                  addAction(value);
                } else {
                  //offline(res.value);
                }
              }
            }
            if (response.action.select.action.nextpayload) {
              if (response.action.select.action.nextpayload == "addtocart") {
                var value = {
                  action: {
                    button: {
                      action: [
                        {
                          icon: "check",
                          text: "Checkout",
                          value: "checkout",
                        },
                        {
                          icon: "check",
                          text: "Add more",
                          value: "products",
                        },
                      ],
                    },
                  },
                  save: "addtocart",
                };
                addAction(value);
              } else {
                offline(response.action.select.action.nextpayload, res.value);
              }
              // else if(response.action.text.action.nextpayload){
              //   if(response.action.text.action.nextpayload=="addtocart"){
              //     var value = {
              //      "action" : {
              //        "button" : {
              //          "action" : [ {
              //                "icon": "check",
              //                "text" : "Checkout",
              //              "value" : "checkout"
              //            }, {
              //              "icon": "check",
              //              "text" : "Continue Shopping",
              //            "value" : "about"
              //          }] }
              //      },
              //      "save":"addtocart"
              //    };
              //    addAction(value);
              //   }else{
              //     offline(response.action.text.action.nextpayload,res.value);
              //   }
  
              // }
            } else {
              var nvalselect = res.value;
              nvalselect = nvalselect.split("_");
              if (nvalselect[1]) {
                offline(nvalselect[0]);
              } else {
                offline(res.value);
              }
            }
            if (response.persist) {
              localStorage.setItem(brandID + "-" + response.save, res.value);
            }
          });
      } else if (response.action.show) {
      } else if (response.action.hide) {
      }
  
      jQuery("#ending")[0].scrollIntoView(true);
    }
  
    function isValidEmail(value) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }
  
    function addpoints(point) {
      // console.log("point: " + point);
      var totalpoint = parseInt(
        localStorage.getItem("userdata-" + brandID + "-total")
      );
      if (totalpoint) {
        // console.log("totalpoint: " + totalpoint);
        var finalpoint = totalpoint + point;
        localStorage.setItem("userdata-" + brandID + "-total", finalpoint);
      } else {
        // console.log("point: " + point);
        localStorage.setItem("userdata-" + brandID + "-total", point);
      }
    }
  
    //CHECK AND VALIDATE IF STRING IS VALID URL
    function is_url(str) {
      let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return regexp.test(str);
    }
  
    function onSuccess(input) {
      const userID = localStorage.getItem(`vmn`);
      let fd = new FormData();
      fd.append('file', input.files[0]);
      fd.append('brandID', brandID);
      fd.append('userID', userID);

      $.ajax({
        url: 'https://chatappapi.server.scvpl.in/upload/image',
        data: fd,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function(data){
          // console.log(`Data:`, data);
          var imgmessage = [{
            add: {
              content: "Image Uploaded, ![image](" + data.url + ")",
            },
          },{
            action: {
              button: {
                action:[{
                  text: "Explore",
                  value: "explore"
                }]
              }
            }
          }];
          addmessage(imgmessage);
        },
        error: function (error) {
          const errorMessage = {
            add: {
              content: `There is some error in image processing. Pleasa try again.`
            },
          };
          newMessage(errorMessage);
        }
      });
    }
  
    function onFail(message) {
      console.log("Failed because: " + message);
    }
  
    //REPLACED OLD CAROUSEL CODE WITH NEW ONE, PROVIDED BY Suman JEE
    function addcarousel(path) {
      jQuery.getJSON(
        "https://www.socialrecharge.com/chat/thefruitsmith.json",
        function (json) {
          // console.log(json);
          var pathv = json.payloads[path].messages[0];
          // console.log(pathv);
          var element = pathv.carousel;
          if (element) {
            var myMsg =
              '<div class="swiper-container swiper-2" style="overflow:scroll;">' +
              '<div class="swiper-wrapper">';
            jQuery.each(element, function (i) {
              //console.log(element[i].title);
              if (element.length == 1) {
                var headClass = "card-header-first";
                var cardFirst = "";
                var swiperMid = "";
                var swipeNew = "margin-right:-2% !important;";
                var cardOne = "border-radius:25px;width:100%;";
                var headOne = "border-radius:25px 25px 0px 0px !important;";
              } else if (i == 0) {
                var headClass = "card-header-first";
                var cardFirst = "";
                var swiperMid = "";
                var swipeNew = "margin-right:-2% !important;";
                var cardOne = "border-radius: 25px 0px 0px 25px;width:80%;";
                var headOne = "";
              } else if (i == element.length - 1) {
                var headClass = "card-header-last";
                var cardFirst = "card-last";
                var swiperMid = "swiper-slide-mid";
                var swipeNew = "margin-right:-15% !important;";
                var cardOne = "border-radius: 25px 0px 0px 25px;width:80%;";
                var headOne = "";
              } else {
                var headClass = "card-header-mid";
                var cardFirst = "card-first";
                var swiperMid = "swiper-slide-mid";
                var swipeNew = "margin-right:-15% !important;";
                var cardOne = "border-radius: 25px 0px 0px 25px;width:80%;";
                var headOne = "";
              }
              myMsg +=
                '<div class="swiper-slide ' +
                swiperMid +
                '" style="width:100% !important;' +
                swipeNew +
                '">' +
                '<div class="card ' +
                cardFirst +
                ' demo-card-header-pic" style="' +
                cardOne +
                'left:5%;float:left;">' +
                '<div style="background-image:url(' +
                element[i].image_url +
                ");" +
                headOne +
                '" valign="bottom" class="card-header ' +
                headClass +
                ' color-white no-border"></div>' +
                '<div class="card-content" style="min-height:85px;margin:5px;">' +
                '<div class="card-content-inner">' +
                '<p class="color-gray"><b>' +
                element[i].title +
                "</b></p>";
              if (element[i].subtitle) {
                myMsg += "<p>" + element[i].subtitle + "</p>";
              }
              myMsg +=
                "</div>" +
                "</div>" +
                '<div align="center" class="card-footer" style="display:block;">';
              var eleButton = element[i].buttons;
              if (eleButton) {
                //myMsg+= '<a href="#" class="link">&nbsp;</a>';
                jQuery.each(eleButton, function (j) {
                  //console.log(element[i].buttons[j].title);
                  if (element[i].buttons[j].type == "web_url") {
                    var bUrl = element[i].buttons[j].url;
                    if (bUrl.indexOf(" 91") >= 0) {
                      var buttUrl = bUrl.replace(" 91", "+91");
                    } else {
                      var buttUrl = bUrl;
                    }
                    myMsg +=
                      '<div align="center" class="carousel_butt"><a href="#" class="link external" onClick="openUrl(\'' +
                      buttUrl +
                      '\');" style="text-transform:uppercase;">' +
                      element[i].buttons[j].title +
                      "</a></div>";
                  } else if (element[i].buttons[j].type == "phone_number") {
                    myMsg +=
                      '<div align="center" class="carousel_butt"><a href="tel:' +
                      element[i].buttons[j].payload +
                      '" class="link external" style="text-transform:uppercase;display:inline-table;">' +
                      element[i].buttons[j].title +
                      "</a></div>";
                  } else if (element[i].buttons[j].type == "postback") {
                    myMsg +=
                      '<div align="center" class="carousel_butt"><a href="#" class="link" onClick="offline(\'' +
                      element[i].buttons[j].payload +
                      '\');" style="text-transform:uppercase;">' +
                      element[i].buttons[j].title +
                      "</a></div>";
                  } else if (element[i].buttons[j].type == "product") {
                    myMsg +=
                      '<div align="center" class="carousel_butt"><a href="#" class="link" onClick="handleBuyEvent(' +
                      JSON.stringify(element[i].buttons[j]) +
                      ');" style="text-transform:uppercase;">' +
                      element[i].buttons[j].title +
                      "</a></div>";
                  }
                });
              }
              myMsg += "</div>" + "</div></div>";
            });
            myMsg +=
              "</div>" +
              //'<div class="swiper-pagination" style="position:relative;bottom:20px;"></div>'+
              "</div>";
            var result = {
              add: {
                type: "html",
                content: myMsg,
              },
            };
            newMessage(result);
          } else {
            var myMsg = "<div>Elements not Found.!</div>";
            var result = {
              add: {
                type: "html",
                content: myMsg,
              },
            };
            newMessage(result);
          }
        }
      );
    }
  
    function addlist(path) {
      // var pathv = json.payloads[path];
      // console.log("listapp: "+brandID);
      if(app.device.platform == "ios") {
        mainView.router.navigate({ name: "listapp", path: "/listapp/:brandid", params: { brandid: brandID } });
      } else {
        mainView.router.navigate({ name: "listapp", params: { brandid: brandID } });
      }
    }
  
    function addcontest(path) {
      var userid = localStorage.getItem("vmn");
      const URL = `https://parseapi.server.scvpl.in/get/userstatus/spintowin/?mobile=${encodeURIComponent(userid)}&gameid=spintowin&brandID=${brandID}`;
      // const URL = `http://localhost:12345/get/userstatus/spintowin/?mobile=${encodeURIComponent(userid)}&gameid=spintowin&brandID=${brandID}`;

      jQuery.getJSON(
        URL,
        async function (spinres) {
          // console.log(spinres);
          if (spinres.status) {
            const brandQuery = new Parse.Query("brandData");
            brandQuery.equalTo("brand_id", brandID);
            const brandResult = await brandQuery.first();
            if (brandResult) {
              const spinFee = brandResult.get(`spinpoints`);
              const userRef = new Parse.Query(`ChatAppUsers`);
              userRef.equalTo(`mobile`, userid);
              const userResult = await userRef.first();
              if (userResult) {
                const userBrandPointResult = await userResult
                  .relation(`wallets`)
                  .query()
                  .equalTo(`brandID`, brandID)
                  .equalTo(`userID`, userid)
                  .first();
                const brandPoint = (userBrandPointResult && userBrandPointResult.get(`currentBalance`)) ? userBrandPointResult.get(`currentBalance`) : '0';
                if (parseInt(brandPoint) >= parseInt(spinFee)) {
                  mainView.router.navigate({
                    name: "spinandwin",
                    params: { brandid: brandID },
                  });
                } else {
                  var spinmessage = [{
                    add: {
                      content:
                        "Sorry, You haven't sufficient point to play. Please earn some more points.",
                    },
                  },{
                    action: {
                      button: {
                        action:[{
                          text: "Explore",
                          value: "explore"
                        }]
                      }
                    }
                  }];
                  addmessage(spinmessage);
                }
              }
            } else {
              let message = [{
                add: {
                  content: `This functionality is not available.`,
                },
              },{
                action: {
                  button: {
                    action:[{
                      text: "Explore",
                      value: "explore"
                    }]
                  }
                }
              }];
              addmessage(message);
            }
              
            /*.then(function (results) {
              // console.log(results);
              if (results.length > 0) {
                jQuery.each(results, async function (key, value) {
                  var jsonRes = JSON.parse(JSON.stringify(value));
                  const userQuery = new Parse.Query("ChatAppPoints");
                  userQuery.equalTo("mobile", userid);
                  const userPointResult = await userQuery.find();
                  // console.log(userPointResult);
                  if (userPointResult.length > 0) {
                    let brandPoints = userPointResult[0].get("brandPoints");
                    let totalPoints = userPointResult[0].get("totalPoints");
                    let individualBrandPoints =
                      userPointResult[0].get("brandPoints") &&
                      userPointResult[0].get("brandPoints")[brandID] &&
                      userPointResult[0].get("brandPoints")[brandID]["points"];
                    // console.log(individualBrandPoints);
                    if (
                      individualBrandPoints &&
                      individualBrandPoints >= jsonRes.spinpoints &&
                      totalPoints >= jsonRes.spinpoints
                    ) {
                      // if(device.platform == "ios") {
                      //   mainView.router.navigate({ name: "spinandwin", path: "/spinandwin/:brandid", params: { brandid: brandID } });
                      // } else {
                      mainView.router.navigate({
                        name: "spinandwin",
                        params: { brandid: brandID },
                      });
                      // }
                    } else {
                      var spinmessage = [{
                        add: {
                          content:
                            "Sorry, You haven't sufficient point to play. Please earn some more points.",
                        },
                      },{
                        action: {
                          button: {
                            action:[{
                              text: "Explore",
                              value: "explore"
                            }]
                          }
                        }
                      }];
                      addmessage(spinmessage);
                    }
                  }
                });
              } else {
                let message = [{
                  add: {
                    content: `This functionality is not available.`,
                  },
                },{
                  action: {
                    button: {
                      action:[{
                        text: "Explore",
                        value: "explore"
                      }]
                    }
                  }
                }];
                addmessage(message);
              }
            });*/
          } else {
            var spinmessage = [{
              add: {
                content:
                  "Sorry, you have exhausted all your chances for today, See you tomorrow.",
              },
            },{
              action: {
                button: {
                  action:[{
                    text: "☰ Explore",
                    value: "explore"
                  }]
                }
              }
            }];
            addmessage(spinmessage);
          }
        }
      );
    }
  
    function winscratch(path) {
      var userid = localStorage.getItem("vmn");  
      const URL = `https://parseapi.server.scvpl.in/get/userstatus/spintowin/?mobile=${encodeURIComponent(userid)}&gameid=scratch&brandID=${brandID}`;
      jQuery.getJSON(
        URL,
        async function (scratchres) {
          // console.log(scratchres);
          if (scratchres.status) {
            /*const brandQuery = new Parse.Query("brandData");
            brandQuery.equalTo("brand_id", brandID);
            const brandResult = await brandQuery.first();
            if (brandResult) {
              const spinFee = brandResult.get(`spinpoints`);
              const userRef = new Parse.Query(`ChatAppUsers`);
              userRef.equalTo(`mobile`, userid);
              const userResult = await userRef.first();
              if (userResult) {
                const userBrandPointResult = await userResult
                  .relation(`wallets`)
                  .query()
                  .equalTo(`brandID`, brandID)
                  .equalTo(`userID`, userid)
                  .first();
                const brandPoint = (userBrandPointResult && userBrandPointResult.get(`currentBalance`)) ? userBrandPointResult.get(`currentBalance`) : '0';
                if(parseInt(brandPoint) && (parseInt(brandPoint) >= parseInt(spinFee))) {*/
                  jQuery.getJSON('https://parseapi.server.scvpl.in/get/brandcoupons/?brandid='+brandID, function(couponres) {
                    // console.log(couponres);
                    if(couponres.coupons && (couponres.coupons).length > 0) {
                      mainView.router.navigate({
                        name: "scratch",
                        params: { brandid: brandID },
                      });
                    } else {
                      var newmsg = [
                        {
                          add: {
                            content: `Coupons aren't available in this brand.`,
                          },
                        },
                        {
                          action: {
                            button: {
                              action: [
                                {
                                  text: "☰ Explore",
                                  value: "explore",
                                },
                              ],
                            },
                          },
                          delay: 1000,
                        },
                      ];
                      addmessage(newmsg);
                    }
                  });
                /*} else {
                  var spinmessage = [{
                    add: {
                      content:
                        "Sorry, You haven't sufficient point to play. Please earn some more points.",
                    },
                  },{
                    action: {
                      button: {
                        action:[{
                          text: "Explore",
                          value: "explore"
                        }]
                      }
                    }
                  }];
                  addmessage(spinmessage);
                }
              }
            } else {
              let message = [{
                add: {
                  content: `This functionality is not available.`,
                },
              },{
                action: {
                  button: {
                    action:[{
                      text: "Explore",
                      value: "explore"
                    }]
                  }
                }
              }];
              addmessage(message);
            }*/
          } else {
            var spinmessage = [{
              add: {
                content:
                  "Sorry, you have exhausted all your chances for today, See you tomorrow.",
              },
            },{
              action: {
                button: {
                  action:[{
                    text: "☰ Explore",
                    value: "explore"
                  }]
                }
              }
            }];
            addmessage(spinmessage);
          }
        }
      );
    }

    function stripHtml(html){
      // Create a new div element
      var temporalDivElement = document.createElement("div");
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html;
      // Retrieve the text property of the element (cross-browser support)
      return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    function sendReservationWhatsapp() {
      jQuery.getJSON(
      'https://parseapi.server.scvpl.in/get/branddetails?brandid=' + brandID,
      jsondata => {
        // console.log(jsondata);
        var wNumber = jsondata[0].whatsapp_no;
        var wMessage = localStorage.getItem("reservation_msg");
        // console.log(wMessage);
        if(wNumber) {
          if(wMessage) {
            var wMessageDecode = wMessage.replace(/<br\s*[\/]?>/gi, "\n");
            var URLDecodedMessage = stripHtml(wMessageDecode);
            // console.log(URLDecodedMessage);
            var URLEncodedMessage = encodeURIComponent(URLDecodedMessage);
            var whatsappMsg = [
              {
                add: {
                  type: "html",
                  content: `Please click on the button to send reservation to whatsapp: <a class="link button button-fill external" target="blank" href="https://wa.me/91`+wNumber+`?text=` +
                  URLEncodedMessage +
                  `" style="margin-top:5px;background:green;"><i class="fa fa-whatsapp"></i> Send to whatsapp</a>`,
                },
              },
              {
                action: {
                  button: {
                    action: [
                      {
                        text: "☰ Explore",
                        value: "explore",
                      },
                    ],
                  },
                },
                delay: 1000,
              },
            ];
            addmessage(whatsappMsg);
          } else {
            var spinmessage = [
              {
                add: {
                  content: `Reservation message format is not set.`
                }
              },
              {
                action: {
                  button: {
                    action: [
                      {
                        text: "☰ Explore",
                        value: "explore",
                      },
                    ],
                  },
                },
                delay: 1000,
              },
            ];
            addmessage(spinmessage);
          }
        } else {
          var spinmessage = [
            {
              add: {
                content: `Sorry, this feature is currently disabled by the brand.`
              }
            },
            {
              action: {
                button: {
                  action: [
                    {
                      text: "☰ Explore",
                      value: "explore",
                    },
                  ],
                },
              },
              delay: 1000,
            },
          ];
          addmessage(spinmessage);
        }
      });
    }

    function sendOrderWhatsapp() {
      jQuery.getJSON(
      'https://parseapi.server.scvpl.in/get/branddetails?brandid=' + brandID,
      jsondata => {
        var ordId = localStorage.getItem("pay_orderid");
        var ordObj = Parse.Object.extend("orders");
        var ordQuery = new Parse.Query(ordObj);
        ordQuery.get(ordId).then((data) => {
          var msg = '';
          // console.log(data.get("orderdata")[0].products);
          var ordDetails = (data.get("orderdata")[0].products).map((ordData) => {
            if(ordData.attribute) {
              msg = "⚀ "+ordData.title+" x "+ordData.price+" x "+ordData.qty+" x "+ordData.attribute;
            } else {
              msg = "⚀ "+ordData.title+" x "+ordData.price+" x "+ordData.qty;
            }
            return msg;
          });
          // var orderDetails = JSON.stringify(ordDetails);
          var orderDetails = ordDetails.join('\n');
          // console.log(orderDetails);

          var total = `${data.get("total")}`;
          var name = `${data.get("name")}`;
          var number = `${data.get("userid")}`;
          var buildings = `${data.get("building")}`;
          var upiStatus = `${data.get("upi_status")}`;
          var finalAddress = localStorage.getItem(`${brandID}-useraddress`) ? localStorage.getItem(`${brandID}-useraddress`) : '';
          var wNumber = jsondata[0].whatsapp_no;
          var wMessage = jsondata[0].whatsapp_msg;
          // var wMessage = 'New%20Order%20(%23OBJECTID)%0A%0A%7BOrder_Details%7D%0A%0APayable%3A%20%7BTotal_Amount%7D%0A%0ACustomer%20Details%3A%0A%F0%9F%91%A4%20%7BName%7D%0A%F0%9F%93%9E%20%7BNumber%7D%0A%F0%9F%8F%A1%20%7BBuilding%7D%0A%F0%9F%8F%A1%20%7BAddress%7D%0A%0A_%20_%20_%20_%20_%20_%20_%20_%20_%20_%20_%20_%20_%20_%20%0AThanks%20for%20the%20order';
          // console.log(wMessage);
          var wMessageDecode = decodeURIComponent(wMessage);
          if(upiStatus){
            var upiStr = 'upi://pay?pa='+jsondata[0].upi+'&pn='+(name).replace(/ /g,'')+'&am='+total+'&cu=INR&tn='+ordId;
            var upiMsg = '☛ Pay via UPI (Paytm, PhonePe, Bhim Pay, Google Pay etc) \n '+upiStr;
            var URLDecodedMessage = wMessageDecode.replace('OBJECTID', ordId).replace('{Order_Details}', orderDetails).replace('{Total_Amount}', total).replace('{Name}', name).replace('{Number}', number).replace('{Building}', buildings).replace('{Address}', finalAddress).replace('{UPI_ID}', upiMsg);
          } else {
            var URLDecodedMessage = wMessageDecode.replace('OBJECTID', ordId).replace('{Order_Details}', orderDetails).replace('{Total_Amount}', total).replace('{Name}', name).replace('{Number}', number).replace('{Building}', buildings).replace('{Address}', finalAddress);
          }
          // console.log(URLDecodedMessage);
          var URLEncodedMessage = encodeURIComponent(URLDecodedMessage);
          // console.log(URLEncodedMessage);
          if(wMessage) {
            var whatsappMsg = [
              {
                add: {
                  type: "html",
                  content: `Please click on the button to send order to whatsapp: <a class="link button button-fill external" target="blank" href="https://wa.me/91`+wNumber+`?text=` +
                  URLEncodedMessage +
                  `" style="margin-top:5px;background:green;"><i class="fa fa-whatsapp"></i> Send Order to whatsapp</a>`,
                },
              },
              {
                action: {
                  button: {
                    action: [
                      {
                        text: "☰ Explore",
                        value: "explore",
                      },
                    ],
                  },
                },
                delay: 1000,
              },
            ];
            addmessage(whatsappMsg);
          } else {
            var spinmessage = [
              {
                add: {
                  content: `Whatsapp message format is not set.`
                }
              },
              {
                action: {
                  button: {
                    action: [
                      {
                        text: "☰ Explore",
                        value: "explore",
                      },
                    ],
                  },
                },
                delay: 1000,
              },
            ];
            addmessage(spinmessage);
          }
        });
      });
    }
  
    function payonline() {
      const scripts = document.getElementsByTagName(`script`);
      let includedScripts = [];
      // let isScriptIncluded = false;      
      for(let script of scripts){        
        // script.src === `https://checkout.razorpay.com/v1/checkout.js` && !isScriptIncluded && (isScriptIncluded = true);
        includedScripts = [...includedScripts, script.src];
      }

      if (!includedScripts.includes(`https://checkout.razorpay.com/v1/checkout.js`)) {        
        let paymentScript = document.createElement("script");
        paymentScript.setAttribute(
            "src",
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        document.body.appendChild(paymentScript);
      } else {
        // console.log(`Script already included.`);
      }

      var code = jQuery(".botui-app-container").data("code");
      // var code = "jHsbMeEIiW";
      // console.log("code : ", code);
        code &&
      query.get(code).then((data) => {
        //var razor_key = 'rzp_live_1XyeIVzJo5LRJz';
        var payjson = JSON.parse(JSON.stringify(data));
        // console.log(payjson);
        var razor_key = json.razor_key;
        // var razor_key = 'rzp_test_5MyC0L9MVt3JbU';
        // console.log("Payment: ", payjson.payment);
        var brand_icon = payjson.brand_icon;
        var brand_id = payjson.brand_id;
        var cust_name = localStorage.getItem("name");
        var vmn = localStorage.getItem("vmn");
        var userNum = vmn.split("+");
        var cust_num = vmn.split("+91");
        var ord_id = localStorage.getItem("pay_orderid");
        if(payjson.payment) {
          if (ord_id) {
            var url_ord =
              "https://parseapi.server.scvpl.in/get/orderstatus?brandid=" +
              brand_id +
              "&orderid=" +
              ord_id;
            jQuery.getJSON(url_ord, function (stat) {
              if (stat.status == "rejected") {
                var pay_msg = {
                  add: {
                    content:
                      "You cannot make the payment as the order has been rejected. Please check My Orders for more details.",
                  },
                };
                newMessage(pay_msg);
                var value = {
                  action: {
                    button: {
                      action: [
                        {
                          icon: "comments",
                          text: "Feedback",
                          value: "feedback",
                        },
                        {
                          icon: "ellipsis-h",
                          text: "Explore",
                          value: "explore",
                        },
                      ],
                    },
                    data: [
                      {
                        orderid: ord_id,
                      },
                    ],
                  },
                };
                addAction(value);
              } else {
                var pay_amount = localStorage.getItem("pay_amount");
                pay_amount = parseInt(pay_amount) * 100;
                homeBot.message.add({
                  content: "",
                  autoHide: true,
                  loading: true,
                  delay: 1000,
                });
                jQuery.ajax({
                  url: "https://chatappapi.server.scvpl.in/razor/payment",
                  type: "POST",
                  data: { mob: userNum[1], pay: pay_amount, brand: brand_id },
                  success: function (data) {
                    // homeBot.message.add({
                    //   content: "Wait for payment!",
                    //   autoHide: true,
                    //   loading: false
                    // });
                    // console.log(data);
                    if (data.status && !data.error) {
                      var obj = data;
                      var customer = obj.customer;
                      var orderId = customer.order_id;
                      // Standard Checkout Integration
                      let options = {
                        key: razor_key,
                        amount: pay_amount,
                        currency: "INR",
                        name: cust_name,
                        description: "Online Payment",
                        image: brand_icon,
                        order_id: orderId,
                        handler: function(response) {
                          // console.log(response);
                          var payment_id = response.razorpay_payment_id;
                          var pay_id = payment_id.split("pay_");
                          var paysenddata = JSON.stringify({
                            orderid: ord_id,
                            paymentid: payment_id
                          });
                          jQuery.ajax({
                            type: "POST",
                            url: "https://parseapi.server.scvpl.in/update/payment",
                            headers: { "Content-Type": "application/json" },
                            data: paysenddata,
                            success: result => {
                              var feedback = localStorage.getItem("feed_orderid");
                              if (feedback) {
                                var pay_msg = {
                                  add: {
                                    content:
                                      "Your payment is successfully received for order id " +
                                      ord_id +
                                      ", and your payment id is " +
                                      pay_id[1] +
                                      "."
                                  }
                                };
                                newMessage(pay_msg);
                                var value = {
                                  action: {
                                    button: {
                                      action: [
                                        {
                                          icon: "comments",
                                          text: "Feedback",
                                          value: "feedback"
                                        },
                                        {
                                          icon: "ellipsis-h",
                                          text: "Explore",
                                          value: "explore"
                                        }
                                      ]
                                    },
                                    data: [
                                      {
                                        orderid: ord_id
                                      }
                                    ]
                                  }
                                };
                                addAction(value);
                              } else {
                                var pay_msg = {
                                  add: {
                                    content:
                                      "Your payment is successfully received for order id " +
                                      ord_id +
                                      ", and your payment id is " +
                                      pay_id[1] +
                                      "."
                                  }
                                };
                                newMessage(pay_msg);
                              }
                              localStorage.removeItem("pay_orderid");
                              localStorage.removeItem("pay_amount");
                              jQuery("iframe").each(function() {
                                if (
                                  this.src.indexOf(
                                    "socialrecharge.com/chat/orders"
                                  ) >= 0
                                ) {
                                  this.contentWindow.location.reload(true);
                                }
                              });
                            },
                            error: error => {
                              console.error(JSON.stringify(error));
                            }
                          });
                        },
                        "modal": {
                          "ondismiss": function(){
                            // console.log('Checkout form closed');
                            var pay_msg = {
                              add: {
                                content: "Your transaction was cancelled.",
                              },
                            };
                            newMessage(pay_msg);
                            var value = {
                              action: {
                                button: {
                                  action: [
                                    {
                                      icon: "ellipsis-h",
                                      text: "Explore",
                                      value: "explore",
                                    },
                                  ],
                                },
                              },
                            };
                            addAction(value);
                          }
                        },
                        prefill: {
                          contact: cust_num[1],
                        },
                        notes: {
                          brandname: "",
                          orderid: ord_id,
                        },
                        theme: {
                          color: "#F37254",
                        },
                      };
                      let rzp = new Razorpay(options);
                      rzp.open();
                      rzp.on('payment.failed', function (response){
                        console.log(response.error);
                        console.log(response.error.code);
                        console.log(response.error.description);
                      });
                      // var successCallback = function (payment_id) {
                      //   // alert('payment_id: ' + payment_id);
                      //   // var payment_id = response.razorpay_payment_id;
                      //   var pay_id = payment_id.split("pay_");
                      //   var paysenddata = JSON.stringify({
                      //     orderid: ord_id,
                      //     paymentid: payment_id,
                      //   });
                      //   jQuery.ajax({
                      //     type: "POST",
                      //     url: "https://parseapi.server.scvpl.in/update/payment",
                      //     headers: { "Content-Type": "application/json" },
                      //     data: paysenddata,
                      //     success: (result) => {
                      //       var feedback = localStorage.getItem("feed_orderid");
                      //       if (feedback) {
                      //         var pay_msg = {
                      //           add: {
                      //             content:
                      //               "Your payment is successfully received for order id " +
                      //               ord_id +
                      //               ", and your payment id is " +
                      //               pay_id[1] +
                      //               ".",
                      //           },
                      //         };
                      //         newMessage(pay_msg);
                      //         var value = {
                      //           action: {
                      //             button: {
                      //               action: [
                      //                 {
                      //                   icon: "comments",
                      //                   text: "Feedback",
                      //                   value: "feedback",
                      //                 },
                      //                 {
                      //                   icon: "ellipsis-h",
                      //                   text: "Options",
                      //                   value: "mainmenu",
                      //                 },
                      //               ],
                      //             },
                      //             data: [
                      //               {
                      //                 orderid: ord_id,
                      //               },
                      //             ],
                      //           },
                      //         };
                      //         addAction(value);
                      //       } else {
                      //         var pay_msg = {
                      //           add: {
                      //             content:
                      //               "Your payment is successfully received for order id " +
                      //               ord_id +
                      //               ", and your payment id is " +
                      //               pay_id[1] +
                      //               ".",
                      //           },
                      //         };
                      //         newMessage(pay_msg);
                      //       }
                      //       localStorage.removeItem("pay_orderid");
                      //       localStorage.removeItem("pay_amount");
                      //       jQuery("iframe").each(function () {
                      //         if (
                      //           this.src.indexOf(
                      //             "socialrecharge.com/chat/orders"
                      //           ) >= 0
                      //         ) {
                      //           this.contentWindow.location.reload(true);
                      //         }
                      //       });
                      //     },
                      //     error: (error) => {
                      //       console.error(JSON.stringify(error));
                      //     },
                      //   });
                      // };
                      // var cancelCallback = function (error) {
                      //   console.log(error.description + ' (Error '+error.code+')');
                      //   var cancelMsg = {
                      //     add: {
                      //       content:
                      //         "Your payment was cancelled. Please try again from My Orders.",
                      //     },
                      //   };
                      //   newMessage(cancelMsg);
                      //   var cancelVal = {
                      //     action: {
                      //       button: {
                      //         action: [
                      //           {
                      //             text: "My Orders",
                      //             value: "myorders",
                      //           },
                      //         ],
                      //       },
                      //     },
                      //     save: "paymentcancelled",
                      //   };
                      //   addAction(cancelVal);
                      // };
                      // RazorpayCheckout.open(
                      //   options,
                      //   successCallback,
                      //   cancelCallback
                      // );
                    } else if (data.error) {
                      var pay_msg = {
                        add: {
                          content: `Sorry, online payment is not possible right now. You have to pay in cash for this order.`,
                        },
                      };
                      newMessage(pay_msg);
                      var value = {
                        action: {
                          button: {
                            action: [
                              {
                                icon: "comments",
                                text: "Feedback",
                                value: "feedback",
                              },
                              {
                                icon: "ellipsis-h",
                                text: "Explore",
                                value: "explore",
                              },
                            ],
                          },
                          data: [
                            {
                              orderid: ord_id,
                            },
                          ],
                        },
                      };
                      addAction(value);
                    }
                  },
                });
              }
            });
          } else {
            var pay_msg = {
              add: {
                content: "You have already made the payment online.",
              },
            };
            newMessage(pay_msg);
            var value = {
              action: {
                button: {
                  action: [
                    {
                      icon: "ellipsis-h",
                      text: "Explore",
                      value: "explore",
                    },
                  ],
                },
              },
            };
            addAction(value);
          }
        } else {
          var pay_msg = {
            add: {
              content: "Online payment not available. Please pay via Cash on delivery.",
            },
          };
          newMessage(pay_msg);
          var value = {
            action: {
              button: {
                action: [
                  {
                    icon: "ellipsis-h",
                    text: "Explore",
                    value: "explore",
                  },
                ],
              },
            },
          };
          addAction(value);
        }
      }).catch(error => {
        if (
          error.code === 209 ||
          error.code === 206 ||
          error.message === `Invalid session token`
        ) {
          logout();
          return;
        }
      });
    }
  
    jQuery(window).bind("storage", function (e) {
      if (e.originalEvent.key == "verified_number") {
        // var value = {
        //           "action" : {
        //             "button" : {
        //               "action" : [{
        //                 "icon": "info",
        //                 "text" : "Continue",
        //                 "value" : "postverification"
        //               }]
        //             }
        //           }
        //       };
        //   addAction(value,e.originalEvent.newValue);
      } else if (e.originalEvent.key == "pickedlocation") {
        var nvalue = jQuery.parseJSON(e.originalEvent.newValue);
        var nextpayload = nvalue.nextpayload.substr(1);
        localStorage.setItem(
          "userdata-" + brandID + "-pl",
          e.originalEvent.newValue
        );
        var addpush = { key: "selectedaddress", value: nvalue };
        myArray.push(addpush);
  
        localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
        localStorage.removeItem("pickedlocation");
        var value = {
          action: {
            button: {
              action: [
                {
                  icon: "info",
                  text: "Drop",
                  value: nextpayload,
                },
              ],
            },
          },
        };
        addAction(value, e.originalEvent.newValue);
      } else if (e.originalEvent.key == "msgtoken") {
        var pusht = { key: "msgtoken", value: e.originalEvent.newValue };
        myArray.push(pusht);
        localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
        var value = {
          action: {
            button: {
              action: [
                {
                  icon: "info",
                  text: "Continue",
                  value: "subscribe",
                },
              ],
            },
          },
        };
        addAction(value, e.originalEvent.newValue);
      } else if (/payload/i.test(e.originalEvent.key)) {
        // offline("myskills", "");
        localStorage.removeItem(e.originalEvent.key);
      }
    });
  // }
  
  window.document.addEventListener("payloads", handlePEvent, false);
  function handlePEvent(e) {
    offline(e.detail, "");
  }
  
  window.document.addEventListener("payment", handlePayEvent, false);
  function handlePayEvent(e) {
    payonline();
  }
  
  window.document.addEventListener("checkout", handleCEvent, false);
  function handleCEvent(e) {
    if(app.sheet.get('.sheet-modal').open) {
      app.sheet.close();
    }
    // console.log(brand_post_url);
    jQuery.ajax({
      type: "POST",
      url: brand_post_url,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(e.detail),
      success: (success) => {
        // console.log(success);
        clearout();
        // serverdata = JSON.parse(success); //DUE TO CHANGE IN SERVER RESPONSE, HAVE TO COMMENT IT
        if (success.length > 3) {
          localStorage.setItem("feed_orderid", success[3].action.data[0].orderid);
          localStorage.setItem("pay_orderid", success[3].action.data[0].orderid);
          localStorage.setItem("pay_amount", success[3].action.data[0].amount);
        }
  
        success.forEach((element) => {
          if (element.add) {
            newMessage(element);
          } else if (element.action) {
            addAction(element);
          }
        });
      },
      error: (error) => {
        var emsg = {
          add: {
            content:
              "There seems to be some problem with connectivity, please try again later",
          },
        };
        newMessage(emsg);
        // clearout();
      },
    });
    // jQuery.post(brand_post_url, e.detail, function(result){
    // var serverdata = JSON.parse(result);
    // serverdata.forEach(element => {
    //   newMessage(element);
    // });
  
    // });
  }
  
  window.document.addEventListener("buy", handleBEvent, false);
  function handleBEvent(e) {
    if (e.detail.type == "product") {
      var value = "";
      if (e.detail.attribute) {
        var minimum = parseInt(e.detail.min);
        var maximum = parseInt(e.detail.max);
        var noptions = [];
        var j = 0;
        var value;
        for (i = minimum; i <= maximum; i++) {
          if (e.detail.attribute) {
            noptions[j] = {
              text: i,
              value: "attribute_" + i + "_" + JSON.stringify(e.detail.attribute),
            };
          } else {
            noptions[j] = { text: i, value: "attribute_" + i };
          }
          //shamli
          j++;
          if (i == maximum) {
            value = {
              action: {
                select: {
                  action: {
                    button: {
                      icon: "plus",
                      label: "ok",
                    },
                    label: "number",
                    multipleselect: false,
                    options: noptions,
                    placeholder: "select quantity",
                    searchselect: false,
                    value: "",
                  },
                },
              },
              save: e.detail.sku + "_quantity",
            };
            addAction(value);
          }
        }
      } else {
        var minimum = parseInt(e.detail.min);
        var maximum = parseInt(e.detail.max);
        var noptions = [];
        var j = 0;
        var value;
        for (i = minimum; i <= maximum; i++) {
          noptions[j] = { text: i, value: "addtocart_" + i };
          //shamli
          j++;
          if (i == maximum) {
            value = {
              action: {
                select: {
                  action: {
                    button: {
                      icon: "plus",
                      label: "ok",
                    },
                    label: "number",
                    multipleselect: false,
                    options: noptions,
                    placeholder: "select quantity",
                    searchselect: false,
                    value: "",
                  },
                },
              },
              save: e.detail.sku + "_quantity",
            };
            addAction(value);
          }
        }
      }
      // addAction(value, e.detail);
      localStorage.setItem(
        "userdata-" + brandID + "-" + e.detail.sku,
        JSON.stringify(e.detail)
      );
    }
    // offline(e.detail, "");
  }
  
  window.document.addEventListener("spintowin", handleSpinEvent, false);
  function handleSpinEvent(e) {
    var vmn = localStorage.getItem("vmn");
    var result = JSON.parse(e.detail);
    // console.log(result);
    var prize = result[0].value;
    var prizeId = result[0].userData.prizeId ? result[0].userData.prizeId : '';
    var senddata = {
      mobile: vmn,
      brandid: brandID,
      result: result[0].msg,
      win: result[0].win,
      prize: prize,
      gameid: "spintowin",
      prizeid: prizeId,
    };
    // console.log("senddata : ", JSON.stringify(senddata));
    const spinUrl = "https://parseapi.server.scvpl.in/set/userstatus/spintowin/";
    // const spinUrl = "http://localhost:12345/set/userstatus/spintowin/";
    jQuery.ajax({
      type: "POST",
      url: spinUrl,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(senddata),
      success: async (success) => {
        // console.log("Success : ", success);
        /*const brandQuery = new Parse.Query("brandData");
        brandQuery.equalTo("brand_id", brandID);
        brandQuery
        .find()
        .then(function (results) {
          if(results.length > 0){  
            jQuery.each(results, async function (key, value) {
              var jsonRes = JSON.parse(JSON.stringify(value));
              var spinfee = jsonRes.spinfee;
              const userQuery = new Parse.Query("ChatAppPoints");
              userQuery.equalTo("mobile", vmn);
              const userPointResult = await userQuery.find();  
              // console.log(userPointResult);
              if(userPointResult.length > 0){
                let brandPoints = userPointResult[0].get("brandPoints");
                let totalPoints = userPointResult[0].get("totalPoints");
                let individualBrandPoints = userPointResult[0].get("brandPoints") && userPointResult[0].get("brandPoints")[brandID] && userPointResult[0].get("brandPoints")[brandID]['points']            
                // console.log(individualBrandPoints);
                let updateBrandPoints = individualBrandPoints ? {[brandID]:{points: individualBrandPoints - parseInt(spinfee)}} : {[brandID]:{points:parseInt(spinfee)}};
                brandPoints = {...brandPoints, ...updateBrandPoints};
                // console.log(brandPoints);
                totalPoints = (parseInt(totalPoints) - parseInt(spinfee));
                let pointsLog = userPointResult[0].get("points");
                let tempPointsLog = [...pointsLog, {[`spintowin_${brandID}_debit_spin_points`]: {
                    points: spinfee,
                    type: "debit",
                    tag: `Debit ${spinfee} point(s) for spin to win.`,
                    reason: `Debited ${spinfee} point(s) for spin to win.`,
                    brand: brandID,
                    gameID: "spintowin",
                    date: Date.now(),
                    // date: moment().format(),
                  }}]
                const userPointObj = Parse.Object.extend(`ChatAppPoints`);
                let userPoint = new userPointObj();
                userPoint.id = userPointResult[0].id;
                userPoint.save({
                  points: tempPointsLog,
                  brandPoints: brandPoints,
                  totalPoints: totalPoints
                }).then(
                  (pointsSnap) => {
                    console.log(`${spinfee} points debited and updated points log.`)                  
                  },
                  (error) => {
                    console.error(
                      "Error in setting points to user for reaction on brand post. Error is : ",
                      JSON.stringify(error)
                    );
                  });
              }
            });
          }
        });*/

        const brandRef = new Parse.Query(`brandData`);
        brandRef.equalTo(`brand_id`, brandID);
        const brandResult = await brandRef.first();
        if (brandResult) {
          const spinFee = brandResult.get(`spinfee`);

          const userRef = new Parse.Query(`ChatAppUsers`);
          userRef.equalTo(`mobile`, vmn);
          const userResult = await userRef.first();
          
          if (userResult) {
            const userBrandPointResult = await userResult
              .relation(`wallets`)
              .query()
              .equalTo(`userID`, vmn)
              .equalTo(`brandID`, brandID)
              .first();
            if (userBrandPointResult) {
              const pointsLog = userBrandPointResult.get(`pointsLog`);
              const updatedPointsLog = [...pointsLog, {[`spintowin_${brandID}_debit_spin_points`]: {
                    points: spinFee,
                    type: "debit",
                    tag: `Debit ${spinFee} point(s) for spin to win.`,
                    reason: `Debited ${spinFee} point(s) for spin to win.`,
                    brand: brandID,
                    gameID: "spintowin",
                    date: new Date().toJSON()                    
                  }}]
              userBrandPointResult.increment(`totalSpent`, parseInt(spinFee));
              userBrandPointResult.decrement(`currentBalance`, parseInt(spinFee));
              userBrandPointResult.relation(`brand`).add(brandResult);
              userBrandPointResult.relation(`user`).add(userResult);
              userBrandPointResult.save({
                pointsLog: updatedPointsLog
              }).then((userWalletSnap)=>{
                console.log(`User wallet sync successfully.`,userWalletSnap)
              }, error=> {
                console.error(`Error in syncing user wallet. Error is `, error);
              });
            }
          }
        }
  
        mainView.router.back();
        var emsg = success.message[0].messages[0];
        // console.log(emsg);
        newMessage(emsg);
        var amsg = success.message[0].messages[1];
        addAction(amsg);
        // if(prize > 0) {
        //   var score = '5';
        // } else {
        //   var score = '0';
        // }
        // var scoredata = { mobile: vmn, brandid: brandID, score: score, gameid: e.detail[0].gameId };
        // // console.log("scoredata : ", JSON.stringify(scoredata));
        // var scoreUrl = "https://parseapi.server.scvpl.in/set/gamescore/";
        // jQuery.ajax({
        //   type: "POST",
        //   url: scoreUrl,
        //   headers: { "Content-Type": "application/json" },
        //   data: JSON.stringify(scoredata),
        //   success: success => {
        //     console.log("Success : ",success);
        //   }
        // });
      },
      error: (error) => {
        var emsg = {
          add: {
            content:
              "There seems to be some problem with connectivity, please try again later",
          },
        };
        newMessage(emsg);
        // clearout();
      },
    });
  }
  
  window.document.addEventListener("scratchtowin", handleScratchEvent, false);
  function handleScratchEvent(e) {
    var vmn = localStorage.getItem("vmn");
    // console.log("e -", e.detail[0]);
    var prize = e.detail[0].code.toString();
    // var msg = "You won " + prize;
    if(prize == "Lose"){
      var win = false;
      var msg = "Sorry you Lose.";
    } else {
      var win = true;
      var msg = "Congratulations, you have won a coupon. Here is the coupon code: "+prize;
    }
    var senddata = {
      mobile: vmn,
      brandid: brandID,
      result: msg,
      win: win,
      prize: prize,
      gameid: "scratch",
    };
    // console.log("senddata : ", JSON.stringify(senddata));
    var scratchUrl = "https://parseapi.server.scvpl.in/set/userstatus/spintowin/";
    jQuery.ajax({
      type: "POST",
      url: scratchUrl,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(senddata),
      success: (success) => {
        // console.log("Success : ", success);
        mainView.router.back();
        var emsg = success.message[0].messages[0];
        newMessage(emsg);
        var amsg = success.message[0].messages[1];
        addAction(amsg);
      },
      error: (error) => {
        var emsg = {
          add: {
            content:
              "There seems to be some problem with connectivity, please try again later",
          },
        };
        newMessage(emsg);
      },
    });
  }
  
  window.document.addEventListener("mobile", handleEvent, false);
  function handleEvent(e) {
    localStorage.setItem("userdata-" + brandID + "-vmn", e.detail);
    var nmobile = { key: "mobile", value: e.detail };
    var bdata = { key: "brandid", value: brandID };
    var value = [];
    var deviceType = getMobileOperatingSystem();
    myArray.push(nmobile);
    myArray.push(bdata);
  
    localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
    localStorage.setItem("vmn", e.detail);
    var name = localStorage.getItem("userdata-" + brandID + "-name");
    if (name) {
      localStorage.setItem("name", name);
    }
    localStorage.removeItem("verified_number");
    jQuery.getJSON(jQuery(".botui-app-container").data("url"), (json) => {
      // var path = "postverification";
  
      if (deviceType == "ios") {
        offline("postverificationios", "");
      } else if (deviceType == "android") {
        offline("postverificationandroid", "");
      }
  
      addmessage(value, e.detail);
    });
  }
  
  window.addEventListener("message", function (e) {
    // console.log("e: ", e.data);
    if (e.data.type == "phone") {
      var phone = e.data.detail;
      // console.log("phone", phone);
      localStorage.setItem("verified_number", phone);
      handleEventPhone(phone);
    } else if (e.data.type == "buy") {
      var butt = e.data.detail;
      // console.log(butt);
      var payload = butt.payload;
      localStorage.setItem("payload-" + payload, payload);
      handleBuyEvent(e.data);
    } else if (e.data.type == "checkout") {
      var butt = e.data.detail;
      // console.log(butt);
      var payload = butt.payload;
      //localStorage.setItem('payload-'+payload, payload);
      handleCheckoutEvent(e.data);
    } else if (e.data.type == "payment") {
      var butt = e.data.detail;
      // console.log(butt);
      var payload = butt.payload;
      var pay_oid = e.data.pay_oid;
      var pay_price = e.data.pay_price;
      localStorage.setItem("feed_orderid", pay_oid);
      localStorage.setItem("pay_orderid", pay_oid);
      localStorage.setItem("pay_amount", pay_price);
      handlePaymentEvent(e.data);
    } else if (e.data.type == "spintowin") {
      var butt = e.data.detail;
      // console.log(butt);
      var payload = butt.payload;
      //localStorage.setItem('payload-'+payload, payload);
      handleSpintowinEvent(e.data);
    } else if (e.data.type == "scratchtowin") {
      var butt = e.data.detail;
      // console.log(butt);
      var payload = butt.payload;
      //localStorage.setItem('payload-'+payload, payload);
      handleScratchtowinEvent(e.data);
    } else {
      // console.log("type: ", e.data.type);
    }
  });
  
  function handleScratchtowinEvent(e) {
    var vmn = localStorage.getItem("vmn");
    // console.log("e -", e.detail[0]);
    var prize = e.detail[0].code.toString();
    var msg = "You won " + prize;
    var senddata = {
      mobile: vmn,
      brandid: brandID,
      result: msg,
      prize: prize,
      gameid: "scratch",
    };
    // console.log("senddata : ", JSON.stringify(senddata));
    var scratchUrl = "https://parseapi.server.scvpl.in/set/userstatus/spintowin/";
    jQuery.ajax({
      type: "POST",
      url: scratchUrl,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(senddata),
      success: (success) => {
        // console.log("Success : ", success);
        var emsg = success.message[0].messages[0];
        newMessage(emsg);
        var amsg = success.message[0].messages[1];
        addAction(amsg);
      },
      error: (error) => {
        var emsg = {
          add: {
            content:
              "There seems to be some problem with connectivity, please try again later",
          },
        };
        newMessage(emsg);
      },
    });
  }
  
  function handleSpintowinEvent(e) {
    var vmn = localStorage.getItem("vmn");
    var data = JSON.parse(e.detail);
    // console.log("Spin data : ", data[0]);
    var prize = data[0].userData.score.toString();
    var senddata = {
      mobile: vmn,
      brandid: brandID,
      result: data[0].msg,
      prize: prize,
      gameid: data[0].gameId,
    };
    // console.log("senddata : ", JSON.stringify(senddata));
    var spinUrl = "https://parseapi.server.scvpl.in/set/userstatus/spintowin/";
    jQuery.ajax({
      type: "POST",
      url: spinUrl,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(senddata),
      success: (success) => {
        // console.log("Success : ", success);
        var emsg = success.message[0].messages[0];
        // console.log(emsg);
        newMessage(emsg);
        var amsg = success.message[0].messages[1];
        addAction(amsg);
      },
      error: (error) => {
        var emsg = {
          add: {
            content:
              "There seems to be some problem with connectivity, please try again later",
          },
        };
        newMessage(emsg);
        // clearout();
      },
    });
  }
  
  function handleEventPhone(phone) {
    // console.log("Inside Mobile Number : " + phone);
    localStorage.setItem("userdata-" + brandID + "-vmn", phone);
    var nmobile = { key: "mobile", value: phone };
    var bdata = { key: "brandid", value: brandID };
    var value = [];
    var deviceType = getMobileOperatingSystem();
    myArray.push(nmobile);
    myArray.push(bdata);
  
    localStorage.setItem("json-" + brandID, JSON.stringify(myArray));
    localStorage.setItem("vmn", phone);
    var name = localStorage.getItem("userdata-" + brandID + "-name");
    if (name) {
      localStorage.setItem("name", name);
    }
    localStorage.removeItem("verified_number");
    // var path = "postverification";
    if (deviceType == "ios") {
      offline("postverificationios", "");
    } else if (deviceType == "android") {
      offline("postverificationandroid", "");
    }
  }
  
  function handleBuyEvent(e) {
    if (e.detail.type == "product") {
      var value = "";
      if (e.detail.attribute) {
        var minimum = parseInt(e.detail.min);
        var maximum = parseInt(e.detail.max);
        var noptions = [];
        var j = 0;
        var value;
        for (i = minimum; i <= maximum; i++) {
          if (e.detail.attribute) {
            noptions[j] = {
              text: i,
              value: "attribute_" + i + "_" + JSON.stringify(e.detail.attribute),
            };
          } else {
            noptions[j] = { text: i, value: "attribute_" + i };
          }
          j++;
          if (i == maximum) {
            value = {
              action: {
                select: {
                  action: {
                    button: {
                      icon: "plus",
                      label: "ok",
                    },
                    label: "number",
                    multipleselect: false,
                    options: noptions,
                    placeholder: "select quantity",
                    searchselect: false,
                    value: "",
                  },
                },
              },
              save: e.detail.sku + "_quantity",
            };
            addAction(value);
          }
        }
      } else {
        var minimum = parseInt(e.detail.min);
        var maximum = parseInt(e.detail.max);
        var noptions = [];
        var j = 0;
        var value;
        for (i = minimum; i <= maximum; i++) {
          noptions[j] = { text: i, value: "addtocart_" + i };
          j++;
          if (i == maximum) {
            value = {
              action: {
                select: {
                  action: {
                    button: {
                      icon: "plus",
                      label: "ok",
                    },
                    label: "number",
                    multipleselect: false,
                    options: noptions,
                    placeholder: "select quantity",
                    searchselect: false,
                    value: "",
                  },
                },
              },
              save: e.detail.sku + "_quantity",
            };
            addAction(value);
          }
        }
      }
      localStorage.setItem(
        "userdata-" + brandID + "-" + e.detail.sku,
        JSON.stringify(e.detail)
      );
    }
  }
  
  function handleCheckoutEvent(e) {
    // console.log("checkout data", e.detail);
    jQuery.ajax({
      type: "POST",
      url: brand_post_url,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(e.detail),
      success: (success) => {
        //console.log('SUCCESS :'+JSON.stringify(success));
        clearout();
        var serverdata = "";
        //serverdata = JSON.parse(success);
        serverdata = success;
        // console.log("serverdata : ", serverdata);
        if (serverdata.length > 3) {
          localStorage.setItem(
            "feed_orderid",
            serverdata[3].action.data[0].orderid
          );
          localStorage.setItem(
            "pay_orderid",
            serverdata[3].action.data[0].orderid
          );
          localStorage.setItem("pay_amount", serverdata[3].action.data[0].amount);
        }
  
        serverdata.forEach((element) => {
          // console.log(element);
          if (element.add) {
            newMessage(element);
          } else if (element.action) {
            addAction(element);
          }
        });
      },
      error: (error) => {
        // console.log(JSON.stringify(error));
        var emsg = {
          add: {
            content:
              "There seems to be some problem with connectivity, please try again later",
          },
        };
        newMessage(emsg);
      },
    });
  }
  
  function handlePaymentEvent(e) {
    // console.log("Pay data", e.detail);
    payonline();
  }
  
  function getQueryStringValue(key) {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  }
  function resizeIframe(iframe) {
    var size = iframe.contentWindow.document.body.scrollHeight;
    var size_new = size;
    iframe.style.height = size_new + "px";
    // console.log(iframe.src);
    // if (
    //   iframe.src
    //     .toLowerCase()
    //     .indexOf("https://www.socialrecharge.com/chat/listapp") >= 0
    // ) {
    //   var name = localStorage.getItem("name");
    //   var vmn = localStorage.getItem("vmn");
    //   var building = localStorage.getItem("p-" + brandID + "-building");
    //   var finaladdress = localStorage.getItem("p-" + brandID + "-finaladdress");
    //   var finaldata = window.localStorage.getItem("json-" + brandID);
    //   // console.log('Json Data : ', finaldata);
    //   iframe.contentWindow.postMessage(
    //     {
    //       name: name,
    //       vmn: vmn,
    //       building: building,
    //       finaladdress: finaladdress,
    //       detail: finaldata,
    //     },
    //     "https://www.socialrecharge.com/chattest/cart/"
    //   );
    // }
    // if ( iframe.src .toLowerCase()
    //     .indexOf("https://www.socialrecharge.com/spinandwin") >= 0
    // ) {
    //   var vmn = localStorage.getItem("vmn");
    //   iframe.contentWindow.postMessage(
    //     { vmn: vmn },
    //     "https://www.socialrecharge.com/spinandwin/"
    //   );
    // }
    // if ( iframe.src .toLowerCase()
    //     .indexOf("https://www.socialrecharge.com/chat/scratch") >= 0
    // ) {
    //   var vmn = localStorage.getItem("vmn");
    //   iframe.contentWindow.postMessage(
    //     { vmn: vmn },
    //     "https://www.socialrecharge.com/chat/scratch/"
    //   );
    // }
  }
  
  function localStorageChangeEvents(e) {}
  document.addEventListener("localDataStorage", localStorageChangeEvents, false);
  var ga_record = function (type, action) {
    if (ga) {
      ga("send", {
        hitType: "event",
        eventCategory: type,
        eventAction: action,
      });
    }
  };
  function addmessage(serverdata, fdata) {
    var fda = serverdata;
    jQuery.each(fda, function (index, val) {
      if (val.add) {
        setTimeout(function () {
          newMessage(val, fdata);
        }, index * 1000);
      } else if (val.action) {
        setTimeout(function () {
          addAction(val);
        }, index * 1000);
      }
    });
  }
  function clearout() {
    var arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (var i = 0; i < localStorage.length; i++) {
      var str1 = localStorage.key(i);
      var str2 = "userdata-" + brandID;
      if (str1.indexOf(str2) != -1) {
        arr.push(localStorage.key(i));
      }
    }
    // Iterate over arr and remove the items by key
    if (arr.length) {
      for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
      }
    }
    var localbrandid = brandID || localStorage.getItem("brandid");
    if (localbrandid) {
      localStorage.removeItem("json-" + localbrandid);
      myArray = [];
    }
  }
  
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //     return "windows";
    // }
  
    // if (/android/i.test(userAgent)) {
    //     return "android";
    // }
  
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
  
    return "android";
  }
  
  function scancode() {
    cordova.plugins.barcodeScanner.scan(
      function (result) {
        console.log(
          "We got a barcode\n" +
            "Result: " +
            result.text +
            "\n" +
            "Format: " +
            result.format +
            "\n" +
            "Cancelled: " +
            result.cancelled
        );
        var payload = result.text;
        if (payload) {
          if (payload.indexOf("TYPE=CELL") >= 0) {
            var name = payload.split('UTF-8:');
            var nameRes = name[1].split("\nEMAIL;");
            // console.log(nameRes[0]);
            var phone = payload.split('TYPE=CELL:');
            var phoneRes = phone[1].split("\nURL");
            //app.dialog.confirm('Name: '+nameRes[0]+'<br>Mobile: '+phoneRes[0]+'<br>Do you want to add this contact?', function () {
            var dialog = app.dialog.create({
              text: 'Name: '+nameRes[0]+'<br>Mobile: '+phoneRes[0]+'<br>Do you want to add this contact?',
              buttons: [
              {
                text: 'Cancel',
              },
              {
                text: 'Save',
                onClick: function () {
                  var email = payload.split('INTERNET:');
                  var emailRes = email[1].split("\nTEL;");
                  // console.log(emailRes[0]);
                  var options      = new ContactFindOptions();
                  options.filter   = phoneRes[0];
                  options.multiple = true;
                  options.desiredFields = [navigator.contacts.fieldType.id, navigator.contacts.fieldType.displayName, navigator.contacts.fieldType.name, navigator.contacts.fieldType.phoneNumbers];
                  options.hasPhoneNumber = true;
                  var fields       = [navigator.contacts.fieldType.displayName, navigator.contacts.fieldType.name, navigator.contacts.fieldType.phoneNumbers];
                  navigator.contacts.find(fields, function (contacts) {
                    // console.log(contacts);
                    if(contacts.length < 1) {
                      // create a new contact object
                      var contact = navigator.contacts.create();
                      contact.displayName = nameRes[0];
                      contact.nickname = nameRes[0]; // specify both to support all devices
                      // populate some fields
                      var name = new ContactName();
                      name.givenName = nameRes[0];
                      contact.name = name;
                      var phoneNumbers = [];
                      phoneNumbers[0] = new ContactField('home', phoneRes[0], false);
                      contact.phoneNumbers = phoneNumbers;
                      var emails = [];
                      emails[0] = new ContactField('home', emailRes[0], false);
                      contact.emails = emails;
                      // save to device
                      contact.save(function onSuccess(contact) {
                        // console.log(contact);
                        var qmessage = {
                          add: {
                            content: "Contact successfully saved in device.",
                          },
                        };
                        newMessage(qmessage);
                      }, function onError(contactError) {
                        console.log("Error = " + contactError.code);
                      });
                      
                    } else {
                      var qmessage = {
                        add: {
                          content: "Number already exist in device.",
                        },
                      };
                      newMessage(qmessage);
                    }
                  },
                  function (contactError) {
                    // console.log(contactError);
                    console.log('onError!');
                  }, options);
                },
              },
            ]
          }).open();
          } if ((payload.indexOf("socialrecharge.com/chat/") >= 0) || (payload.indexOf("chatapp.store/") >= 0)) {
            var bid = payload.substring(payload.lastIndexOf("/") + 1, payload.length);
            // console.log('bid : '+bid);
            var bQuery = new Parse.Query("brandData");
            bQuery.equalTo("brand_id", bid);
            bQuery
              .find()
              .then(function (results) {
                jQuery.each(results, function (key, value) {
                  var json = JSON.parse(JSON.stringify(value));
                  // console.log(json);
                  var brand_id = json.brand_id;
                  var id = json.type == "fb" ? "'" + json.brand_code + "'" : "'" + value.id + "'";
                  var pageid = json.type == "fb" ? json.fb_page_id : "";
                  let type = (json.type && json.type != "" && json.type.length > 0) ? json.type : "";
                  openPop(id, type, pageid, brand_id);
                });
              })
              .catch(function (error) {
                // console.log(error);
                var qmessage = {
                  add: {
                    content: "Brand not found!",
                  },
                };
                newMessage(qmessage);
              });
          } else {
            var resultUrl = {
              add: {
                type: "embed",
                content: payload,
              },
            };
            newMessage(resultUrl);
          }
        } else {
          var qmessage = {
            add: {
              content: "Please scan qrcode only.",
            },
          };
          newMessage(qmessage);
        }
      },
      function (error) {
        app.dialog.alert("Scanning failed: " + error);
      },
      {
        preferFrontCamera: false, // iOS and Android
        showFlipCameraButton: true, // iOS and Android
        showTorchButton: true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: true, // Android, save scan history (default false)
        prompt: "Place a barcode inside the scan area", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
        orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true, // iOS
        disableSuccessBeep: false, // iOS and Android
      }
    );
  }
  
  //REPLACED WITH Sangeet Sir's FUNCTION
  async function openPop(bid, type, page_id, brand_id, payload) {
    // console.log(bid,type,page_id,brand_id,payload);
    // console.log($$(`.page .navbar .page_title .title`).html());
    if($$(`.page .navbar .page_title .title`).html() == 'ChatApp Store') {
      $$(`#closePopup`).hide();
    } else {
      $$(`#closePopup`).show();
    }
    $$(`.page .navbar .prev_page_title .title`).html($$(`.page .navbar .page_title .title`).html());
    localStorage.setItem("brandid", brand_id);
    app.popup.close(".popup-category");
    if (app.sheet.get('.sheet-modal') && app.sheet.get('.sheet-modal').open) {
      app.sheet.close();
    }
    if(type == "fb") {
      //coded date 12/05/2021
      let name;
      const user = await firebase.auth().currentUser;    
      if (await user) {
        (user && (await user.displayName)) != null
          ? (name = user.displayName.trim())
          : null;
        (user && (await user.displayName)) == null &&
        localStorage.getItem(`brandid`) &&
        (localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
          localStorage.getItem(
            `userdata-${localStorage.getItem(`brandid`)}-name`
          ))
          ? updateUserProfile()
          : `${
              ((name = user && user.displayName.trim()),
              (user &&
                (localStorage.getItem(`brandid`) || brandID) &&
                localStorage.setItem(
                  `${localStorage.getItem(`brandid`) || brandID}-name`,
                  user.displayName
                )) ||
                (user &&
                  (localStorage.getItem(`brandid`) || brandID) &&
                  localStorage.setItem(
                    `userdata-${localStorage.getItem(`brandid`) || brandID}-name`,
                    user.displayName
                  )))
            }`;
        user && (await user.phoneNumber)
          ? localStorage.setItem("vmn", user.phoneNumber)
          : null;
      }

      var pageid = bid;
      var keyword_type = "get-started";
      var dta = {
        api_key:'2-HyOzasv1571307953ZpDgvuC',
        id:pageid,
      };
      // console.log(dta);
      var url = "https://messengerf.com/Api/get_brand_info/";
      app.request.promise.json(url, dta)
      .then(async function (result) {      
        // console.log('Load was performed:',result);
        homeBot.message.removeAll();
        var messg = await result.data.brand_info[0];
  
        // json = JSON.parse(JSON.stringify(data));
        json = messg;
        // localStorage.setItem("theteajunctionsaket-jsondata", JSON.stringify(json1));
        if(json.page_cover){
          isImageExist(json.page_cover, function(exists) {
            var brandCover = exists ? json.page_cover : NO_COVER;
            jQuery("#brandcover").css("background-image", "url(" + brandCover + ")");
          });
        } else {
          jQuery("#brandcover").css("background-image", `url(${NO_COVER})`);
        }
        if(json.page_profile){
          isImageExist(json.page_profile, function(exists) {
            var brandIcon = exists ? json.page_profile : NO_LOGO;
            jQuery("#brandicon").attr("src", brandIcon);
          });
        } else {
          jQuery("#brandicon").attr("src", NO_LOGO);
        }
        jQuery(".brandname").html(json.page_name);
        jQuery("#brandcat").html("Messenger Bot");
        let brandName = json.page_name ? json.page_name : "ChatApp";
        $$(`.page .navbar .page_title .title`).html(brandName);
        if (
          json.welcome_message &&
          json.welcome_message.length > 0 &&
          json.welcome_message.includes("{{user_first_name}}")
        ) {
          let welcomeMessage =
            name ||
            localStorage.getItem(`${brandID}-name`) ||
            localStorage.getItem(`userdata-${brandID}-name`)
              ? json.welcome_message.replace(
                  "{{user_first_name}}",
                  name ||
                    localStorage.getItem(`${brandID}-name`) ||
                    localStorage.getItem(`userdata-${brandID}-name`)
                )
              : json.welcome_message.replace("{{user_first_name}}", "friend");
          jQuery(`#welcomemessage`).html(welcomeMessage);
        } else {
          json.welcome_message.replace("{{user_first_name}}", "friend");
        }
        let subsequentCall = false;
        let visitedPage = localStorage.getItem("visitedPage");
        if (visitedPage && visitedPage.includes(brand_id)) {
          // console.log(`Already Visted.`);
          $("#getstart").text("Welcome Back");
          keyword_type = "exist_start";
          subsequentCall = true;
        } else {
          visitedPage = visitedPage ? visitedPage.split(",") : [];
          visitedPage.push(brand_id);
          localStorage.setItem("visitedPage", visitedPage.toString());
          $("#getstart").text("Get Started");
        }
        if (user) {          
          jQuery("#getstart").attr(
            "onclick",
            "processfb('" +
              keyword_type +
              "','" +
              bid +
              "', " +
              subsequentCall +
              ");"
          );
        } else {
          $(`#getstart`).attr(`onclick`, `selectcountry();`)
        }
        app.popup.open(".popup-push");
        jQuery(".botui-app-container").data("pageid", page_id);
  
        app.preloader.hideIn(".app");
        // $(".botui-container").empty();
        // localStorage.clear();
        window.localStorage.setItem("INTRO_SLIDE", true);
        localStorage.setItem("brand_code", pageid);
        localStorage.setItem("fb_bot", true);
        if (
          mainView.router.currentRoute.url != "/" &&
          mainView.router.currentRoute.url != "/android_asset/www/index.html"
        ) {
          mainView.router.back();
        }
      })
      .catch(function (err) {
        console.log(err.xhr)
        console.log(err.status)
        console.log(err.message)
      });
    } else {
      homeBot.message.removeAll();
      query.get(bid).then((data) => {
        json = JSON.parse(JSON.stringify(data));
        json = json.payload_json;
        let brandName = json.brand_name !== 'userProfile' ? json.brand_name ? json.brand_name : "ChatApp" : localStorage.getItem(`remoteUser`);
        $$(`.page .navbar .page_title .title`).html(brandName);
        // localStorage.setItem("theteajunctionsaket-jsondata", JSON.stringify(json1));
  
        if (json.is_persist_menu) {
          $("a.float").hasClass("sheet-open")
            ? ""
            : $("a.float").addClass("sheet-open");
          $("a.float").data("sheet") == ".my-sheet-swipe-to-step"
            ? ""
            : $("a.float").data("sheet", ".my-sheet-swipe-to-step");
          // let persistMenu = json.payloads.persist_menu.messages[0];
          let persistMenu = json.payloads.explore.messages[1];
          let prMenu = "";
          $("#prmenu").html("");
          persistMenu.action.button.action.forEach((value, key) => {
            if(value.type) {
              prMenu +=
                value.type === "postback"
                  ? `<li><a href="#" onclick="offline('${value.value}')" class="panel-close sheet-close">${value.text}</a></li>`
                  : `<li><a href="#" onclick="openUrl('${value.value}')" class="panel-close sheet-close">${value.text}</a></li>`;
            } else {
              prMenu += `<li><a href="#" onclick="offline('${value.value}')" class="panel-close sheet-close">${value.text}</a></li>`;
            }
          });
          prMenu += `<li><a href="#" onclick="offline('manageNotification')" class="panel-close sheet-close">Manage Notification</a></li>`;
          $("#prmenu").html(prMenu);
        } else {
          $("a.float").removeClass("sheet-open");
          $("a.float").removeData("sheet");
          $("#prmenu").html("");
        }
        
        if(json.brand_id === `chatappstore`){         
          jQuery("#brandicon").attr("src", HOME_PAGE_LOGO);
        } else if(json.brand_icon){
          isImageExist(json.brand_icon, function(exists) {
            var brandIcon = exists ? json.brand_icon : NO_LOGO;
            jQuery("#brandicon").attr("src", brandIcon);
          });
        } else {
          jQuery("#brandicon").attr("src", NO_LOGO);
        }

        jQuery(".brandname").html(brandName);
        jQuery("#brandcat").html(json.brand_category);

        var bQuery = new Parse.Query("brandData");
        bQuery.equalTo("brand_id", json.brand_id);
        bQuery
          .find()
          .then(function (results) {
            jQuery.each(results, function (key, value) {
              var json = JSON.parse(JSON.stringify(value));
              jQuery("#brandtag").html(json.brand_tagline);
              // if(json.brand_id === `chatappstore`){                
              //   jQuery("#brandcover").css("background-image", `url(${NO_COVER})`);
              // } else 
              if(json.brand_cover){
                isImageExist(json.brand_cover, function(exists) {
                  var brandCover = exists ? json.brand_cover : NO_COVER;
                  jQuery("#brandcover").css("background-image", "url(" + brandCover + ")");
                });
              } else {
                jQuery("#brandcover").css("background-image", `url(${NO_COVER})`);
              }
            });
          });
        var sliders = json.sliders;
        jQuery(".swiper-container-brand").hide();

        // console.log(sliders);
        /*if(sliders) {
          var brandswiper = app.swiper.create(".swiper-container-brand", {
            init: false,
            speed: 400,
            spaceBetween: 10,
            centeredSlides: true,
            pagination: {
              el: ".swiper-pagination",
              type: "bullets",
            },
          });
        
          jQuery(".swiper-container-brand").show();
          var isSwiper = false;
          jQuery.each(sliders, function (s) {
            if(sliders[s].image!='') {
              isSwiper = true;
              jQuery(".brandslider").append(
                '<div class="swiper-slide"><a href="#" class="item-title" class="col"><img src="' +
                  sliders[s].image + '" style="width:100%;height:100%;"></a></div>'
              );
            }
          });
        
          setTimeout(function () {
            console.log(isSwiper);
            if(isSwiper) {
              brandswiper.init();
            }
          }, 1500);
        } else {
          jQuery(".swiper-container-brand").hide();
        }*/

        let visitedPage = localStorage.getItem("visitedPage");
        if (visitedPage && visitedPage.includes(brand_id)) {
          $("#getstart").text("Welcome Back");        
          // console.log(typeof payload);
          if (typeof payload === `string`) {
            let clickPayload = payload ? payload : 'exist_start';        
            jQuery("#getstart").attr("onclick", `offline('${clickPayload}','');`);
          } else if (typeof payload === 'object') {
            $(`#getstart`).attr(`onclick`, `addmessage(${JSON.stringify(payload)})`);
          } else {
            let clickPayload = payload ? payload : 'exist_start';        
            jQuery("#getstart").attr("onclick", `offline('${clickPayload}','');`);
          }

        } else {
          visitedPage = visitedPage ? visitedPage.split(",") : [];
          visitedPage.push(brand_id);
          localStorage.setItem("visitedPage", visitedPage.toString());
          $("#getstart").text("Get Started");        
          // console.log(typeof payload);
          if (typeof payload === 'string') {
            let clickPayload = payload ? payload : 'get_started';
            jQuery("#getstart").attr("onclick", `offline('${clickPayload}','');`);
          } else if (typeof payload === 'object') {
            $(`#getstart`).attr(`onclick`, `addmessage(${JSON.stringify(payload)})`);
          } else {
            let clickPayload = payload ? payload : 'get_started';
            jQuery("#getstart").attr("onclick", `offline('${clickPayload}','');`);
          }
        }
        payload === `autoVerifyWithWhatsApp` && $(`#getstart`).on(`click`, (e) => {
          app.preloader.show();          
          firebaseAuth();
        });
        app.popup.open(".popup-push");
        // var element = document.getElementById("hello-world");
        // element.setAttribute("data-url", "https://socialrecharge.com/chat/api.php?f="+bid);
        // jQuery("#hello-world").data('url', 'https://socialrecharge.com/chat/api.php?f='+bid);
        // jQuery(".botui-app-container").attr("data-url", "https://socialrecharge.com/chat/api.php?f="+bid);
        if (json.brand_cover && json.bot_bg == "yes") {
          jQuery(".botui-app-container").attr(
            "style",
            "background-image:url(" + json.brand_cover + ");"
          );
          setTimeout(function () {
            jQuery(".botui-container").attr(
              "style",
              "background-color: transparent !important;"
            );
          }, 1000);
        } else {
          jQuery(".botui-app-container").css("background-image", "none");
        }
        jQuery(".botui-app-container").data("code", bid);
        jQuery(".botui-app-container").data("pageid", "");
  
        app.preloader.hideIn(".app");
  
        // $(".botui-container").empty();
        // localStorage.clear();
        // window.localStorage.setItem("INTRO_SLIDE", true);
        if (
          mainView.router.currentRoute.url != "/" &&
          mainView.router.currentRoute.url != "/android_asset/www/index.html"
        ) {
          mainView.router.back();
        }
        localStorage.setItem("brand_code", bid);
        localStorage.setItem("fb_bot", false);
      }).catch(error => {
        /** * 209 FOR INVALID SESSION TOKEN * 206 FOR SESSION IS MISSING */
        if (
          error.code === 209 ||
          error.code === 206 ||
          error.message === `Invalid session token`
        ) {
          logout();
          return;
        }
      });
    }
  }
  function closePopup() {
    $$(`.page .navbar .page_title .title`).html($$(`.page .navbar .prev_page_title .title`).html());
  }
  function isImageExist(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true); };
    img.onerror = function() { callback(false); };
    img.src = url;
  }
  function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }
  
  function processfb(payload, pageid, subsequentcall) {
    // console.log(payload);
    var myPhotoBrowserPopup = "";
    if (
      payload == "get_started" ||
      payload == "get-started" ||
      payload == "exist_start"
    ) {
      if (localStorage.getItem("brand_code") && localStorage.getItem("vmn")) {
        var dQuery = new Parse.Query("userChatbot");
        dQuery.equalTo("mobile", localStorage.getItem("vmn"));
        var bQuery = new Parse.Query("userChatbot");
        bQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
        var mainQuery = Parse.Query.and(dQuery, bQuery);
        mainQuery.find().then(function (result) {
          // console.log(result); console.log(result.length);
          if (result.length < 1) {
            var fb_bot = localStorage.getItem("fb_bot");
            var brandQuery = new Parse.Query("brandData");
            if(fb_bot && fb_bot == "true") {
              brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
            } else {
              brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
            }
            brandQuery.first().then(function (brandResult) {
              if(brandResult.get(`type`) === `brand`){
                // jQuery.each(brandResults, function (key, value) {
                  // var json = JSON.parse(JSON.stringify(value));
                  // json.brand_name, json.brand_id
                  var userQuery = new Parse.Object("userChatbot");
                  userQuery.set("brand_id", brandResult.get(`brand_id`));
                  userQuery.set("brand_icon", brandResult.get(`brand_icon`));
                  userQuery.set("brand_code", localStorage.getItem("brand_code"));
                  userQuery.set("brand_name", brandResult.get(`brand_name`));
                  userQuery.set("brand_category", brandResult.get(`brand_category`));
                  userQuery.set("type", brandResult.get(`type`));
                  userQuery.set("device_uuid", app.device.uuid);
                  userQuery.set("mobile", localStorage.getItem("vmn"));
                  userQuery.save();
                // });
              }
            });
          } else if (result.length == 1) {
            // console.log("id :", result[0].id);
            var userChat = Parse.Object.extend("userChatbot");
            var userQuery = new Parse.Query(userChat);
            userQuery.equalTo("objectId", result[0].id);
            userQuery.first().then(function (bresult) {
              bresult.save();
            });
          }
        });
      }
    }
    // console.log(
    //   `Subsequentcall : ${subsequentcall} and type is ${typeof subsequentcall}`
    // );
    var dta;
    if (!subsequentcall || payload === "exist_start") fetchPersistentMenu(pageid);
    subsequentcall
      ? (dta = {
          page_id: pageid,
          postback_id: payload,
        })
      : (dta = {
          page_id: pageid,
          keyword_type: payload,
        });
    // console.log(dta);
    var url;
    subsequentcall
      ? (url = "https://messengerf.com/Api/get_all_payloads")
      : (url = "https://messengerf.com/Api/get_all_messenger_bot");
    localStorage.setItem("fbpage", pageid);
    jQuery(
      "body .my-sheet-swipe-to-step .sheet-modal-inner .sheet-modal-swipe-step .padding-bottom a"
    ).data("pageid", pageid);
    jQuery.ajax({
      type: "GET",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: dta,
      dataType: "json",
      success: (result) => {
        var serverdata =
          result.payloads_info[0].bot_name === "GET STARTED" &&
          result.payloads_info[0].is_template === "0"
            ? result.payloads_info[0].message
            : result.payloads_info[0].template_jsoncode;
        var fda = JSON.parse(serverdata);
        // console.log(`fda: `, fda);
        jQuery.each(fda, function (index, val) {
          if (val.message.attachment) {
            var msgAttach = val.message.attachment;
            if (val.message.template_type == "audio") {
              setTimeout(function () {
                var result = {
                  add: {
                    type: "html",
                    content:
                      '<audio controls autoplay><source src="' +
                      msgAttach.payload.url +
                      '" type="audio/mpeg"></audio>',
                  },
                };
                newMessage(result);
              }, index * 1000);
            } else if (msgAttach.payload.template_type == "receipt") {
              // console.log(msgAttach.payload.elements);
              var receipt = JSON.stringify(msgAttach.payload);
              localStorage.setItem("receipt_payload", receipt);
              var myMsg =
                '<div class="list media-list" style="margin:0px;"><div class="card" onclick="openReceipt()" style="border-radius:20px;width:90%;left:6%;float:left;">' +
                '<div class="card-header">Order Confirmation</div>' +
                '<div class="card-content"><ul>';
              var eleReceipt = msgAttach.payload.elements;
              if (eleReceipt) {
                jQuery.each(eleReceipt, function (r) {
                  myMsg +=
                    "<li>" +
                    '<div class="item-content">' +
                    '<div class="item-media">' +
                    '<img src="' +
                    eleReceipt[r].image_url +
                    '" style="min-height:80px;width:85px;max-width:inherit;border-radius:initial;">' +
                    "</div>" +
                    '<div class="item-inner">' +
                    '<div class="item-title-row">' +
                    '<div class="item-title">' +
                    eleReceipt[r].title +
                    "</div>" +
                    "</div>";
                  if (eleReceipt[r].subtitle) {
                    myMsg +=
                      '<div class="item-text">' +
                      eleReceipt[r].subtitle +
                      "</div>";
                  }
                  myMsg += "</div>" + "</div>" + "</li>";
                });
              }
              myMsg +=
                '</ul><div class="card-content-inner">' +
                '<p style="margin:5px 15px;line-height:20px;"><span style="font-size:13px;">Paid with</span><br><b>' +
                msgAttach.payload.payment_method +
                "</b></p>";
              if (msgAttach.payload.address) {
                myMsg +=
                  '<p style="margin:5px 15px;line-height:20px;"><span style="font-size:13px;">Ship to</span><br><b>' +
                  msgAttach.payload.address.street_1 +
                  ", " +
                  msgAttach.payload.address.city +
                  ", " +
                  msgAttach.payload.address.state +
                  ", " +
                  msgAttach.payload.address.country +
                  " " +
                  msgAttach.payload.address.postal_code +
                  "</b></p>";
              }
              myMsg +=
                '</div></div><div class="card-footer">Total <b style="top:12px;right:15px;position:absolute;">' +
                msgAttach.payload.summary.total_cost +
                "</b></div>" +
                "</div></div>";
              var result = {
                add: {
                  type: "html",
                  content: myMsg,
                },
              };
              newMessage(result);
            } else if (val.message.template_type == "carousel") {
              var carouselEle = val.message.attachment.payload.elements;
              nativeCarousel(carouselEle, "fb", pageid);
            } else if (val.message.template_type == "text_with_buttons") {
              setTimeout(function () {
                var buttons = val.message.attachment.payload.buttons;
                var butt = [];
                jQuery.each(buttons, function (q) {
                  if (buttons[q].type == "web_url") {
                    //myMsg += '<a href="#" class="link" onClick="openUrl(\''+element[i].buttons[j].url+'\');" style="text-transform:uppercase;">' + element[i].buttons[j].title + '</a>';
                    var bUrl = buttons[q].url;
                    if (bUrl.indexOf(" 91") >= 0) {
                      var buttUrl = bUrl.replace(" 91", "+91");
                    } else {
                      var buttUrl = bUrl;
                    }
                    butt.push({
                      icon: "check",
                      text: buttons[q].title,
                      value: buttUrl,
                    });
                  } else if (buttons[q].type == "phone_number") {
                    myMsg +=
                      '<a href="tel:' +
                      buttons[q].payload +
                      '" class="link external" style="text-transform:uppercase;display:inline-table;">' +
                      element[i].buttons[j].title +
                      "</a>";
                  } else if (buttons[q].type == "postback") {
                    //myMsg += '<a href="#" class="link" onClick="offline(\'' + element[i].buttons[j].payload + '\');" style="text-transform:uppercase;">' + element[i].buttons[j].title + '</a>';
                    butt.push({
                      icon: "check",
                      text: buttons[q].title,
                      value: buttons[q].payload,
                    });
                  }
                });
                // console.log("Butt : ", butt);
                var value = {
                  action: {
                    button: {
                      action: butt,
                    },
                  },
                  medium: "fb",
                  pageid: pageid,
                };
                addAction(value);
              }, index * 1000);
            } else if (msgAttach.payload.template_type == "media") {
              var eleMedia = msgAttach.payload.elements;
              var myMsg = "";
              if (eleMedia) {
                jQuery.each(eleMedia, function (m) {
                  if (eleMedia[m].media_type == "image") {
                    var imgUrl = eleMedia[m].url;
                    myPhotoBrowserPopup = app.photoBrowser.create({
                      photos: [imgUrl],
                      zoom: 400,
                      theme: "dark",
                      backLinkText: "Back",
                      type: "popup",
                    });
                    myMsg +=
                      '<div class="card demo-card-header-pic" style="border-radius:25px;min-width:275px;box-shadow:none;">' +
                      '<div style="background-image:url(' +
                      eleMedia[m].url +
                      ');border-radius:25px 25px 0px 0px;height:60vw;" valign="bottom" class="card-header color-white no-border pb-popup"></div>';
                  } else if (eleMedia[m].media_type == "video") {
                    myMsg +=
                      '<div class="card demo-card-header-pic" style="border-radius:25px;min-width:275px;box-shadow:none;">' +
                      '<div class="card-content">' +
                      '<iframe width="100%" style="border:#000 1px solid;border-radius:25px 25px 0px 0px;height:40vw;" src="' +
                      eleMedia[m].url +
                      '" frameborder="0" allowfullscreen></iframe>' +
                      "</div>";
                  }
                  myMsg += '<div class="card-footer">';
                  var eleButton = eleMedia[m].buttons;
                  if (eleButton) {
                    jQuery.each(eleButton, function (j) {
                      if (eleMedia[m].buttons[j].type == "web_url") {
                        var bUrl = eleMedia[m].buttons[j].url;
                        if (bUrl.indexOf(" 91") >= 0) {
                          var buttUrl = bUrl.replace(" 91", "+91");
                        } else {
                          var buttUrl = bUrl;
                        }
                        myMsg +=
                          '<a href="#" class="link external" onClick="openUrl(\'' +
                          buttUrl +
                          '\');" style="text-transform:uppercase;">' +
                          eleMedia[m].buttons[j].title +
                          "</a>";
                      } else if (eleMedia[m].buttons[j].type == "phone_number") {
                        myMsg +=
                          '<a href="tel:' +
                          eleMedia[m].buttons[j].payload +
                          '" class="link external" style="text-transform:uppercase;display:inline-table;">' +
                          eleMedia[m].buttons[j].title +
                          "</a>";
                      }
                    });
                  }
                  myMsg += "</div></div>";
                });
              }
              var result = {
                add: {
                  type: "html",
                  content: myMsg,
                },
              };
              newMessage(result);
            } else if (
              msgAttach.payload.template_type == "airline_boardingpass"
            ) {
              var bPass = msgAttach.payload.boarding_pass;
              // console.log(bPass);
              var myMsg =
                '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#0085ff;color:#fff;border-radius:20px;min-width:275px;">' +
                '<div class="card-header" style="border-bottom:#fff 0.5px solid;margin:0px 10px;font-size:13px;padding:10px 0px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="' +
                bPass[0].header_image_url +
                '" width="36" height="36"></div><div style="width:30%;float:left;">Terminal<br>' +
                bPass[0].flight_info.departure_airport.terminal +
                '</div><div align="center" style="width:20%;float:right;">Gate<br>' +
                bPass[0].flight_info.departure_airport.gate +
                "</div></div></div>" +
                '<div class="card-content"><table width="100%" style="padding:5px 10px;">' +
                '<tr><th align="left"><span style="font-size:13px;">Passengers</span></th><th align="right"><span style="font-size:13px;">Seat</span></th></tr>';
              // var sfields = bPass[0].secondary_fields;
              jQuery.each(bPass, function (p) {
                myMsg +=
                  '<tr><td><b style="font-size:13px;">' +
                  bPass[p].passenger_name +
                  '</b></td><td align="right"><b>' +
                  bPass[p].seat +
                  "</b></td></tr>";
              });
              myMsg += "</table>";
              if (bPass[0].flight_info) {
                myMsg +=
                  '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:22%;"><span style="font-size:13px;">Flight</span><br><b>' +
                  bPass[0].flight_info.flight_number +
                  "</b></p>";
              }
              myMsg +=
                '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;">Departs</span><br><b>' +
                bPass[0].flight_info.flight_schedule.departure_time +
                "</b></p>";
              myMsg +=
                '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;">Arrive</span><br><b>' +
                bPass[0].flight_info.flight_schedule.arrival_time +
                "</b></p>";
              myMsg +=
                '<table width="100%" style="padding:0px 10px;">' +
                '<tr><th align="left"><span style="font-size:13px;">' +
                bPass[0].flight_info.departure_airport.city +
                '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;">' +
                bPass[0].flight_info.arrival_airport.city +
                "</span></th></tr>" +
                '<tr><td><span style="font-size:40px;">' +
                bPass[0].flight_info.departure_airport.airport_code +
                '</span></td><td align="center"><img src="' +
                bPass[0].logo_image_url +
                '" width="64" height="64"></td><td align="right"><span style="font-size:40px;">' +
                bPass[0].flight_info.arrival_airport.airport_code +
                "</span></td></tr>" +
                "</table>";
              myMsg +=
                '</div><div class="card-footer" style="background:#fff;width:100%;"><a href="#" class="link" onclick="openBoarding()">View Boarding Pass</a></div>' +
                "</div></div>";
              var result = {
                add: {
                  type: "html",
                  content: myMsg,
                },
              };
              newMessage(result);
            } else if (msgAttach.payload.template_type == "airline_checkin") {
              var bPass = msgAttach.payload;
              // console.log(bPass);
              var myMsg =
                '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
                '<div class="card-header" style="background:#0085ff;color:#fff;font-size:13px;padding:10px;border-radius:20px 20px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36"></div><div align="center" style="width:42%;float:right;">Booking Number<br><b style="line-height:22px;float:right;">' +
                bPass.pnr_number +
                "</b></div></div></div>" +
                '<div class="card-content">';
              if (bPass.flight_info) {
                myMsg +=
                  '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:22%;"><span style="font-size:13px;color:#999;">Flight</span><br><b>' +
                  bPass.flight_info[0].flight_number +
                  "</b></p>";
                myMsg +=
                  '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Boards</span><br><b>' +
                  bPass.flight_info[0].flight_schedule.boarding_time +
                  "</b></p>";
                myMsg +=
                  '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Arrives</span><br><b>' +
                  bPass.flight_info[0].flight_schedule.arrival_time +
                  "</b></p>";
                myMsg +=
                  '<table width="100%" style="padding:0px 10px;">' +
                  '<tr><th align="left"><span style="font-size:13px;color:#999;">' +
                  bPass.flight_info[0].departure_airport.city +
                  '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;color:#999;">' +
                  bPass.flight_info[0].arrival_airport.city +
                  "</span></th></tr>" +
                  '<tr><td><span style="font-size:40px;color:#0085ff;">' +
                  bPass.flight_info[0].departure_airport.airport_code +
                  '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;color:#0085ff;">' +
                  bPass.flight_info[0].arrival_airport.airport_code +
                  "</span></td></tr></table>";
              }
              myMsg +=
                '</div><div class="card-footer" style="background:#fff;width:100%;font-weight:bold;padding-left:40%;"><a href="#" class="link">Check In</a></div>' +
                "</div></div>";
              var result = {
                add: {
                  type: "html",
                  content: myMsg,
                },
              };
              newMessage(result);
            } else if (msgAttach.payload.template_type == "airline_update") {
              var bPass = msgAttach.payload;
              // console.log(bPass);
              var myMsg =
                '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
                '<div class="card-header" style="font-size:13px;padding:10px;border-radius:20px 20px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36" style="border-radius:50%;"></div><div align="right" style="width:42%;float:right;color:#999;">Flight Status<br><b style="line-height:22px;float:right;color:#e8452b;font-size:16px;text-transform:capitalize;">' +
                bPass.update_type +
                "</b></div></div></div>" +
                '<div class="card-content">';
              if (bPass.update_flight_info) {
                myMsg +=
                  '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:22%;"><span style="font-size:13px;color:#999;">Flight</span><br><b>' +
                  bPass.update_flight_info.flight_number +
                  "</b></p>";
                myMsg +=
                  '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Departs</span><br><b style="color:#e8452b;">' +
                  bPass.update_flight_info.flight_schedule.departure_time +
                  "</b></p>";
                myMsg +=
                  '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Arrives</span><br><b style="color:#e8452b;">' +
                  bPass.update_flight_info.flight_schedule.arrival_time +
                  "</b></p>";
                myMsg +=
                  '<table width="100%" style="padding:0px 10px;">' +
                  '<tr><th align="left"><span style="font-size:13px;color:#999;">' +
                  bPass.update_flight_info.departure_airport.city +
                  '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;color:#999;">' +
                  bPass.update_flight_info.arrival_airport.city +
                  "</span></th></tr>" +
                  '<tr><td><span style="font-size:40px;color:#0085ff;">' +
                  bPass.update_flight_info.departure_airport.airport_code +
                  '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;color:#0085ff;">' +
                  bPass.update_flight_info.arrival_airport.airport_code +
                  "</span></td></tr></table>";
              }
              myMsg += "</div></div></div>";
              var result = {
                add: {
                  type: "html",
                  content: myMsg,
                },
              };
              newMessage(result);
            } else if (msgAttach.payload.template_type == "airline_itinerary") {
              var bPass = msgAttach.payload;
              // console.log(bPass);
              var myMsg =
                '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
                '<div class="card-header" style="background:#0085ff;color:#fff;border-radius:20px 20px 0px 0px;font-size:13px;padding:10px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36"></div><div align="right" style="width:42%;float:right;">Booking Number<br><b style="line-height:22px;">' +
                bPass.pnr_number +
                "</b></div></div></div>" +
                '<div class="card-content"><table width="100%" style="padding:5px 10px;">' +
                '<tr><th align="left"><span style="font-size:13px;color:#999;">Passengers</span></th></tr>';
              // var sfields = bPass[0].secondary_fields;
              jQuery.each(bPass.passenger_info, function (p) {
                myMsg +=
                  '<tr><td><b style="font-size:13px;">' +
                  bPass.passenger_info[p].name +
                  "</b></td></tr>";
              });
              myMsg += "</table>";
              jQuery.each(bPass.flight_info, function (f) {
                myMsg +=
                  '<hr style="margin:0px 10px;"><p style="margin:5px 10px;line-height:20px;float:left;width:90%;"><span style="font-size:13px;color:#999;">' +
                  bPass.flight_info.length +
                  " Stops</span><br><b>" +
                  bPass.flight_info[f].flight_schedule.departure_time +
                  "</b></p>";
                myMsg +=
                  '<table width="100%" style="padding:0px 10px;">' +
                  '<tr><th align="left"><span style="font-size:13px;">' +
                  bPass.flight_info[f].departure_airport.city +
                  '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;">' +
                  bPass.flight_info[f].arrival_airport.city +
                  "</span></th></tr>" +
                  '<tr><td><span style="font-size:40px;">' +
                  bPass.flight_info[f].departure_airport.airport_code +
                  '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;">' +
                  bPass.flight_info[f].arrival_airport.airport_code +
                  "</span></td></tr>" +
                  "</table>";
              });
              myMsg +=
                '<hr style="margin:0px 10px;"><p style="margin:10px;width:92%;"><span style="font-size:13px;color:#999;">Total</span><b style="float:right;">' +
                bPass.currency +
                " " +
                bPass.total_price +
                "</b></p>";
              myMsg +=
                '</div><div class="card-footer" style="background:#fff;width:100%;"><a href="#" onclick="viewItinerary()" class="link">View Details</a></div>' +
                "</div></div>";
              var result = {
                add: {
                  type: "html",
                  content: myMsg,
                },
              };
              newMessage(result);
            } else if (val.message.template_type == "generic_template") {
              var carouselEle = val.message.attachment.payload.elements;
              nativeCarousel(carouselEle, "fb", pageid);
            } else {
              // console.log(val.message.attachment.payload);
            }
          } else {
            if(val.message.template_type == "User_Input_Flow") {
              var campaign_id = val.message.flow_campaign_id;
              var fb_bot = localStorage.getItem("fb_bot");
              const brandQuery = new Parse.Query("brandData");
              if(fb_bot && fb_bot == "true") {
                brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
              } else {
                brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
              }
              brandQuery
              .find()
              .then(function (results) {
                if(results.length > 0){  
                  jQuery.each(results, async function (key, value) {
                    var jsonRes = JSON.parse(JSON.stringify(value));
                    var apikey = jsonRes.api_key;
                    // console.log(apikey);
                    jQuery.ajax({
                      type: "GET",
                      url: "https://messengerf.com/apiv2/get_flow_campaign_info/?api_key="+apikey+"&flow_campaign_id="+campaign_id,
                      success: (result) => {
                        console.log(result.questions);
                        var bcode = localStorage.getItem('brand_code');
                        localStorage.setItem(bcode+'_questions', JSON.stringify(result.questions));
                          var next = "0";
                          nextQuestion(next);
                      }
                    });
                  });
                }
              });
            } else if (val.message.template_type == "text") {
              setTimeout(function () {
                var textmessage = {
                  add: {
                    content: val.message.text,
                  },
                };
                newMessage(textmessage);
              }, index * 1000);
            } else if (val.message.template_type == "quick_reply") {
              setTimeout(function () {
                var textmessage = {
                  add: {
                    content: val.message.text,
                  },
                };
                newMessage(textmessage);
                var quickButton = val.message.quick_replies;
                if (quickButton) {
                  var vmn = window.localStorage.getItem("vmn");
                  var butt = [];
                  jQuery.each(quickButton, function (q) {
                    if (quickButton[q].content_type == "user_phone_number") {
                      var vmn = window.localStorage.getItem("vmn");
                      if (vmn) {
                        butt.push({ icon: "phone", text: "Call", value: vmn });
                      }
                    } else if (quickButton[q].content_type == "user_email") {
                      var email = window.localStorage.getItem(brandID + "-email");
                      if (email) {
                        butt.push({
                          icon: "envelope",
                          text: "Email",
                          value: email,
                        });
                      }
                    } else {
                      butt.push({
                        icon: "check",
                        text: quickButton[q].title,
                        value: quickButton[q].payload,
                      });
                    }
                  });
                  // console.log("Butt : ", butt);
                  var value = {
                    action: {
                      button: {
                        action: butt,
                      },
                    },
                    medium: "fb",
                    pageid: pageid,
                  };
                  addAction(value);
                }
              }, 1000);
            }
          }
        });
      },
    });
  }

  function nextQuestion(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var textmessage = {
      add: {
        content: questions[next].question,
      },
    };
    newMessage(textmessage);
    if(questions[next].reply_type == 'Date') {
      showAppointmentDate(next);
      } else if(questions[next].reply_type == 'Time') {
      showAppointmentTime(next);
      } else if(questions[next].reply_type == 'Phone') {
      question_Phone(next);
    } else if(questions[next].reply_type == 'Number') {
      question_Number(next);
    } else if(questions[next].reply_type == 'URL') {
      question_URL(next);
    } else if(questions[next].reply_type == 'File') {
      question_File(next);
    } else if(questions[next].reply_type == 'Image') {
      question_Image(next);
    } else if(questions[next].reply_type == 'Video') {
      question_Video(next);
    } else if(questions[next].reply_type == 'Datetime') {
      question_Datetime(next);
    }
  }
  function setFlowData(next, setValue) {
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var mobile = localStorage.getItem('vmn');
    var phone = mobile.split('+');
    var fname = localStorage.getItem('name') ? localStorage.getItem('name') : '';
    var lname = '';
    var email_id = localStorage.getItem('email') ? localStorage.getItem('email') : '';
    var id = questions[next].id;
    var campaign_id = questions[next].flow_campaign_id;
    var fb_bot = localStorage.getItem("fb_bot");
    const brandQuery = new Parse.Query("brandData");
    if(fb_bot && fb_bot == "true") {
      brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
    } else {
      brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
    }
    brandQuery
    .find()
    .then(function (results) {
      if(results.length > 0){  
        jQuery.each(results, async function (key, value) {
          var jsonRes = JSON.parse(JSON.stringify(value));
          var apikey = jsonRes.api_key;
          var fb_page_id = jsonRes.fb_page_id;
          var page_table_id = jsonRes.brand_code;
          // console.log(fb_page_id);
          if(next == 0) {
            if (localStorage.getItem("set_subscriber") != "success") {
              var mUrl = "https://messengerf.com/apiv2/set_subsriber/?api_key="+apikey+"&page_table_id="+page_table_id+"&page_id="+fb_page_id+"&subscriber_id="+phone[1]+"&first_name="+fname+"&last_name="+lname;
              jQuery.ajax({
                type: "GET",
                url: mUrl,
                success: (result) => {
                  // console.log(result);
                  localStorage.setItem("set_subscriber", "success");
                }
              });
            }
          }
          jQuery.ajax({
            type: "GET",
            url: "https://messengerf.com/apiv2/set_single_subscriber_flow_info/?api_key="+apikey+"&flow_campaign_id="+campaign_id+"&subscriber_id="+phone[1]+"&page_id="+fb_page_id+"&question_id="+id+"&user_answer="+setValue+"&fb_message_id=Webview%20Submit",
            success: (result) => {
              // console.log(result);
            }
          });
        });
      }
    });
  }
  function showAppointmentDate(next) {
    var save = "appointDate";
    var fdate = new Date();
    fdate.setDate(fdate.getDate() + 1);
    var fromdate = new Date();
    fromdate.setDate(fromdate.getDate() + 2);
    var fb_bot = localStorage.getItem("fb_bot");
    const brandQuery = new Parse.Query("brandData");
    if(fb_bot && fb_bot == "true") {
      brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
    } else {
      brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
    }
    brandQuery
    .find()
    .then(function (results) {
      if(results.length > 0){  
        jQuery.each(results, async function (key, value) {
          var jsonRes = JSON.parse(JSON.stringify(value));
          var resDays = jsonRes.res_days ? jsonRes.res_days : "30";
          var todate = new Date();
          todate.setDate(todate.getDate() + parseInt(resDays));
          var htmlElement = `<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper dateswiper"><button class="dateui" onClick="selectAppointmentDate('`+moment().format('YYYY-MM-DD')+`', '`+save+`', '`+next+`');"> Today <br> `+moment().format('DD MMM')+`</button><button class="dateui" onClick="selectAppointmentDate('`+moment(fdate).format('YYYY-MM-DD')+`', '`+save+`', '`+next+`');"> Tomorrow <br> `+moment(fdate).format('DD MMM')+`</button>`;
          for (var d = fromdate; d <= todate; d.setDate(d.getDate() + 1)) {
            htmlElement += `<button class="dateui" onClick="selectAppointmentDate('`+moment(d).format('YYYY-MM-DD')+`', '`+save+`', '`+next+`');"> `+moment(d).format('dddd')+` <br> `+ moment(d).format('DD MMM') +`</button>`;
          }
          htmlElement += `</div></div>`;

          var result = {
            add: {
              type: "html",
              content: htmlElement,
            },
          };
          newMessage(result);
        });
      }
    });
  }
  function selectAppointmentDate(dateValue, saveval, next){
      if (saveval) saveValue(saveval, dateValue);
      if (saveval) saveValue("appointdate_"+localStorage.getItem("brand_code"), dateValue);
      // homeBot.message.remove();
      homeBot.message
      .add({
        delay: 1000,
        loading: true,
        content: `You have selected ${dateValue}.`,
      })
      .then(function (index) {
        setFlowData(next, dateValue);
        var nextPayload = parseInt(next)+1;
        nextQuestion(nextPayload);
      });
  }
  function showAppointmentTime(next) {
    let today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }T"11:00:00"`;
    // console.log(`today: `, today);
    var fb_bot = localStorage.getItem("fb_bot");
    const brandQuery = new Parse.Query("brandData");
    if(fb_bot && fb_bot == "true") {
      brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
    } else {
      brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
    }
    brandQuery
    .find()
    .then(function (results) {
      if(results.length > 0){  
        jQuery.each(results, async function (key, value) {
          var jsonRes = JSON.parse(JSON.stringify(value));
          var startTime = jsonRes.res_starttime ? jsonRes.res_starttime : "11:00:00";
          var endTime = jsonRes.res_endtime ? jsonRes.res_endtime : "22:00:00";
          let evalMinTime = (startTime).split(":");
          let evalMaxTime = (endTime).split(":");
          let minTime = startTime;
          let maxTime = endTime;
          var save = "appointTime";
          var quarterHours = ["00", "30"];
          var htmlElement = '<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper timeswiper">';
          var appointdate = localStorage.getItem(`userdata-${brandID}-appointdate_`+localStorage.getItem("brand_code"));
          var todayDate = moment().format('YYYY-MM-DD');
          var startTime = (appointdate == todayDate) ? (parseInt(moment().format('H')) + 1) : evalMinTime[0];
          for(var i = startTime; i <= evalMaxTime[0]; i++){
            for(var j = 0; j < 2; j++){
              var dt = i + ":" + quarterHours[j];
              var time = moment(dt, ["h:mm"]).format("h:mm A");
              var timeVal = moment(dt, ["h:mm"]).format("HH:mm");
              htmlElement += `<button class="timeui" onClick="selectAppointmentTime('`+timeVal+`', '`+save+`', '`+next+`');">`+time+`</button>`;
            }
          }
          htmlElement += `</div></div>`;

          var result = {
            add: {
              type: "html",
              content: htmlElement,
            },
          };
          newMessage(result);
        });
      }
    });
  }
  function selectAppointmentTime(timeValue, save, next) {
      var dateTimeValue = localStorage.getItem(`userdata-${brandID}-appointDate`)+"T"+timeValue;
      // console.log(dateTimeValue);
      saveValue("appointmentdatetime", dateTimeValue);
      if (save) saveValue(save, timeValue);
      homeBot.message
      .add({
        delay: 1000,
        loading: true,
        content: `You have selected ${timeValue}.`,
      })
      .then(function (index) {
        setFlowData(next, timeValue);
        var nextPayload = parseInt(next)+1;
        nextQuestion(nextPayload);
      });
  }
  function question_Phone(next) {
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    if(questions[next].quick_reply_phone == "yes") {
      if(localStorage.getItem("vmn")) {
        var mobile = localStorage.getItem("vmn");
        var newmsg = [
          {
            action: {
              text: {
                action: {
                  placeholder: questions[next].question,
                  value: mobile,
                  sub_type: "text"
                }
              }
            },
            save: "question_Phone",
            next: true,
            current: next,
            nextvalue: nextPayload
          },
        ];
        addmessage(newmsg);
      }
    }
  }
  function question_Number(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    var newmsg = [
      {
        action: {
          text: {
            action: {
              placeholder: questions[next].question,
              sub_type: "number"
            }
          }
        },
        save: "question_Number",
        next: true,
        current: next,
        nextvalue: nextPayload
      },
    ];
    addmessage(newmsg);
  }
  function question_URL(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    var newmsg = [
      {
        action: {
          text: {
            action: {
              placeholder: questions[next].question,
              sub_type: "text"
            }
          }
        },
        save: "question_URL",
        next: true,
        current: next,
        nextvalue: nextPayload
      },
    ];
    addmessage(newmsg);
  }
  function question_File(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    if(questions[next].skip_button_text) {
      var newmsg = [
        {
          add: {
            type: "html",
            content: `<input type="file" name="file_upload" onchange="imageFileUpload(this, '`+next+`');">`,
          },
        },
        {
          action: {
            button: {
              action: [
                {
                  text: questions[next].skip_button_text,
                  value: "skipquestion",
                  next: nextPayload
                }
              ]
            }
          }
        }
      ];
    } else {
      var newmsg = [
        {
          add: {
            type: "html",
            content: `<input type="file" name="file_upload" onchange="imageFileUpload(this, '`+next+`');">`,
          },
        },
      ];
    }
    addmessage(newmsg);
  }
  function imageFileUpload(input, next){
    var bcode = localStorage.getItem('brand_code');
    var brandid = localStorage.getItem('brandid');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        // console.log(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      // console.log(input.files[0].name);
      var imgName = input.files[0].name;
      var imgFile = input.files[0];
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child('chatbots/'+brandid+'/questions/' + imgName).put(imgFile);
      uploadTask.on('state_changed', function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            // console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING:
            // console.log('Upload is running');
            break;
        }
      }, function (error) {
        console.log(error);
      }, function (res) {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          // console.log('File available at', downloadURL);
          setFlowData(next, downloadURL);

        });
      });
    }
    var nextPayload = parseInt(next)+1;
    nextQuestion(nextPayload);
  }
  function question_Image(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    if(questions[next].skip_button_text) {
      var newmsg = [
        {
          add: {
            type: "html",
            content: `<input type="file" name="image_upload" onchange="imageFileUpload(this, '`+next+`');">`,
          },
        },
        {
          action: {
            button: {
              action: [
                {
                  text: questions[next].skip_button_text,
                  value: "skipquestion",
                  next: nextPayload
                }
              ]
            }
          }
        }
      ];
    } else {
      var newmsg = [
        {
          add: {
            type: "html",
            content: `<input type="file" name="image_upload" onchange="imageFileUpload(this, '`+next+`');">`,
          },
        },
      ];
    }
    addmessage(newmsg);
  }
  function question_Video(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    if(questions[next].skip_button_text) {
      var newmsg = [
        {
          add: {
            type: "html",
            content: `<input type="file" name="video_upload" onchange="videoUpload(this, '`+next+`');">`,
          },
        },
        {
          action: {
            button: {
              action: [
                {
                  text: questions[next].skip_button_text,
                  value: "skipquestion",
                  next: nextPayload
                }
              ]
            }
          }
        }
      ];
    } else {
      var newmsg = [
        {
          add: {
            type: "html",
            content: `<input type="file" name="video_upload" onchange="videoUpload(this, '`+next+`');">`,
          },
        },
      ];
    }
    addmessage(newmsg);
  }
  function videoUpload(input, next){
    var bcode = localStorage.getItem('brand_code');
    var brandid = localStorage.getItem('brandid');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        // console.log(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      // console.log(input.files[0].name);
      var imgName = input.files[0].name;
      var imgExt = imgName.split(".");
      console.log(imgExt[1]);
      if(imgExt[1] == "mp4" || imgExt[1] == "mov" || imgExt[1] == "mpeg" || imgExt[1] == "wma" || imgExt[1] == "wmv") {
        var imgFile = input.files[0];
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('chatbots/'+brandid+'/questions/' + imgName).put(imgFile);
        uploadTask.on('state_changed', function (snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              // console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING:
              // console.log('Upload is running');
              break;
          }
        }, function (error) {
          console.log(error);
        }, function (res) {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // console.log('File available at', downloadURL);
            setFlowData(next, downloadURL);

          });
        });
        var nextPayload = parseInt(next)+1;
        nextQuestion(nextPayload);
      } else {
        homeBot.message
        .add({
          delay: 1000,
          loading: true,
          content: `Wrong format! File accepted only in .mp4, .mov, .mpeg, .wma and .mwv format. Please select a video.`,
        })
        .then(function (index) {
          question_Video(next);
        });
      }
    }
  }
  function question_Datetime(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
    var nextPayload = parseInt(next)+1;
    let today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }`;
    // let htmlElement = `<input type="datetime" id="datetimevalue" max='${today}' required onchange="success('datetimevalue')" style="float:left;" />&nbsp<input type="submit" id='submitdatetimevalue' value="Ok" onclick="getDateTimeValue('datetimevalue', '${next}')" style="position: relative;" disabled/>`;
    let htmlElement = `<input type="text" placeholder="Select date and time" id="datetimevalue" required onchange="success('datetimevalue')" style="float:left;width:80%;" />&nbsp<input type="submit" id='submitdatetimevalue' value="Ok" onclick="getDateTimeValue('datetimevalue', '${next}')" style="position: relative;" disabled/>`;
    var result = {
      add: {
        type: "html",
        content: htmlElement,
      },
    };
    newMessage(result);
    setTimeout( function() {
      var calendarDateTime = app.calendar.create({
        inputEl: '#datetimevalue',
        timePicker: true,
        disabled: {
          to: new Date()
        },
        dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
      });
    }, 1500);
  }
  function getDateTimeValue(id, next) {
    let rangeValue = document.getElementById(id).value;
    // console.log(rangeValue);
    homeBot.message
      .add({
        delay: 1000,
        loading: true,
        content: `You have selected ${rangeValue}.`,
      })
      .then(function (index) {
        setFlowData(next, rangeValue);
        var bcode = localStorage.getItem('brand_code');
        var questions = JSON.parse(localStorage.getItem(bcode+'_questions'));
        var lastPayload = questions[next].postback_id;
        let subsequentcall = true;      
        var fb_bot = localStorage.getItem("fb_bot");
        const brandQuery = new Parse.Query("brandData");
        if(fb_bot && fb_bot == "true") {
          brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
        } else {
          brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
        }
        brandQuery
        .find()
        .then(function (results) {
          if(results.length > 0){  
            jQuery.each(results, async function (key, value) {
              var jsonRes = JSON.parse(JSON.stringify(value));
              var fb_page_id = jsonRes.brand_code;
              // console.log(fb_page_id);
              processfb(lastPayload, fb_page_id, subsequentcall);
            });
          }
        });
      });
  }

  function nextFormBuilder(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
    if(questions[next].label != 'Submit') {
      var textmessage = {
        add: {
          content: questions[next].label,
        },
      };
      newMessage(textmessage);
    }
    if(questions[next].subtype == 'text') {
      formBuilder_Text(next);
    } else if(questions[next].subtype == 'tel') {
      formBuilder_Tel(next);
    } else if(questions[next].subtype == 'email') {
      formBuilder_Email(next);
    } else if(questions[next].type == 'date') {
      formBuilder_Date(next);
    } else if(questions[next].type == 'time') {
      formBuilder_Time(next);
    } else if(questions[next].type == 'button') {
      var bcode = localStorage.getItem('brand_code');
      var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
      var newmsg = [
          {
            action: {
              button: {
                action: [
                  {
                    text: questions[next].label,
                    value: "submit_form_builder"
                  }
                ]
              }
            }
          }
        ];
        addmessage(newmsg);
    }
  }

  function formBuilder_Text(next){
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
    var nextPayload = parseInt(next)+1;
    var nameVal = localStorage.getItem("name") ? localStorage.getItem("name") : '';
    var newmsg = [
      {
        action: {
          text: {
            action: {
              placeholder: questions[next].label,
              value: nameVal,
              sub_type: "text"
            }
          }
        },
        save: questions[next].name,
        formBuilder: true,
        current: next,
        nextvalue: nextPayload
      },
    ];
    addmessage(newmsg);
  }
  function formBuilder_Tel(next) {
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
    var nextPayload = parseInt(next)+1;
    if(localStorage.getItem("vmn")) {
      var mobile = localStorage.getItem("vmn");
      var newmsg = [
        {
          action: {
            text: {
              action: {
                placeholder: questions[next].label,
                value: mobile,
                sub_type: "text"
              }
            }
          },
          save: questions[next].name,
          formBuilder: true,
          current: next,
          nextvalue: nextPayload
        },
      ];
      addmessage(newmsg);
    }
  }
  function formBuilder_Email(next) {
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
    var nextPayload = parseInt(next)+1;
    var emailVal = localStorage.getItem("email") ? localStorage.getItem("email") : '';
    var newmsg = [
      {
        action: {
          text: {
            action: {
              placeholder: questions[next].label,
              value: emailVal,
              sub_type: "email"
            }
          }
        },
        save: questions[next].name,
        formBuilder: true,
        current: next,
        nextvalue: nextPayload
      },
    ];
    addmessage(newmsg);
  }
  function formBuilder_Date(next) {
    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
    var save = questions[next].name;
    var fdate = new Date();
    fdate.setDate(fdate.getDate() + 1);
    var fromdate = new Date();
    fromdate.setDate(fromdate.getDate() + 2);
    var fb_bot = localStorage.getItem("fb_bot");
    const brandQuery = new Parse.Query("brandData");
    if(fb_bot && fb_bot == "true") {
      brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
    } else {
      brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
    }
    brandQuery
    .find()
    .then(function (results) {
      if(results.length > 0){  
        jQuery.each(results, async function (key, value) {
          var jsonRes = JSON.parse(JSON.stringify(value));
          var resDays = jsonRes.res_days ? jsonRes.res_days : "30";
          // console.log(parseInt(resDays));
          var todate = new Date();
          todate.setDate(todate.getDate() + parseInt(resDays));
          var htmlElement = `<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper dateswiper"><button class="dateui" onClick="selectFormDate('`+moment().format('YYYY-MM-DD')+`', '`+save+`', '`+next+`');"> Today <br> `+moment().format('DD MMM')+`</button><button class="dateui" onClick="selectFormDate('`+moment(fdate).format('YYYY-MM-DD')+`', '`+save+`', '`+next+`');"> Tomorrow <br> `+moment(fdate).format('DD MMM')+`</button>`;
          for (var d = fromdate; d <= todate; d.setDate(d.getDate() + 1)) {
            htmlElement += `<button class="dateui" onClick="selectFormDate('`+moment(d).format('YYYY-MM-DD')+`', '`+save+`', '`+next+`');"> `+moment(d).format('dddd')+` <br> `+ moment(d).format('DD MMM') +`</button>`;
          }
          htmlElement += `</div></div>`;

          var result = {
            add: {
              type: "html",
              content: htmlElement,
            },
          };
          newMessage(result);
        });
      }
    });
  }
  function selectFormDate(dateValue, saveval, next){
      if (saveval) saveValue(saveval, dateValue);
      if (saveval) saveValue("appointdate_"+localStorage.getItem("brand_code"), dateValue);
      homeBot.message
      .add({
        delay: 1000,
        loading: true,
        content: `You have selected ${dateValue}.`,
      })
      .then(function (index) {
        var bcode = localStorage.getItem('brand_code');
        var ansFormBuilder = localStorage.getItem(bcode+'_form_builder_answer');
        var obj = saveval+'='+dateValue+'&';
        var new_object = ansFormBuilder+""+obj;
        // console.log(new_object);
        localStorage.setItem(bcode+'_form_builder_answer', new_object);
        var nextPayload = parseInt(next)+1;
        nextFormBuilder(nextPayload);
      });
  }
  function formBuilder_Time(next) {
    let today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }T"11:00:00"`;

    var bcode = localStorage.getItem('brand_code');
    var questions = JSON.parse(localStorage.getItem(bcode+'_form_builder'));
    var fb_bot = localStorage.getItem("fb_bot");
    const brandQuery = new Parse.Query("brandData");
    if(fb_bot && fb_bot == "true") {
      brandQuery.equalTo("brand_code", localStorage.getItem("brand_code"));
    } else {
      brandQuery.equalTo("objectId", localStorage.getItem("brand_code"));
    }
    brandQuery
    .find()
    .then(function (results) {
      if(results.length > 0){  
        jQuery.each(results, async function (key, value) {
          var jsonRes = JSON.parse(JSON.stringify(value));
          var startTime = jsonRes.res_starttime ? jsonRes.res_starttime : "11:00:00";
          var endTime = jsonRes.res_endtime ? jsonRes.res_endtime : "22:00:00";
          // console.log(startTime+" - "+endTime);
          let evalMinTime = (startTime).split(":");
          let evalMaxTime = (endTime).split(":");
          let minTime = startTime;
          let maxTime = endTime;
          var save = questions[next].name;
          var quarterHours = ["00", "30"];
          var htmlElement = '<div class="swiper-container3 swiper-init demo-swiper3 demo-swiper-auto" style="overflow:auto;"><div class="swiper-wrapper timeswiper">';
          var appointdate = localStorage.getItem(`userdata-${brandID}-appointdate_`+localStorage.getItem("brand_code"));
          var todayDate = moment().format('YYYY-MM-DD');
          var startTime = (appointdate == todayDate) ? (parseInt(moment().format('H')) + 1) : evalMinTime[0];
          for(var i = startTime; i <= evalMaxTime[0]; i++){
            for(var j = 0; j < 2; j++){
              var dt = i + ":" + quarterHours[j];
              var time = moment(dt, ["h:mm"]).format("h:mm A");
              var timeVal = moment(dt, ["h:mm"]).format("HH:mm");
              htmlElement += `<button class="timeui" onClick="selectFormTime('`+timeVal+`', '`+save+`', '`+next+`');">`+time+`</button>`;
            }
          }
          htmlElement += `</div></div>`;

          var result = {
            add: {
              type: "html",
              content: htmlElement,
            },
          };
          newMessage(result);
        });
      }
    });
  }
  function selectFormTime(timeValue, save, next) {
      if (save) saveValue(save, timeValue);
      homeBot.message
      .add({
        delay: 1000,
        loading: true,
        content: `You have selected ${timeValue}.`,
      })
      .then(function (index) {
        var bcode = localStorage.getItem('brand_code');
        var ansFormBuilder = localStorage.getItem(bcode+'_form_builder_answer');
        var obj = save+'='+timeValue;
        var new_object = ansFormBuilder+""+obj;
        // console.log(new_object);
        localStorage.setItem(bcode+'_form_builder_answer', new_object);
        var nextPayload = parseInt(next)+1;
        nextFormBuilder(nextPayload);
      });
  }
  
  function nativeCarousel(element, medium, pid) {
    let pageid = pid;
    pageid = "'" + pageid + "'";
    let cardDefaultImage = `https://www.ajactraining.org/wp-content/uploads/2019/09/image-placeholder.jpg`;
    if (element) {
      var myMsg =
        '<div class="swiper-container3 swiper-2" style="overflow:scroll;">' +
        '<div class="swiper-wrapper">';
      jQuery.each(element, function (i) {
        let defImage = element[i].image_url
          ? element[i].image_url
          : cardDefaultImage;
        // console.log(element[i]);
        if (element.length == 1) {
          var headClass = "card-header-first";
          var cardFirst = "";
          var swiperMid = "";
          var swipeNew = "margin-right:-2% !important;";
          var cardOne = "border-radius:25px;width:100%;";
          var headOne = "border-radius:25px 25px 0px 0px !important;";
        } else if (i == 0) {
          var headClass = "card-header-first";
          var cardFirst = "";
          var swiperMid = "";
          var swipeNew = "margin-right:-2% !important;";
          var cardOne = "border-radius: 25px 0px 0px 25px;width:80%;";
          var headOne = "";
        } else if (i == element.length - 1) {
          var headClass = "card-header-last";
          var cardFirst = "card-last";
          var swiperMid = "swiper-slide-mid";
          var swipeNew = "margin-right:-15% !important;";
          var cardOne = "border-radius: 0px 25px 25px 0px;width:80%;";
          var headOne = "";
        } else {
          var headClass = "card-header-mid";
          var cardFirst = "card-first";
          var swiperMid = "swiper-slide-mid";
          var swipeNew = "margin-right:-15% !important;";
          var cardOne = "border-radius: 0px 0px 0px 0px;width:80%;";
          var headOne = "";
        }
        myMsg +=
          '<div class="swiper-slide ' +
          swiperMid +
          '" style="width:100% !important;' +
          swipeNew +
          '">' +
          '<div class="card ' +
          cardFirst +
          ' demo-card-header-pic" style="' +
          cardOne +
          'left:5%;float:left;">' +
          '<div style="background-image:url(' +
          defImage +
          ");" +
          headOne +
          '" valign="bottom" class="card-header ' +
          headClass +
          ' color-white no-border"></div>' +
          '<div class="card-content" style="min-height:85px;margin:5px;">' +
          '<div class="card-content-inner">' +
          '<p class="color-gray"><b>' +
          element[i].title +
          "</b></p>";
        if (element[i].subtitle) {
          myMsg +=
            "<p>" +
            `${
              element[i].subtitle.length > 80
                ? element[i].subtitle.substring(0, 80) + "…"
                : element[i].subtitle + "…"
            }` +
            "</p>";
        }
        myMsg +=
          "</div>" +
          "</div>" +
          '<div align="center" class="card-footer" style="display:block;">';
        var eleButton = element[i].buttons;
        if (eleButton) {
          // myMsg += '<div class="list simple-list">';
          //myMsg+= '<a href="#" class="link">&nbsp;</a>';
          jQuery.each(eleButton, function (j) {
            if (element[i].buttons[j].type == "web_url") {
              var bUrl = element[i].buttons[j].url;
              if (bUrl.indexOf(" 91") >= 0) {
                var buttUrl = bUrl.replace(" 91", "+91");
              } else {
                var buttUrl = bUrl;
              }
              myMsg +=
                '<div align="center" class="carousel_butt"><a href="'+buttUrl+'" class="link external" target="blank">' +
                element[i].buttons[j].title +
                "</a></div>";
            } else if (element[i].buttons[j].type == "botButton"){
              // console.log(element[i].buttons[j].title);
              let params = "'"+JSON.stringify(element[i].buttons[j].params)+"'";
              let text = "'"+ (element[i].buttons[j].text).replace(/[^a-zA-Z0-9 ]/g, "")+ "'";
              let value = "'"+ element[i].buttons[j].value+ "'";
              let nextPayload = "'"+ element[i].buttons[j].nextpayload+ "'";
              let share_text = "'"+ (element[i].buttons[j].share_text).replace(/[^a-zA-Z0-9 ]/g, "")+ "'";
              let share_title = "'"+ (element[i].buttons[j].share_title).replace(/[^a-zA-Z0-9 ]/g, "")+ "'";
              let share_link = "'"+ element[i].buttons[j].share_link+ "'";
  
              myMsg += '<div align="center" class="carousel_butt"><a href="#" class="link" onClick="callAddAction(' +
                text +","+
                value + "," +
                nextPayload+ "," +
                share_text+ "," +
                share_title+ "," +
                share_link+
                ");\">" +
                element[i].buttons[j].title +
                "</a></div>";  
            } else if (element[i].buttons[j].type == "phone_number") {
              myMsg +=
                '<div align="center" class="carousel_butt"><a href="tel:' +
                element[i].buttons[j].payload +
                '" class="link external" style="display:inline-table;">' +
                element[i].buttons[j].title +
                "</a></div>";
            } else if (element[i].buttons[j].type == "postback") {
              // let pageid = pid;
              let payload = "'" + element[i].buttons[j].payload + "'";
              let subsequentcall = true;
              myMsg +=
                '<div align="center" class="carousel_butt"><a href="#" class="link" onClick="processfb(' +
                payload +
                ", " +
                pageid +
                ", " +
                subsequentcall +
                ');">' +
                element[i].buttons[j].title +
                "</a></div>";
            } else if (element[i].buttons[j].type == "product") {
              myMsg +=
                '<div align="center" class="carousel_butt"><a href="#" class="link" onClick="handleBuyEvent(' +
                JSON.stringify(element[i].buttons[j]) +
                ');">' +
                element[i].buttons[j].title +
                "</a></div>";
            } else if (element[i].buttons[j].type == "nextpayload") {
              myMsg +=
                '<div align="center" class="carousel_butt"><a href="#" class="link" onClick="offline(\'' +
                element[i].buttons[j].payload +
                "');\">" +
                element[i].buttons[j].title +
                "</a></div>";
            }
            // myMsg += "<br>";
          });
        }
        myMsg += "</div>" + "</div></div>";
      });
      myMsg +=
        "</div>" +
        //'<div class="swiper-pagination" style="position:relative;bottom:20px;"></div>'+
        "</div>";
      var result = {
        add: {
          type: "html",
          content: myMsg,
        },
      };
      newMessage(result);
    } else {
      var myMsg = "<div>Elements not Found.!</div>";
      var result = {
        add: {
          type: "html",
          content: myMsg,
        },
      };
      newMessage(result);
    }
  }
  
  function callAddAction(text, value, nextPayload, share_text, share_title, share_link){
    try{      
      if (value == "share") {
        offline(
          nextPayload,
          value
        );
        if (navigator.share) {        
          navigator
            .share({
              title: share_title,
              text: share_text,
              url: share_link,
            })
            .then(() => {
              // console.log("Data was shared successfully");
              var ogmessage = {
                add: {
                  content: "Data shared successfully.",
                },
              };
              newMessage(ogmessage);
            })
            .catch((err) => {
              console.error("Share failed:", err.message);
            });
        } else {
          // share message further..          
          var ogmessage = {
            add: {
              type: "html",
              content: `Please click on the following links to Share: <a class="link external" target="blank" href="https://facebook.com/sharer/sharer.php?u=` +
              encodeURI( share_link ) +
              `"><i class="fa fa-facebook-square" style="font-size:34px;margin-left:5px;"></i></a>&nbsp;<a class="link external" target="blank" href="https://twitter.com/intent/tweet/?text=` +
              encodeURI( share_title ) + ";url=" + encodeURI( share_link ) +
              `"><i class="fa fa-twitter-square" style="font-size:34px;margin-left:10px;"></i></a>&nbsp;<a class="link external" target="blank" href="https://www.linkedin.com/sharing/share-offsite/?url=` +
              encodeURI( share_link ) +
              `"><i class="fa fa-linkedin-square" style="font-size:34px;margin-left:10px;"></i></a>&nbsp;<a class="link external" target="blank" href="https://wa.me/?text=` +
              encodeURI( share_title + " " + share_link ) +
              `"><i class="fa fa-whatsapp" style="font-size:34px;margin-left:10px;"></i></a>`,
            },
          };
          newMessage(ogmessage);        
        }
      }
    }catch(error){
      console.error(`Error in callAddAction function. Error is ${JSON.stringify(error)}`);
    }
  }
  
  function openUrl(url) {
    // console.log(`URL IS: `, url);
    if (url.includes("https://wa.me/?text=")) {
      navigator.share({
          title: url.split("https://wa.me/?text=")[1].includes("https://")
            ? url.split("https://wa.me/?text=")[1].split("https://")[0]
            : url.split("https://wa.me/?text=")[1],
          text: url.split("https://wa.me/?text=")[1].includes("https://")
            ? url.split("https://wa.me/?text=")[1].split("https://")[0]
            : url.split("https://wa.me/?text=")[1],
          url: url.split("https://wa.me/?text=")[1].includes("https://")
            ? `https://${
                url.split("https://wa.me/?text=")[1].split("https://")[1]
              }`
            : url.split("https://wa.me/?text=")[1],
        })
        .then(() => {
          var ogmessage = {
            add: {
              content: "Thank you for sharing 👍",
            },
          };
          newMessage(ogmessage);
        })
        .catch((err) => {
          console.error("Share failed:", err.message);
        });
      return;
    }
    let target = "blank";
    let options = "location=yes";
    var vmn = localStorage.getItem("vmn");
    var resVal = url.replace("#SUBSCRIBER_ID_REPLACE#", vmn);
    // console.log(resVal);
    window.open(resVal);
  }
  
  function openCategory(cat) {
    app.preloader.showIn(".app", "white");
    jQuery(".catName").html(cat);
    jQuery(".popular_detail").html("");
    if (cat == "featured") {
      var bQuery = new Parse.Query("brandData");
      bQuery.exists("brand_id");
      bQuery.equalTo("status", true);
      bQuery.containedIn("type", ['brand', 'fb']);
      bQuery.descending("createdAt");
      bQuery
        .find()
        .then(function (results) {
          jQuery.each(results, function (key, value) {
            var json = JSON.parse(JSON.stringify(value));
            // console.log(json);
            var brand_id = "'"+json.brand_id+"'";
            var id = json.type == "fb" ? "'" + json.brand_code + "'" : "'" + value.id + "'";
            var pageid = json.type == "fb" ? "'" + json.fb_page_id + "'" : "''";
            var type =
              (json.type && json.type != "" && json.type.length > 0)
                ? "'" + json.type + "'"
                : "''";
            jQuery(".popular_detail").append(
              '<li><a href="#" onclick="openPop(' +
                id +
                ", " +
                type +
                ", " +
                pageid +
                ", " +
                brand_id +
                ');" class="item-content">' +
                '<div class="item-media"><img src="' +
                json.brand_icon +
                '" width="60" height="60" style="border-radius:50%;" /></div>' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title" style="color:#000;">' +
                json.brand_name +
                "</div>" +
                "</div>" +
                '<div class="item-subtitle" style="color:#999;">' +
                json.brand_category +
                "</div>" +
                '<div class="item-text">' +
                json.brand_tagline +
                "</div>" +
                "</div>" +
                "</a></li>"
            );
          });
          app.preloader.hideIn(".app");
          app.popup.open(".popup-category");
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (cat == "nearby") {
      var nQuery = new Parse.Query("brandData");
      nQuery.descending("createdAt");
      nQuery.equalTo("status", true);
      nQuery.containedIn("type", ['brand', 'fb']);
      nQuery
        .find()
        .then(function (results) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              jQuery.each(results, function (key, value) {
                var json = JSON.parse(JSON.stringify(value));
                // console.log(json);
                if(json.lat_long) {
                  var latLong = json.lat_long ? json.lat_long.split(",") : null;
                  var dist = getDistanceFromLatLonInKm(
                    latLong[0],
                    latLong[1],
                    position.coords.latitude,
                    position.coords.longitude
                  );
                  // console.log("Distance : ", dist);
                  if (dist <= 10) {
                    var brand_id = "'"+json.brand_id+"'";
                    var id = json.type == "fb" ? "'" + json.brand_code + "'" : "'" + value.id + "'";
                    var pageid =
                      json.type == "fb" ? "'" + json.fb_page_id + "'" : "''";
                    var type =
                      (json.type && json.type != "" && json.type.length > 0)
                        ? "'" + json.type + "'"
                        : "''";
                    jQuery(".popular_detail").append(
                      '<li><a href="#" onclick="openPop(' +
                        id +
                        ", " +
                        type +
                        ", " +
                        pageid +
                        ", " +
                        brand_id +
                        ');" class="item-content">' +
                        '<div class="item-media"><img src="' +
                        json.brand_icon +
                        '" width="60" height="60" style="border-radius:50%;" /></div>' +
                        '<div class="item-inner">' +
                        '<div class="item-title-row">' +
                        '<div class="item-title" style="color:#000;">' +
                        json.brand_name +
                        "</div>" +
                        "</div>" +
                        '<div class="item-subtitle" style="color:#999;">' +
                        json.brand_category +
                        "</div>" +
                        '<div class="item-text">' +
                        json.brand_tagline +
                        "</div>" +
                        "</div>" +
                        "</a></li>"
                    );
                  }
                }
              });
              var nearbyContent = jQuery('.popular_detail li').length;
              if(nearbyContent < 1) {
                jQuery(".popular_detail").html(
                  '<li style="padding:20px;">No result found for Nearby</li>'
                );
              }
            },
            function (error) {
              console.error(error);
            }
          );
          app.preloader.hideIn(".app");
          app.popup.open(".popup-category");
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      var pQuery = new Parse.Query("brandData");
      pQuery.fullText("categories", cat);
      pQuery.equalTo("status", true);
      pQuery.containedIn("type", ['brand', 'fb']);
      pQuery.descending("createdAt");
      pQuery
        .find()
        .then(function (results) {
          if (results.length > 0) {
            jQuery.each(results, function (key, value) {
              var json = JSON.parse(JSON.stringify(value));
              // console.log(json);
              var brand_id = "'"+json.brand_id+"'";
              var id = json.type == "fb" ? "'" + json.brand_code + "'" : "'" + value.id + "'";
              var pageid = json.type == "fb" ? "'" + json.fb_page_id + "'" : "''";
              var type =
                (json.type && json.type != "" && json.type.length > 0)
                  ? "'" + json.type + "'"
                  : "''";
              jQuery(".popular_detail").append(
                '<li><a href="#" onclick="openPop(' +
                  id +
                  ", " +
                  type +
                  ", " +
                  pageid +
                  ", " +
                  brand_id +
                  ');" class="item-content">' +
                  '<div class="item-media"><img src="' +
                  json.brand_icon +
                  '" width="60" height="60" style="border-radius:50%;" /></div>' +
                  '<div class="item-inner">' +
                  '<div class="item-title-row">' +
                  '<div class="item-title" style="color:#000;">' +
                  json.brand_name +
                  "</div>" +
                  "</div>" +
                  '<div class="item-subtitle" style="color:#999;">' +
                  json.brand_category +
                  "</div>" +
                  '<div class="item-text">' +
                  json.brand_tagline +
                  "</div>" +
                  "</div>" +
                  "</a></li>"
              );
            });
          } else {
            jQuery(".popular_detail").append(
              '<li style="padding:10px;">No result found for <span style="text-transform:capitalize;">' +
                cat +
                "</span></li>"
            );
          }
          app.preloader.hideIn(".app");
          app.popup.open(".popup-category");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  function openSearch() {
    if(app.device.platform == "ios") {
      mainView.router.navigate({ name: "search", path: "/search/" });
    } else {
      mainView.router.navigate({ name: "search" });
    }
  }
  //SUMAN'S CODE INTEGRATION
  function openReceipt() {
    // var data =
    //   '{"template_type":"receipt","recipient_name":"Stephane Crozatier","order_number":"12345678902","currency":"USD","payment_method":"Visa 2345", "order_url":"http://petersapparel.parseapp.com/order?order_id=123456","timestamp":"1428444852",' +
    //   '"address":{"street_1":"1 Hacker Way","street_2":"","city":"Menlo Park","postal_code":"94025","state":"CA","country":"US"},' +
    //   '"summary":{"subtotal":75.00,"shipping_cost":4.95,"total_tax":6.19,"total_cost":56.14},' +
    //   '"adjustments":[{"name":"New Customer Discount","amount":20},{"name":"$10 Off Coupon","amount":10}],' +
    //   '"elements":[{"title":"Classic White T-Shirt","subtitle":"100% Soft and Luxurious Cotton","quantity":2,"price":50,"currency":"USD","image_url":"img/whiteshirt.png"},' +
    //   '{"title":"Classic Gray T-Shirt","subtitle":"100% Soft and Luxurious Cotton","quantity":1,"price":25,"currency":"USD","image_url":"img/grayshirt.png"}' +
    //   "]}";
    var data = localStorage.getItem("receipt_payload");
    // console.log(data);
    var result = JSON.parse(data);
    // console.log(result);
    jQuery(".receipt_details").html("");
    var myMsg =
      '<div class="list media-list" style="margin:0px;">' +
      '<div style="margin:5px 15px;font-size:12px;color:#999;">Items</div>' +
      "<div><ul>";
    var eleReceipt = result.elements;
    if (eleReceipt) {
      jQuery.each(eleReceipt, function (r) {
        // console.log(eleReceipt[r].title);
        myMsg +=
          "<li>" +
          '<div class="item-content">' +
          '<div class="item-media">' +
          '<img src="' +
          eleReceipt[r].image_url +
          '" style="min-height:70px;width:70px;max-width:inherit;border-radius:initial;">' +
          "</div>" +
          '<div class="item-inner">' +
          '<div class="item-title-row">' +
          '<div class="item-title">' +
          eleReceipt[r].title +
          "</div>" +
          "</div>";
        if (eleReceipt[r].subtitle) {
          myMsg += '<div class="item-text">' + eleReceipt[r].subtitle + "</div>";
        }
        myMsg += "</div>" + "</div>" + "</li>";
      });
    }
    myMsg +=
      "</ul><div>" +
      '<p style="margin:5px 15px;line-height:25px;"><span style="font-size:12px;color:#999;">OORDER NUMBER</span><br><b>' +
      result.order_number +
      "</b></p>" +
      '<p style="margin:5px 15px;line-height:25px;"><span style="font-size:12px;color:#999;">PIAD WITH</span><br><b>' +
      result.payment_method +
      "</b></p>";
    if (result.address) {
      myMsg +=
        '<p style="margin:5px 15px;line-height:25px;"><span style="font-size:12px;color:#999;">SHIP TO</span><br><b>' +
        result.recipient_name +
        "</b><br><b>" +
        result.address.street_1 +
        ", " +
        result.address.city +
        ", " +
        result.address.state +
        ", " +
        result.address.country +
        " " +
        result.address.postal_code +
        "</b></p>";
    }
    myMsg +=
      '</div></div><div style="font-weight:bold;"><p style="margin:5px 15px;line-height:25px;font-size:12px;color:#999;">SUMMARY</p>' +
      '<p style="margin:5px 15px;line-height:25px;"><span style="float:left;">Subtotal</span><span style="float:right;">' +
      result.summary.subtotal +
      "</span><br>" +
      '<p style="margin:5px 15px;line-height:25px;"><span style="float:left;">Delivery</span><span style="float:right;">' +
      result.summary.shipping_cost +
      "</span><br>" +
      '<p style="margin:5px 15px;line-height:25px;"><span style="float:left;">Estimated tax</span><span style="float:right;">' +
      result.summary.total_tax +
      "</span><br>" +
      '<p style="margin:5px 15px;line-height:25px;"><span style="float:left;">Total</span><span style="float:right;">' +
      result.summary.total_cost +
      "</span>" +
      "</div>" +
      "</div>";
    jQuery(".receipt_details").append(myMsg);
    app.popup.open(".popup-receipt");
    localStorage.removeItem("receipt_payload");
  }
  
  //SUMAN'S CODE INTEGRATION
  function openBoarding() {
    // var data =
    //   '{"template_type": "airline_boardingpass","intro_message": "You are checked in.","locale": "en_US","boarding_pass": [{"passenger_name": "SMITH/NICOLAS","pnr_number": "CG4X7U","seat": "74J","logo_image_url": "img/big.png","header_image_url": "img/small.png","qr_code": "M1SMITH/NICOLAS  CG4X7U nawouehgawgnapwi3jfa0wfh","above_bar_code_image_url": "https://www.example.com/en/PLAT.png","auxiliary_fields": [{"label": "Terminal","value": "T1"},{"label": "Departure","value": "30OCT 19:05"}],"secondary_fields": [{"label": "Boarding","value": "18:30"},{"label": "Gate","value": "D57"},{"label": "Seat","value": "74J"},{"label": "Sec.Nr.","value": "003"}],"flight_info": {"flight_number": "KL0642","departure_airport": {"airport_code": "JFK","city": "New York","terminal": "T1","gate": "D57"},"arrival_airport": {"airport_code": "AMS","city": "Amsterdam"},"flight_schedule": {"departure_time": "2016-01-02T19:05","arrival_time": "2016-01-05T17:30"}}}]}';
    var data = localStorage.getItem("boarding_payload");
    var result = JSON.parse(data);
    // console.log(result);
    jQuery(".boarding_details").html("");
    var bPass = result.boarding_pass;
    // console.log(bPass);
    var qrCode =
      "https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=" +
      bPass[0].qr_code;
    // cordova.plugins.barcodeScanner.encode(cordova.plugins.barcodeScanner.Encode.TEXT_TYPE, qrCode, function(success) {
    //     console.log("encode success: " + success.file);
    //   }, function(fail) {
    //     console.log("encoding failed: " + fail);
    //   }
    // );
    var myMsg =
      '<div class="list media-list" style="margin:0px;"><div class="card" style="background:#0085ff;color:#fff;border-radius:20px;min-width:275px;">' +
      '<div class="card-header" style="margin:0px 10px;font-size:13px;padding:10px 0px 0px 0px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="' +
      bPass[0].header_image_url +
      '" width="36" height="36"></div><div align="center" style="width:20%;float:right;">Priority<br>Boarding</div></div>' +
      '</div><table width="100%" style="padding:0px 10px;border-bottom:#fff 0.5px solid;">' +
      '<tr><th align="left"><span style="font-size:13px;">Passenger</span></th><th align="right"><span style="font-size:13px;">Departure</span></th></tr>' +
      '<tr><td><b style="font-size:13px;">' +
      bPass[0].passenger_name +
      '</b></td><td align="right"><b>' +
      bPass[0].flight_info.flight_schedule.departure_time +
      "</b></td></tr>" +
      '</table><div class="card-content">';
    myMsg +=
      '<table width="100%" style="padding:0px 10px;">' +
      '<tr><th align="left"><span style="font-size:13px;">' +
      bPass[0].flight_info.departure_airport.city +
      '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;">' +
      bPass[0].flight_info.arrival_airport.city +
      "</span></th></tr>" +
      '<tr><td><span style="font-size:40px;">' +
      bPass[0].flight_info.departure_airport.airport_code +
      '</span></td><td align="center"><img src="' +
      bPass[0].logo_image_url +
      '" width="64" height="64"></td><td align="right"><span style="font-size:40px;">' +
      bPass[0].flight_info.arrival_airport.airport_code +
      "</span></td></tr>" +
      "</table>";
    if (bPass[0].flight_info) {
      myMsg +=
        '<p style="margin:5px 10px;line-height:20px;float:left;width:19%;"><span style="font-size:13px;">Flight</span><br><b>' +
        bPass[0].flight_info.flight_number +
        "</b></p>";
    }
    myMsg +=
      '<p style="margin:5px 10px;line-height:20px;float:left;width:19%;"><span style="font-size:13px;">Terminal</span><br><b>' +
      bPass[0].flight_info.departure_airport.terminal +
      "</b></p>" +
      '<p style="margin:5px 10px;line-height:20px;float:left;width:19%;"><span style="font-size:13px;">PNR</span><br><b>' +
      bPass[0].pnr_number +
      "</b></p>" +
      '<p style="margin:5px 10px;line-height:20px;float:left;width:19%;"><span style="font-size:13px;">Arrival</span><br><b>' +
      bPass[0].flight_info.flight_schedule.arrival_time +
      "</b></p>";
    var sfields = bPass[0].secondary_fields;
    jQuery.each(sfields, function (f) {
      myMsg +=
        '<p style="margin:5px 10px;line-height:20px;float:left;width:19%;"><span style="font-size:13px;">' +
        sfields[f].label +
        "</span><br><b>" +
        sfields[f].value +
        "</b></p>";
    });
    myMsg +=
      '</div><div class="card-footer" style="width:100%;"><div align="center" style="width:100%;"><img src="' +
      qrCode +
      '" width="200" height="200" style="border-radius:10px;"></div></div>' +
      "</div></div>";
    jQuery(".boarding_details").append(myMsg);
    app.popup.open(".popup-boarding");
    localStorage.removeItem("boarding_payload");
  }
  function viewItinerary() {
    // var data = '{"1":{"recipient":{"id":"replace_id"},"message": {"attachment": {"type": "template","payload": {"template_type": "airline_itinerary","intro_message": "Here is your flight itinerary.","locale": "en_US","pnr_number": "ABCDEF","passenger_info": [{"name": "Farbound Smith Jr","ticket_number": "0741234567890","passenger_id": "p001"},{"name": "Nick Jones","ticket_number": "0741234567891","passenger_id": "p002"}],'+
    //       '"flight_info": [{"connection_id": "c001","segment_id": "s001","flight_number": "KL9123","aircraft_type": "Boeing 737","departure_airport": {"airport_code": "SFO","city": "San Francisco"},"arrival_airport": {"airport_code": "SLC","city": "Salt Lake City"},"flight_schedule": {"departure_time": "2016-01-02T19:45","arrival_time": "2016-01-02T21:20"},"travel_class": "business"},'+
    //       '{"connection_id": "c002","segment_id": "s002","flight_number": "KL321","aircraft_type": "Boeing 747-200","travel_class": "business","departure_airport": {"airport_code": "SLC","city": "Salt Lake City","terminal": "T1","gate": "G33"},'+
    //       '"arrival_airport": {"airport_code": "AMS","city": "Amsterdam","terminal": "T1","gate": "G33"},"flight_schedule": {"departure_time": "2016-01-02T22:45","arrival_time": "2016-01-03T17:20"}}],'+
    //       '"passenger_segment_info": [{"segment_id": "s001","passenger_id": "p001","seat": "12A","seat_type": "Business"},{"segment_id": "s001","passenger_id": "p002","seat": "12B","seat_type": "Business"},'+
    //       '{"segment_id": "s002","passenger_id": "p001","seat": "73A","seat_type": "World Business","product_info": [{"title": "Lounge","value": "Complimentary lounge access"},{"title": "Baggage","value": "1 extra bag 50lbs"}]},'+
    //       '{"segment_id": "s002","passenger_id": "p002","seat": "73B","seat_type": "World Business","product_info": [{"title": "Lounge","value": "Complimentary lounge access"},{"title": "Baggage","value": "1 extra bag 50lbs"}]}],'+
    //       '"price_info": [{"title": "Fuel surcharge","amount": "1597","currency": "USD"}],"base_price": "12206","tax": "200","total_price": "14003","currency": "USD"}}}}}';
    var data = localStorage.getItem("itinerary_payload");
    var result = JSON.parse(data);
    // console.log(result[1]);
    jQuery(".itinerary_details").html("");
    var bPass = result;
    // console.log(bPass);
    var myMsg =
      '<div class="list media-list" style="margin:0px;"><div class="card" style="margin:0px;background:#fff;color:#000;border-radius:20px;min-width:275px;">' +
      '<div class="card-header" style="background:#0085ff;color:#fff;font-size:13px;padding:10px;"><div style="width:100%;"><div style="width:50%;float:left;"><img src="img/small.png" width="36" height="36"></div><div align="right" style="width:42%;float:right;">Booking Number<br><b style="line-height:22px;">' +
      bPass.pnr_number +
      "</b></div></div></div>" +
      '<div class="card-content">';
    jQuery.each(bPass.flight_info, function (f) {
      myMsg +=
        '<hr style="margin:0px 10px;"><table width="100%" style="padding:0px 10px;">' +
        '<tr><th align="left"><span style="font-size:13px;color:#999;">' +
        bPass.flight_info[f].departure_airport.city +
        '</span></th><th>&nbsp;</th><th align="right"><span style="font-size:13px;color:#999;">' +
        bPass.flight_info[f].arrival_airport.city +
        "</span></th></tr>" +
        '<tr><td><span style="font-size:40px;">' +
        bPass.flight_info[f].departure_airport.airport_code +
        '</span></td><td align="center"><img src="img/big.png" width="64" height="64"></td><td align="right"><span style="font-size:40px;">' +
        bPass.flight_info[f].arrival_airport.airport_code +
        "</span></td></tr>" +
        "</table>";
      myMsg +=
        '<div style="width:100%;"><p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Flight</span><br><b>' +
        bPass.flight_info[f].flight_number +
        "</b></p>";
      myMsg +=
        '<p style="margin:5px 10px;line-height:20px;float:left;width:28%;"><span style="font-size:13px;color:#999;">Departs</span><br><b>' +
        bPass.flight_info[f].flight_schedule.departure_time +
        "</b></p>";
      myMsg +=
        '<p style="margin:5px 10px;line-height:20px;float:right;width:24%;"><span style="font-size:13px;color:#999;">Arrives</span><br><b>' +
        bPass.flight_info[f].flight_schedule.arrival_time +
        "</b></p></div>";
      myMsg +=
        '<div style="width:100%;"><p style="margin:5px 10px;line-height:20px;float:left;width:44%;"><span style="font-size:13px;color:#999;">Date</span><br><b>' +
        bPass.flight_info[f].flight_schedule.departure_time +
        "</b></p>" +
        '<p style="margin:5px 10px;line-height:20px;float:right;width:24%;"><span style="font-size:13px;color:#999;">Aircraft</span><br><b>' +
        bPass.flight_info[f].aircraft_type +
        "</b></p></div>";
      myMsg +=
        '<table width="100%" style="padding:5px 10px;">' +
        '<tr><th align="left"><span style="font-size:13px;color:#999;">Passengers</span></th><th align="right"><span style="font-size:13px;color:#999;">Seat</span></th></tr>';
      jQuery.each(bPass.passenger_info, function (p) {
        jQuery.each(bPass.passenger_segment_info, function (s) {
          // console.log(bPass.flight_info[f].segment_id+" - "+bPass.passenger_segment_info[s].segment_id+" - "+bPass.passenger_info[p].passenger_id+" - "+bPass.passenger_segment_info[s].passenger_id);
          if (
            bPass.flight_info[f].segment_id ==
              bPass.passenger_segment_info[s].segment_id &&
            bPass.passenger_info[p].passenger_id ==
              bPass.passenger_segment_info[s].passenger_id
          ) {
            myMsg +=
              '<tr><td><b style="font-size:15px;">' +
              bPass.passenger_info[p].name +
              '</b></td><td align="right"><b style="font-size:15px;">' +
              bPass.passenger_segment_info[s].seat +
              "</b></td></tr>";
            jQuery.each(bPass.passenger_segment_info[s].product_info, function (
              pi
            ) {
              myMsg +=
                '<tr><td><b style="font-size:13px;color:#999;">' +
                bPass.passenger_segment_info[s].product_info[pi].title +
                ":" +
                bPass.passenger_segment_info[s].product_info[pi].value +
                "</b></td><td>&nbsp;</td></tr>";
            });
          }
        });
      });
      myMsg += "</table>";
    });
    myMsg +=
      '</div><div class="card-footer" style="background:#fff;width:100%;color:#000;"><table width="100%">' +
      '<tr><th align="left"><span style="font-size:15px;color:#999;">Summary</span></th><th>&nbsp;</th></tr>';
    jQuery.each(bPass.price_info, function (i) {
      myMsg +=
        '<tr><td align="left"><span style="font-size:15px;">' +
        bPass.price_info[i].title +
        '</span></td><td align="right"><b>' +
        bPass.price_info[i].currency +
        " " +
        bPass.price_info[i].amount +
        "</b></td></tr>";
    });
    myMsg +=
      '<tr><td align="left"><span style="font-size:15px;">Base Price</span></td><td align="right"><b>' +
      bPass.currency +
      " " +
      bPass.base_price +
      "</b></td></tr>" +
      '<tr><td align="left"><span style="font-size:15px;">Tax</span></td><td align="right"><b>' +
      bPass.currency +
      " " +
      bPass.tax +
      "</b></td></tr>" +
      '<tr><td align="left"><span style="font-size:15px;">Total Price</span></td><td align="right"><b>' +
      bPass.currency +
      " " +
      bPass.total_price +
      "</b></td></tr>" +
      "</table></div>" +
      "</div></div>";
    jQuery(".itinerary_details").append(myMsg);
    app.popup.open(".popup-itinerary");
    localStorage.removeItem("itinerary_payload");
  }
  //REPLACED WITH Sangeet Sir's FUNCTION
  function pageApply(pid, keyword_type) {
    // console.log("page apply called");
    var pageid = jQuery(".botui-app-container").data("pageid");
    // console.log(pageid);
    if (keyword_type) {
    } else {
      keyword_type = "get-started";
    }
    var dta = {
      page_id: pageid,
      keyword_type: keyword_type,
    };
    // console.log(dta);
    var url = "https://messengerf.com/Api/get_all_messenger_bot";
    jQuery.ajax({
      type: "GET",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: dta,
      dataType: "json",
      success: (result) => {
        var messg = JSON.parse(result.payloads_info[0].message);
        jQuery.each(messg, function (index, val) {
          if (val.message.template_type == "text") {
            setTimeout(function () {
              var textmessage = {
                add: {
                  content: val.message.text,
                },
              };
              newMessage(textmessage);
            }, index * 1000);
          } else if (val.message.template_type == "text_with_buttons") {
            setTimeout(function () {
              addAction(val.message.attachment.payload.buttons);
            }, index * 1000);
          } else if (val.message.template_type == "quick_reply") {
            setTimeout(function () {
              var textmessage = {
                add: {
                  content: val.message.text,
                },
              };
              newMessage(textmessage);
              var vmn = window.localStorage.getItem("vmn");
              var value = {
                action: {
                  button: {
                    action: [
                      { icon: "check", text: "Quick", value: "quick" },
                      { icon: "check", text: "Call", value: vmn },
                      { icon: "check", text: "Email", value: "email" },
                    ],
                  },
                },
                save: "addtocart",
              };
              addAction(value);
            }, index * 1000);
          } else if (val.message.template_type == "carousel") {
            // console.log(val.message.attachment.payload);
            var carouselEle = val.message.attachment.payload.elements;
            var nurl =
              "https://www.socialrecharge.com/chat/carouselapp.html?q=" +
              encodeURI(JSON.stringify(carouselEle));
            var addmsg = {
              add: {
                type: "embed",
                content: nurl,
              },
            };
  
            newMessage(addmsg);
          }
        });
      },
      error: (error) => {
        console.error(
          `RETURNED ERROR FROM PREFETCH VALUE API ${JSON.stringify(error)}`
        );
      },
    });
  }
  
  function brandApply(bid) {
    var code = jQuery(".botui-app-container").data("code");
    // var code = "jHsbMeEIiW";
    var pdata = Parse.Object.extend("brandData");
    var query = new Parse.Query(pdata);
    query.get(code).then((data) => {
      data = JSON.parse(JSON.stringify(data));
      dataJson = data.payload_json.messages;
      // console.log(dataJson);
      // addmessage(dataJson);
      offline("get_started", "");
    });
    // offline("get_started","");
  }
  function load_js(filePath) {
    // console.log('load_js : '+ filePath);
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.src = filePath;
    head.appendChild(script);
  }
  function createjscssfile(filename, filetype) {
    if (filetype == "js") {
      //if filename is a external JavaScript file
      var fileref = document.createElement("script");
      fileref.setAttribute("type", "text/javascript");
      fileref.setAttribute("src", filename);
    } else if (filetype == "css") {
      //if filename is an external CSS file
      var fileref = document.createElement("link");
      fileref.setAttribute("rel", "stylesheet");
      fileref.setAttribute("type", "text/css");
      fileref.setAttribute("href", filename);
    }
    return fileref;
  }
  function replacejscssfile(oldfilename, newfilename, filetype) {
    var targetelement =
      filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist using
    var targetattr =
      filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
    var allsuspects = document.getElementsByTagName(targetelement);
    for (var i = allsuspects.length; i >= 0; i--) {
      //search backwards within nodelist for matching elements to remove
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute(targetattr) != null &&
        allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1
      ) {
        var newelement = createjscssfile(newfilename, filetype);
        allsuspects[i].parentNode.replaceChild(newelement, allsuspects[i]);
      }
    }
  }
  
  function refresh() {
    if (localStorage.getItem("fbpage")) {
      // console.log(`fetch data from api`);
      fetchPersistentMenu(localStorage.getItem("fbpage"));
      return;
    }
    clearout();
    homeBot.message.removeAll().then(function (index) {
      // console.log("feeling refreshed");
      // homeBot.action.hide;
      // homeBot.message.removeAll();
      offline("persist_menu");
      /*var newmsg = [
        {
          add: {
            content: "Your session has been refreshed. Explore the menu below:",
          },
        },
        {
          action: {
            button: {
              action: [
                {
                  text: "📙 Menu",
                  value: "mainmenu",
                },
              ],
            },
          },
          delay: 1000,
        },
      ];
      addmessage(newmsg);*/
      app.sheet.close(".my-sheet");
    });
  }
  
  function fetchPersistentMenu(pageid) {
    let url = "https://messengerf.com/Api/get_all_persistent_menu";
    jQuery.ajax({
      type: "GET",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: { page_id: pageid },
      dataType: "json",
      success: (result) => {
        var serverdata = result.persistent_menu[0].item_json;
        var fda = JSON.parse(serverdata);
        if (!fda) {
          $("a.float").removeClass("sheet-open");
          $("a.float").removeData("sheet");
          $("#prmenu").html("");
          return;
        }
        $("a.float").hasClass("sheet-open")
          ? ""
          : $("a.float").addClass("sheet-open");
        $("a.float").data("sheet") == ".my-sheet-swipe-to-step"
          ? ""
          : $("a.float").data("sheet", ".my-sheet-swipe-to-step");
        homeBot.message.removeAll();
        let buttons = [];
        let prMenu = "";
        $("#prmenu").html("");
        jQuery.each(fda["call_to_actions"], (index, val) => {
          prMenu +=
            val.type === "postback"
              ? `<li>
          <a
            href="#"
            onclick="processfb('${val.payload}', '${pageid}', ${true})"
            class="panel-close sheet-close"
            >${val.title}</a
          >
        </li>`
              : `<li>
        <a
          href="#"
          onclick="openUrl('${val.url}')"
          class="panel-close sheet-close"
          >${val.title}</a
        >
      </li>`;
          buttons.push({
            type: val.type,
            text: val.title,
            value: val.type === "postback" ? val.payload : val.url,
          });
        });
  
        let value = {
          action: {
            button: {
              action: buttons,
            },
          },
          medium: "fb",
          pageid: pageid,
        };
        addAction(value);
        $("#prmenu").html(prMenu);
      },
    });
  }
  
  function openHomePage() {
    location.reload("/");
  }
  function imageOpen(img) {
    // console.log("imageOpen : "+img);
    // var myPhotoBrowserPage = app.photoBrowser.create({
    //   photos : [img],
    //   theme: 'dark',
    //   // type: 'standalone'
    //   type: 'page',
    //   pageBackLinkText: 'Back'
    // }).open();
    app.popup.create({
      content: '<div class="popup" style="z-index:12500;">'+
          '<div class="navbar">'+
            '<div class="navbar-bg"></div>'+
            '<div class="navbar-inner">'+
              '<div class="right">'+
                '<a class="link popup-close">Close</a>'+
              '</div>'+
            '</div>'+
          '</div>'+
          '<div class="page-content" align="center">'+
            '<div class="block">'+
              '<img src="'+img+'" style="width:100%;">'+
            '</div>'+
          '</div></div>'
    }).open();
  }
  function openQRImage(img){
    app.popup.create({
      content: '<div class="popup">'+
                '<div class="navbar">'+
                  '<div class="navbar-bg"></div>'+
                  '<div class="navbar-inner">'+
                    '<div class="title">QR Code</div>'+
                    '<div class="right">'+
                      '<a class="link popup-close">Done</a>'+
                    '</div>'+
                  '</div>'+
                '</div>'+
                  '<div class="block" align="center" style="width:auto;">'+
                    '<img src="'+img+'">'+
                  '</div>'+
                '</div>',
      on: {
        open: function (popup) {
          // console.log('Popup open');
        },
      }
    }).open();
  }

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  function openPostPage(postId){
    mainView.router.navigate({ name: `post`, path: `/post/:postid`, params: { postid: postId} });
  }
  function openOrderPage(postId){
    mainView.router.navigate({ name: "orders", path: "/orders/:brandid", params: { brandid: brandID } });
  }

  function openIndexPage(pName) {
    clearout();
    if(app.sheet.get('.sheet-modal') && app.sheet.get('.sheet-modal').open) {
      app.sheet.close();
    }
    mainView.router.back();
    // localStorage.setItem("pagetype", pName);
    // mainView.router.navigate({ name: "home" }, {
    //   clearPreviousHistory : true,
    //   reloadAll : true, // Both code working
    // });
    // jQuery.getScript("js/sr_x.js");
    // replacejscssfile('js/sr_x.js', 'js/sr_x.js', 'js')
    // offline('exist_start','');
    if(pName == 'products') {
      setTimeout(() => {
        if(app.sheet.get('.sheet-modal') && app.sheet.get('.sheet-modal').open) {
          app.sheet.close();
        }
        var bCode = localStorage.getItem("brand_code");
        jQuery(".botui-app-container").data("code", bCode);
        var value = {
          action: {
            button: {
              action: [
                {
                  text: "My Orders",
                  value: "myorders",
                },
                {
                  text: "Explore",
                  value: "explore",
                }
              ],
            },
          },
        };
        addAction(value);
      }, 1000);
    } else if(pName == 'checkout') {
      var ord_id = localStorage.getItem("pay_orderid");
      // console.log(ord_id);
      // mainView.router.back('/', {force:true});
      setTimeout(() => {
        mainView.router.back();
        if(app.sheet.get('.sheet-modal') && app.sheet.get('.sheet-modal').open) {
          app.sheet.close();
        }
        var bCode = localStorage.getItem("brand_code");
        jQuery(".botui-app-container").data("code", bCode);
        let message = {
          add: {
            content: "Thank you for your order.",
          },
        };
        newMessage(message);
        jQuery.getJSON(
        'https://parseapi.server.scvpl.in/get/branddetails?brandid=' + brandID,
        jsondata => {
          // console.log(jsondata);
          var wStatus = jsondata[0].whatsapp_status;
          var wNumber = jsondata[0].whatsapp_no;
          // console.log(wStatus +" - "+wNumber);
          if(wStatus && wNumber) {
            var value = {
              action: {
                button: {
                  action: [
                    {
                      icon: "shopping-cart",
                      text: "My Orders",
                      value: "myorders",
                    },
                    {
                      icon: "credit-card",
                      text: "Pay Online",
                      value: "payonline",
                    },
                    {
                      icon: "whatsapp",
                      text: "Send Order",
                      value: "sendOrderWhatsapp",
                    }
                  ],
                },
                data: [
                  {
                    orderid: ord_id,
                  },
                ],
              },
            };
            addAction(value);
          } else {
            var value = {
              action: {
                button: {
                  action: [
                    {
                      icon: "shopping-cart",
                      text: "My Orders",
                      value: "myorders",
                    },
                    {
                      icon: "credit-card",
                      text: "Pay Online",
                      value: "payonline",
                    },
                  ],
                },
                data: [
                  {
                    orderid: ord_id,
                  },
                ],
              },
            };
            addAction(value);
            // localStorage.removeItem("pagetype");
          }
        });
      }, 1500);
    }
  }
  function hasClass(elem, className) {
    return elem.className.split(" ").indexOf(className) > -1;
  }
  function art(id, value) {
    this.key = id;
    this.value = value;
  }
  function pluckByName(inArr, name, exists) {
    for (let i = 0; i < inArr.length; i++) {
      if (inArr[i].key == name) {
        return exists === true ? true : inArr[i];
      }
    }
  }
  function addShipping() {
    jQuery('#add_shipping').toggle();
    let locs;
    locs = JSON.parse(
      localStorage.getItem(brandID + "_servicearea")
    ).map((location) => {
      // console.log(location);
      jQuery('#buildings').append('<option value="'+location.text+'">'+location.text+'</option>');
    });
  }
  function checkoutBack(bid) {
    mainView.router.back();
    app.sheet.close('.orders-swipe-to-step');
    jQuery(".viewcart").unbind();
    mainView.router.navigate('listapp/'+bid, {
      reloadCurrent: true,
      ignoreCache: true,
    });
    app.sheet.open('.products-swipe-to-step');
  }
  function searchBack() {
    mainView.router.back();
    // mainView.router.navigate('/discover/', {
    //   reloadCurrent: true,
    //   ignoreCache: true,
    // });
    // mainView.router.refreshPage();
  }
  function ordersBack() {
    mainView.router.back();
    app.sheet.close('.myorder-sheet');
    app.sheet.destroy();
    var value = {
          action: {
            button: {
              action: [
                {
                  icon: "ellipsis-h",
                  text: "Explore",
                  value: "explore",
                },
              ],
            },
          },
        };
    addAction(value);
  }
  function pagesBack() {
    var value = {
          action: {
            button: {
              action: [
                {
                  icon: "ellipsis-h",
                  text: "Explore",
                  value: "explore",
                },
              ],
            },
          },
        };
    addAction(value);
  }
  function clearproduct(brandID) {
    var arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (var i = 0; i < localStorage.length; i++) {
      var str1 = localStorage.key(i);
      var str2 = "userdata-" + brandID;
      if (str1.indexOf(str2) != -1) {
        // console.log(str2 + " found");
        arr.push(localStorage.key(i));
      } else {
        // console.log("not found");
      }
    }
    // Iterate over arr and remove the items by key
    if (arr.length) {
      for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
      }
    }
    if (brandID) {
      // console.log("detected brand id: " + brandID);
      localStorage.removeItem("json-" + brandID);
      myArray = [];
    }
  }
  
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    // console.log(lat1+" - "+lon1+" - "+lat2+" - "+lon2);
    var R = 6371;
    // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);
    // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    // Distance in km
    return parseFloat(d);
  }
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  
  jQuery("body").on("DOMSubtreeModified", ".botui-actions-buttons", function () {
    // console.log('changed : ', (jQuery(document).height()+10));
    jQuery("html, body").scrollTop(jQuery(document).height() + 10);
  });
  // "push":"true",
  // "title": "Congratulations! You have earned 10 more points",
  // "body": "Just 10 more points left to get your free Chai worth INR 30/-",
  // "icon": "https://miro.medium.com/max/800/1*4QemAP2IzD_8ct2f3kySvg.jpeg",
  // "image": "https://miro.medium.com/max/800/1*4QemAP2IzD_8ct2f3kySvg.jpeg",
  // "nextpayload":"neworder",
  // "data": {
  //   "postid": "You have eraned good points..",
  //   "post_title": "You have earned points",
  //   "image": "https://miro.medium.com/max/800/1*4QemAP2IzD_8ct2f3kySvg.jpeg"
  // }
  
  // export {offline, fetchPersistentMenu};
  // window.openPop=openPop;

  
window.openPop = openPop;
window.processfb = processfb;
window.openHomePage = openHomePage;
window.offline = offline;
window.checkoutBack = checkoutBack;
window.openIndexPage = openIndexPage;
window.searchBack = searchBack;
window.ordersBack = ordersBack;
window.openCategory = openCategory;
window.sheetOpen = sheetOpen;
window.refresh = refresh;
window.onSuccess = onSuccess;
window.onFail = onFail;
window.resizeIframe = resizeIframe;
window.pluckByName = pluckByName;
window.addmessage = addmessage;
window.pagesBack = pagesBack;
window.openSearch = openSearch;
window.closePopup = closePopup;
window.selectFormDate = selectFormDate;
window.selectFormTime = selectFormTime;
window.addShipping = addShipping;
window.openQRImage = openQRImage;

export {
  offline,
  fetchPersistentMenu,
  homeBot,
  addAction,
  newMessage,
  deg2rad,
  getDistanceFromLatLonInKm,
  clearproduct,
  ordersBack,
  checkoutBack,
  pluckByName,
  art,
  hasClass,
  openIndexPage,
  getBase64Image,
  imageOpen,
  openHomePage,
  refresh,
  replacejscssfile,
  createjscssfile,
  load_js,
  brandApply,
  pageApply,
  viewItinerary,
  openBoarding,
  openReceipt,
  openSearch,
  openCategory,
  openUrl,
  callAddAction,
  nativeCarousel,
  getUrlParameter,
  processfb,
  openPostPage,
  openOrderPage,
  openPop,
  scancode,
  getMobileOperatingSystem,
  clearout,
  addmessage,
  ga_record,
  resizeIframe,
  getQueryStringValue,
  saveValue,
  savePersistData,
  sheetOpen,
  addcarousel,
  addlist,
  pagesBack,
};