import "jquery";
import { offline, fetchPersistentMenu, getUrlParameter, processfb, openPostPage, openOrderPage, openPop, saveValue } from "./sr_x.js";
import { updateUserProfile } from "./app.js";
import firebase from "firebase/app";
import { FIREBASE } from "./firebase";
import Parse from "./db";

var appbot = {
  // Application Constructor
  initialize: function () {
    document.addEventListener(
      "deviceready",
      this.onDeviceReady.bind(this),
      false
    );
    // console.log(`fired appbot initialize property`);
  },
  // deviceready Event Handler
  // Bind any cordova events here. Common events are:
  // 'pause', 'resume', etc.
  onDeviceReady: function () {
    this.receivedEvent("deviceready");
    localStorage.removeItem("fbpage");
    // FirebasePlugin.isUserSignedIn(
    //   (isSignedIn) => {        
    //     FirebasePlugin.getCurrentUser(
    //       (user) => {            
    //         user.name &&
    //           localStorage.setItem("name", user.name) &&
    //           localStorage.setItem(
    //             `userdata-${localStorage.getItem(`brandid`)}-name`,
    //             user.name
    //           );
    //         user.name == null &&
    //         localStorage.getItem(`brandid`) &&
    //         (localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
    //           localStorage.getItem(
    //             `userdata-${localStorage.getItem(`brandid`)}-name`
    //           ))
    //           ? updateUserProfile()
    //           : `${
    //               (localStorage.getItem(`brandid`) &&
    //                 localStorage.setItem(
    //                   `${localStorage.getItem(`brandid`)}-name`,
    //                   user.name
    //                 )) ||
    //               (localStorage.getItem(`brandid`) &&
    //                 localStorage.setItem(
    //                   `userdata-${localStorage.getItem(`brandid`)}-name`,
    //                   user.name
    //                 ))
    //             }`;

    //         user.providerId === "phone" && user.phoneNumber
    //           ? localStorage.setItem("vmn", user.phoneNumber)
    //           : user.providerId === "google.com" && user.email
    //           ? localStorage.setItem("email", user.email)
    //           : null;
    //       },
    //       (error) => {
    //         console.error("Failed to get current user data: " + error);
    //         localStorage.clear();
    //       }
    //     );
    //   },
    //   (error) => {
    //     console.error("Failed to check if user is signed in: " + error);
    //   }
    // );
    // Mock device.platform property if not available
    if (!window.device) {
      window.device = { platform: "Browser" };
    }

    // setTimeout(function () {
    //   let brandID = localStorage.getItem(`brandid`);
    //   let isSignedIn = localStorage.getItem(`isSignedIn`);      
    //   let userSignedInData =        
    //     (localStorage.getItem("vmn") || localStorage.getItem("email"))
    //       ? localStorage.getItem("vmn") || localStorage.getItem("email")
    //       : null;
    //   let userEmail = localStorage.getItem(`${brandID}-email`);
         
    //   if (isSignedIn === 'true' && userSignedInData) {              
    //     offline("exist_start");
    //   } else if(isSignedIn !== 'true' && userSignedInData){
    //     offline(`buildYourProfile`);
    //   } else {
    //     offline("get_started");
    //   }

    //   handleExternalURLs();
    // }, 2000);

    // window.FirebasePlugin.onMessageReceived(
    //   function (notification) {        
    //     const notificationTitle = notification.post_title;
    //     const notificationOptions = {
    //       body: notification.body,
    //       icon: notification.image,
    //     };
    //     var images = "";
    //     if (notification.image) {
    //       images = " ![product image](" + notification.image + ")";
    //     }
    //     var pushmessage = "";
    //     if (notification.embed_url) {
    //       pushmessage = {
    //         add: {
    //           type: "embed",
    //           content: notification.embed_url,
    //         },
    //       };
    //       newMessage(pushmessage);
    //     } else if (notification.payload) {
    //       // console.log("payload: " + notification.payload);
    //       showNotification(notification.payload, 64);
    //     } else {
    //       var pushmessage = {
    //         add: {
    //           content: notification.title + ": " + notification.body + images,
    //         },
    //       };
    //       newMessage(pushmessage);
    //     }

    //     var bid = brandID;
    //     // console.log("Bid " + bid);
    //     storeUrl.close();
    //   },
    //   function (error) {
    //     console.error(error);
    //   }
    // );
  },
  // Update DOM on a Received Event
  receivedEvent: function (id) {
    // console.log(`Received Event: ${id}`);
  },
};

appbot.initialize();

/*$(document).ready(function(){  
  FIREBASE.auth().onAuthStateChanged(function(user) {
    // console.log(user);
    if (user) {
      user.displayName == null || user.displayName === 'undefined' &&
      localStorage.getItem(`brandid`) &&
      (localStorage.getItem(`name`) || localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
        localStorage.getItem(
          `userdata-${localStorage.getItem(`brandid`)}-name`
        ))
        ? updateUserProfile()
        : `${
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              )) ||
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `userdata-${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              ))
        }`;
      
      !user.isAnonymous &&
      user.providerData &&
      user.providerData.length > 0 &&
      user.providerData[0].providerId === "phone" &&
      user.phoneNumber
        ? localStorage.setItem("vmn", user.phoneNumber)
        : user.providerId === "google.com" && user.email
        ? localStorage.setItem("email", user.email)
        : null; 
    }
  });
  
  setTimeout(async function () {
    const currentUser = Parse.User.current();
    let brandID = localStorage.getItem(`brandid`);
    let isSignedIn = localStorage.getItem(`isSignedIn`);
    let userSignedInData =
      localStorage.getItem("vmn") || localStorage.getItem("email")
        ? localStorage.getItem("vmn") || localStorage.getItem("email")
        : null;
    let userEmail = localStorage.getItem(`${brandID}-email`);

    const pageType = jQuery(".botui-app-container").data("pagetype");
    const fbPageID = jQuery(".botui-app-container").data("pageid");
    brandID = jQuery(".botui-app-container").data("brandid");
    if (pageType === "fb" && fbPageID) {
      // console.log(`Inside if`);
      return openPop(fbPageID, "fb", undefined, brandID, undefined);
    }

    var brandCode = jQuery(".botui-app-container").data("code");

    // FIREBASE.auth().signInAnonymously().then(() => {
    //   console.log("Sign in");
    // })
    // .catch((error) => {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   console.log(errorMessage);
    // });

    var postId = getUrlParameter("postId");
    var payload = getUrlParameter("payload");
    // console.log('Payloads: ');
    // console.log(payload);
    if(payload == 'myorders') {
      openOrderPage(brandID);
    }
    if(postId){
      // console.log(postId);
      // var userId = getUrlParameter("userId");
      openPostPage(postId);
    } else {
      if (isSignedIn === "true" && userSignedInData) {
        // offline("exist_start");
        // openPop(brandCode, undefined, undefined, brandID, "exist_start"); // SUMAN JEE ORIGINAL
        //CHANGE MADE BY AMAN
        if (localStorage.getItem("name") == "friend") {
          // console.log(localStorage.getItem("name"));
          var newmsg = [
            {
              add: {
                content: `Your name please`,
              },
            },
            {
              action: {
                text: {
                  action: {
                    nextpayload: "explore",
                    placeholder: "Your name please",
                    sub_type: "text",
                  },
                },
              },
              save: "name",
              persist: true,
            },
          ];
          // addmessage(newmsg);
          openPop(brandCode, undefined, undefined, brandID, newmsg);
        } else {
          // offline("exist_start");
          openPop(brandCode, undefined, undefined, brandID, "exist_start");
        }
      } else if (isSignedIn !== "true" && userSignedInData && currentUser && currentUser.get("phone") && currentUser.get("phone") === localStorage.getItem("vmn")) {        
        openPop(brandCode, undefined, undefined, brandID, "completedBasicProfile");
      } else if (isSignedIn !== "true" && userSignedInData) {
        // offline(`buildYourProfile`);
        openPop(brandCode, undefined, undefined, brandID, "buildYourProfile");
      } else {
        var objId = getUrlParameter("t");
        // console.log(objId);
        if (objId) {
          saveValue("objectID", objId);
          const autoVerifyQuery = new Parse.Query(`AutoVerify`);
          autoVerifyQuery.equalTo(`objectId`, objId);
          const autoVerifyResult = await autoVerifyQuery.first();
          
          autoVerifyResult && localStorage.setItem(`temp-userID`, autoVerifyResult.get(`userID`));
          // offline("autoVerifyWithWhatsApp");
          if (localStorage.getItem("name") == "friend") {
            // console.log(localStorage.getItem("name"));
            var newmsg = [
              {
                add: {
                  content: `Your name please`,
                },
              },
              {
                action: {
                  text: {
                    action: {
                      nextpayload: "explore",
                      placeholder: "Your name please",
                      sub_type: "text",
                    },
                  },
                },
                save: "name",
                persist: true,
              },
            ];
            // addmessage(newmsg);
            openPop(brandCode, undefined, undefined, brandID, newmsg);
          } else {
            openPop(brandCode, undefined, undefined, brandID, "autoVerifyWithWhatsApp");
          }
        } else {
          // offline("get_started");
          openPop(brandCode, undefined, undefined, brandID, "get_started");
        }
      }
    }
    // handleExternalURLs();
  }, 2500);
});*/


$(document).ready(function () {
  FIREBASE.auth().onAuthStateChanged(async function(user) {
    if (user) {
 
      user.displayName == null || user.displayName === 'undefined' &&
      localStorage.getItem(`brandid`) &&
      (localStorage.getItem(`name`) || localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
        localStorage.getItem(
          `userdata-${localStorage.getItem(`brandid`)}-name`
        ))
        ? updateUserProfile()
        : `${
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              )) ||
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `userdata-${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              ))
        }`;
     
      !user.isAnonymous &&
        user.providerData &&
        user.providerData.length > 0 &&
        user.providerData[0].providerId === "phone" &&
        user.phoneNumber
        ? localStorage.setItem("vmn", user.phoneNumber)
        : user.providerId === "google.com" && user.email
          ? localStorage.setItem("email", user.email)
          : null;
    }
  });
 
  setTimeout(async function () {    
    const currentUser = Parse.User.current();
   
    let brandID = localStorage.getItem(`brandid`);
    let isSignedIn = localStorage.getItem(`isSignedIn`);
   
    let userSignedInData =
      localStorage.getItem("vmn") || localStorage.getItem("email")
        ? localStorage.getItem("vmn") || localStorage.getItem("email")
        : null;
   
    let userEmail = localStorage.getItem(`${brandID}-email`);
 
    /*const userQuery = new Parse.Query(Parse.User);
    userQuery.get(currentUser.id).then((UserSnap) => {
      console.log("User snap:", JSON.stringify(UserSnap));
    }).catch(error => {
      console.error("Error in fetching parse user data. Error is ", error);
    });*/
 
    const pageType = jQuery(".botui-app-container").data("pagetype");
    const fbPageID = jQuery(".botui-app-container").data("pageid");
    brandID = jQuery(".botui-app-container").data("brandid");
    if (pageType === "fb" && fbPageID) {      
      return openPop(fbPageID, "fb", undefined, brandID, undefined);
    }
 
    var brandCode = jQuery(".botui-app-container").data("code");
    var payload = getUrlParameter("payload");    
    if(payload === 'myorders') {
      openOrderPage(brandID);
    }
    var postId = getUrlParameter("postId");
   
    if(postId){
      // mainView.router.navigate({ name: `post`, path: `/post/:postid`, params: { postid: postId} });
      // console.log(postId);
      // var userId = getUrlParameter("userId");
      openPostPage(postId);
    } else {
      if (isSignedIn === "true" && userSignedInData) {        
        // offline("exist_start");
        // openPop(brandCode, undefined, undefined, brandID, "exist_start"); // SUMAN JEE ORIGINAL
        //CHANGE MADE BY AMAN
        var objId = getUrlParameter("t");
        // console.log(objId);
        if (objId) {
          saveValue("objectID", objId);
 
          const autoVerifyQuery = new Parse.Query(`AutoVerify`);
          autoVerifyQuery.equalTo(`objectId`, objId);
          const autoVerifyResult = await autoVerifyQuery.first();
         
          autoVerifyResult && localStorage.setItem(`temp-userID`, autoVerifyResult.get(`userID`));
         
          // offline("autoVerifyWithWhatsApp");
          if (autoVerifyResult) {          
            openPop(
              brandCode,
              undefined,
              undefined,
              brandID,
              "autoVerifyWithWhatsApp"
            )        
          } else {
            // location.href = location.href.split("?")[0];
            openPop(brandCode, undefined, undefined, brandID, "exist_start");  
          }
        } else if (localStorage.getItem("name") == "friend") {
         
          var newmsg = [
            {
              add: {
                content: `Your name please`,
              },
            },
            {
              action: {
                text: {
                  action: {
                    nextpayload: "explore",
                    placeholder: "Your name please",
                    sub_type: "text",
                  },
                },
              },
              save: "name",
              persist: true,
            },
          ];
          // addmessage(newmsg);
          openPop(brandCode, undefined, undefined, brandID, newmsg);
        } else {
          // offline("exist_start");
          openPop(brandCode, undefined, undefined, brandID, "exist_start");
        }
      } else if (
        isSignedIn !== "true" &&
        userSignedInData &&
        currentUser &&
        currentUser.get("phone") &&
        currentUser.get("phone") === localStorage.getItem("vmn")
      ) {        
        openPop(brandCode, undefined, undefined, brandID, "completedBasicProfile");
      }
      else if (isSignedIn !== "true" && userSignedInData) {
        // offline(`buildYourProfile`);
        openPop(brandCode, undefined, undefined, brandID, "buildYourProfile");
      } else {
        var objId = getUrlParameter("t");
        // console.log(objId);
        if (objId) {
          saveValue("objectID", objId);
 
          const autoVerifyQuery = new Parse.Query(`AutoVerify`);
          autoVerifyQuery.equalTo(`objectId`, objId);
          const autoVerifyResult = await autoVerifyQuery.first();
         
          autoVerifyResult && localStorage.setItem(`temp-userID`, autoVerifyResult.get(`userID`));
         
          // offline("autoVerifyWithWhatsApp");
          if (localStorage.getItem("name") == "friend") {
            // console.log(localStorage.getItem("name"));
            var newmsg = [
              {
                add: {
                  content: `Your name please`,
                },
              },
              {
                action: {
                  text: {
                    action: {
                      nextpayload: "explore",
                      placeholder: "Your name please",
                      sub_type: "text",
                    },
                  },
                },
                save: "name",
                persist: true,
              },
            ];
            // addmessage(newmsg);
            openPop(brandCode, undefined, undefined, brandID, newmsg);
          } else {
            if (autoVerifyResult) {              
              openPop(
                brandCode,
                undefined,
                undefined,
                brandID,
                "autoVerifyWithWhatsApp"
              );
            } else {
              openPop(brandCode, undefined, undefined, brandID, "get_started");
            }
          }
        } else {
          // offline("get_started");
          openPop(brandCode, undefined, undefined, brandID, "get_started");
        }
      }
      // app.welcomescreen.open();
    }
    // handleExternalURLs();
  }, 2500);
});



/*(()=>{
    // console.log(`Inside index.js =======>`)
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // User is signed in.
      // console.log(`Current user is, inside onAuthStateChanged:`,user);
      // console.log(`User name is : ${user.displayName}`)
      // console.log(typeof user.displayName)
      // console.log(user.displayName !== 'undefined')
      // console.log(user.displayName)
      // console.log(user.displayName && user.displayName !== 'undefined')
      user.displayName && user.displayName !== 'undefined' &&
      localStorage.setItem("name", user.displayName) &&
      localStorage.setItem(
        `userdata-${localStorage.getItem(`brandid`)}-name`,
        user.displayName
      );
      user.displayName == null || user.displayName === 'undefined' &&
      localStorage.getItem(`brandid`) &&
      (localStorage.getItem(`name`) || localStorage.getItem(`${localStorage.getItem(`brandid`)}-name`) ||
        localStorage.getItem(
          `userdata-${localStorage.getItem(`brandid`)}-name`
        ))
        ? updateUserProfile()
        : `${
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              )) ||
            (localStorage.getItem(`brandid`) &&
              localStorage.setItem(
                `userdata-${localStorage.getItem(`brandid`)}-name`,
                user.displayName
              ))
          }`;
          // console.log(`User: `,user);
          // console.log(`Provider ID: `,user.providerData[0].providerId);
          // console.log(`Phone number: `,user.phoneNumber);
      user.providerData[0].providerId === "phone" && user.phoneNumber
        ? localStorage.setItem("vmn", user.phoneNumber)
        : user.providerId === "google.com" && user.email
        ? localStorage.setItem("email", user.email)
        : null;      
    } else {
      // No user is signed in.
      // console.log(`User is not signed in, inside onAuthStateChanged ${JSON.stringify(user)}`);
    }
  });

  setTimeout(function () {
    let brandID = localStorage.getItem(`brandid`);
    let isSignedIn = localStorage.getItem(`isSignedIn`);      
    let userSignedInData =        
      (localStorage.getItem("vmn") || localStorage.getItem("email"))
        ? localStorage.getItem("vmn") || localStorage.getItem("email")
        : null;
    let userEmail = localStorage.getItem(`${brandID}-email`);
    
    if (isSignedIn === 'true' && userSignedInData) {
      if(localStorage.getItem("name") == 'friend'){
        // console.log(localStorage.getItem("name"));
        var newmsg = [
          {
            add: {
              content: `Your name please`,
            },
          },
          {
            action: {
              text: {
                action: {
                  nextpayload: "explore",
                  placeholder: "Your name please",
                  sub_type: "text"
                },
              },
            },
            save: "name",
            persist: true
          },
        ];
        addmessage(newmsg);
      } else {           
        offline("exist_start");
      }
    } else if(isSignedIn !== 'true' && userSignedInData){
      offline(`buildYourProfile`);
    } else {
      var objId = getUrlParameter("t");
      // console.log(objId);
      if(objId) {
        saveValue("objectID", objId);
        offline("autoVerifyWithWhatsApp");
      } else {
        offline("get_started");
      }
    }
    // handleExternalURLs();
  }, 2500);
})();*/
