import firebase from 'firebase/app';
import 'firebase/app'
import 'firebase/auth'
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAtwf_Ro94Kl5bzw7d0ylhKyUsZc-Qfct4",
    authDomain: "socialrecharge.firebaseapp.com",
    databaseURL: "https://socialrecharge.firebaseio.com",
    projectId: "project-1956585320571671692",
    storageBucket: "project-1956585320571671692.appspot.com",
    messagingSenderId: "194244686544",
    appId: "1:194244686544:web:c34b66190670672271e4d5"
  };
  // Initialize Firebase
export const FIREBASE = firebase.initializeApp(firebaseConfig);