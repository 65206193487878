import '../css/botui.min.css';
import '../css/botui-theme-default.css';
import '../css/framework7.bundle.min.css';
// import '../css/f7.welcomescreen.css';
import '../css/app.css';
import '../css/style_cart.css';
import '../css/reaction.css';
import '../css/framework7-icons.css';
import '../css/style.css'

// const $ = require('../../node_modules/jquery/src/jquery.js');
// window.jQuery = $;
// window.$ = $;

// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

require(`./firebase`);
require(`./core.js`);
require(`./storage.js`);
require(`./jquery-iframe-auto-height.min.js`);
// require(`./framework7.bundle`);
// require(`./f7.welcomescreen`);
require("./index.js");
// require(`./jquery.browser.min.js`);
// require(`./moment.js`);
require(`./sanitize-html.min.js`);
// require(`../spinandwin/js/ThrowPropsPlugin.min.js`);
// require(`../spinandwin/js/Spin2WinWheel.js`);
require(`./app.js`);
// require(`./routes.js`);
require(`./sr_x.js`);
// require(`../firebase-messaging-sw`);
// require(`./f7.welcomescreen.js`);
// window.$ = require('https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js');

